import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { BaseFacets } from 'components/BaseHelpers/BaseSearch/BaseFacets';
import BaseSearchResults from 'components/BaseHelpers/BaseSearchResults';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import styles from './InsightsTopicFilterInsights.module.scss';

export const TopicFilterResults = ({
  moduleHeader,
  noResults,
  statsTemplate,
  didYouMeanLabelFormat,
}: {
  moduleHeader: Field<string>;
  noResults: JSX.Element | undefined;
  statsTemplate: Field<string> | undefined;
  didYouMeanLabelFormat?: Field<string> | undefined;
}) => {
  const { state } = useBaseSearch();
  const { hasBreadcrumbs, hasResults, isLoading } = state;
  const { isEE } = useExpEditor();
  const [hideComponent, setHideComponent] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) return;
    const shouldHide = !hasResults && !hasBreadcrumbs;
    setHideComponent(shouldHide);
  }, [hasBreadcrumbs, hasResults, isLoading]);

  if (hideComponent && !isEE) return <></>;

  return (
    <>
      <ModuleHeader header={moduleHeader} className={'container-10'} />
      <form
        className={cn(styles['insights-topic-filter-search'], 'spacer', 'container-10')}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <BaseFacets />
      </form>
      <div className={cn('container-10', 'spacer')}>
        <BaseSearchResults
          noResults={noResults}
          statsTemplate={statsTemplate}
          correctionTemplate={didYouMeanLabelFormat}
          fallback={<></>}
        />
      </div>
    </>
  );
};
