import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { usePdf } from 'src/hooks/usePdf';
import { usePeopleInfo } from 'src/hooks/usePeopleInfo';

import { hasValue } from 'src/utils/hasValue';

import { Website } from 'lib/component-props/model';

import ImageCta from 'components/BaseHelpers/ImageCta';
import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Text from 'components/BaseHelpers/Text';

import styles from './BaseContactsCard.module.scss';

export type BaseContactsCardProps = {
  hideImage?: Field<boolean>;
  fields: Website.Project.Main.PageTypes.People.Professional;
};

const BaseContactsCard = ({
  fields: { url, id, fields },
  hideImage,
}: BaseContactsCardProps): JSX.Element => {
  const { level, title, name, officesPhones, email, image } = usePeopleInfo(fields);

  const { t } = useDictionary();
  const { isPdf } = usePdf();

  return (
    <div className={cn(styles.main)}>
      {!hideImage?.value && !isPdf && (
        <LinkOrComponent field={url} className={styles['image']} aria-hidden={true} tabIndex={-1}>
          <div className={styles[`image-container`]}>
            <ImageCta
              sizes="192px"
              image={image}
              field={url}
              aria-hidden={true}
              ctaText={t('View Full Profile')}
            />
          </div>
        </LinkOrComponent>
      )}
      <div className={styles[`content`]}>
        <div className={styles[`eyebrow`]}>
          <Text
            tag="div"
            editable={false}
            field={hasValue(title) ? title : level}
            className={styles.role}
          />
        </div>
        <LinkOrComponent field={url} className={styles[`name-container`]}>
          <Text editable={false} className={cn(styles[`name`])} field={name} />
        </LinkOrComponent>
        <ul className={cn(styles[`office-container`])}>
          {officesPhones?.map(({ office, phone }, index) => {
            return (
              <li key={`office-${index}-${id}`} className={styles[`office`]}>
                {office?.text && (
                  <LinkOrComponent className={styles.location} field={office} tag="span">
                    {office.text}
                  </LinkOrComponent>
                )}
                {phone?.href && <Link className={styles.phone} field={phone} />}
              </li>
            );
          })}
        </ul>
        <Link field={email?.href} className={styles[`email`]}>
          <span
            className={styles['email-span']}
            dangerouslySetInnerHTML={{ __html: email?.emailHtml || '' }}
          />
        </Link>
      </div>
    </div>
  );
};

export default BaseContactsCard;
