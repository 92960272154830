import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';

import { BaseLinkCard } from 'components/BaseHelpers/BaseLinkCard/BaseLinkCard';

import { InsightResult } from './InsightsRelatedContent';

import styles from './RelatedContentCard.module.scss';

type RelatedContentCardProps = InsightResult & {
  hideImage: boolean;
  showCta: boolean | undefined;
  hideDate: boolean | undefined;
  contentType: Field<string> | undefined;
  isMainCard?: boolean;
  className?: string;
};

export const RelatedContentCard = (props: RelatedContentCardProps): JSX.Element => {
  const { contentType, url, hideImage, hideDate, showCta, isMainCard } = props;
  const { carouselImage, title, date, displayDate, startDateandTime } = props.fields || {};
  const { t } = useDictionary();
  const cardVariantClass = hideImage ? 'side-card' : 'main-card';

  return (
    <BaseLinkCard
      className={cn(styles['main'], styles[cardVariantClass], {
        [styles['with-image']]: isMainCard,
      })}
      url={url}
      image={carouselImage}
      hideImage={hideImage}
    >
      {hideImage && (
        <BaseLinkCard.ContentType field={contentType} className={styles['content-type']} />
      )}
      <BaseLinkCard.Title field={title} className={styles['title']} />
      {!hideDate && (
        <BaseLinkCard.Date
          field={startDateandTime || date}
          displayDate={displayDate}
          className={styles['date']}
        />
      )}
      {showCta && url && <BaseLinkCard.Cta text={t('Learn More')} />}
    </BaseLinkCard>
  );
};
