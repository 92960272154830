import { Result } from '@coveo/headless';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useCoveoResult } from 'src/hooks/useCoveoResult';
import { useDictionary } from 'src/hooks/useDictionary';

import ImageCta from 'components/BaseHelpers/ImageCta';
import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';

import styles from './PeopleResults.module.scss';

interface PeopleResultProps {
  result: Result;
}

export const PeopleResult = ({ result }: PeopleResultProps): JSX.Element => {
  const { t } = useDictionary();
  const { state } = useBaseSearch();
  const { hasQuery } = state;
  const { data, logResultAnalytics } = useCoveoResult({ result, hideExcerpt: !hasQuery });
  const { fullName, peopleTitle, imageurl, imagealt, email, officePhones, url, excerpt } = data;

  return (
    <div className={styles['card']}>
      <LinkOrComponent
        field={url}
        className={styles['card-image-link']}
        onClick={logResultAnalytics}
        tabIndex={-1}
        aria-hidden={true}
      >
        <ImageCta
          sizes="336px"
          className={styles['card-image']}
          image={{ value: { src: imageurl, alt: imagealt || fullName } }}
          field={url}
          aria-hidden={true}
          ctaText={t('View Full Profile')}
        />
      </LinkOrComponent>

      <div className={styles['card-content']}>
        <Link field={url} className={styles['card-link']} onClick={logResultAnalytics}>
          <span className={'visually-hidden'}>{fullName}</span>
        </Link>
        {peopleTitle && <span className={styles['card-title']}>{peopleTitle}</span>}
        <span className={styles['card-name']} id={`people-card-${fullName}`} aria-hidden="true">
          {fullName}
        </span>
        {officePhones.length !== 0 && (
          <ul className={styles['card-offices']}>
            {officePhones.map(({ office, officeurl, phone }, index) => {
              return (
                <li
                  key={`office-${index}-${result.uniqueId}`}
                  className={styles['card-offices-item']}
                >
                  {office && (
                    <LinkOrComponent
                      className={styles['card-office']}
                      tag={'span'}
                      field={officeurl}
                    >
                      {office}
                    </LinkOrComponent>
                  )}
                  {phone && (
                    <Link
                      className={styles['card-phone']}
                      field={`tel:${phone.replaceAll(' ', '')}`}
                    >
                      {phone}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {email && (
          <Link field={email.href} className={styles['card-email']}>
            <span
              className={styles['card-email']}
              dangerouslySetInnerHTML={{ __html: email.emailHtml || '' }}
            ></span>
          </Link>
        )}
        {excerpt && (
          <span
            className={styles['card-excerpt']}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></span>
        )}
      </div>
    </div>
  );
};
