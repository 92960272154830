import { useExpEditor } from 'src/hooks/useExpEditor';

import { getLinkFieldValue } from 'src/utils/sitecoreFieldHelpers';

import { BaseCtaProps } from './BaseCta';

export const useBaseCta = () => {
  const { hasValueOrIsEE, isEE } = useExpEditor();

  const shouldRenderCta = (props: BaseCtaProps): boolean => {
    if (isEE) {
      return true;
    }

    if (props.tag === 'button') {
      return hasValueOrIsEE(props.text) || Boolean(props.children);
    }

    if (props.tag === 'a') {
      const linkField = getLinkFieldValue(props.field);
      const hasHref = Boolean(linkField.href);
      const hasLabel = Boolean(props.children || linkField.text);
      return hasHref && hasLabel;
    }

    if (props.tag === 'span') {
      return true;
    }

    const never: never = props;
    return never;
  };

  return {
    shouldRenderCta,
  };
};
