export function setCookie(name: string, value: string, expirationInMs = 0) {
  const date = new Date();
  date.setTime(date.getTime() + expirationInMs);
  document.cookie = [
    `${name}=${value}`,
    expirationInMs ? `expires=${date.toUTCString()}` : '',
    `path=/`,
  ]
    .filter(Boolean)
    .join('; ');
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  return parts.length === 2 ? parts.pop()?.split(';').shift() || '' : '';
}
