import { useMemo } from 'react';

import { HIDDEN_FIELDS } from 'src/types/coveo';

import { FacetFields } from './useCoveoFacet';
import { useCoveoFields } from './useCoveoFields';

export function useCoveoFieldConfig(type: 'insights' | 'people' | 'jobs' | 'site') {
  const baseFields = useCoveoFields();

  const facetFields: FacetFields = useMemo(() => {
    switch (type) {
      case 'insights':
        return [
          HIDDEN_FIELDS.templateName,
          HIDDEN_FIELDS.associations,
          baseFields.dateRange,
          baseFields.topics,
          baseFields.services,
          baseFields.industries,
          baseFields.offices,
          baseFields.regions,
          baseFields.contentTypes,
        ];
      case 'people':
        return [
          HIDDEN_FIELDS.associations,
          baseFields.services,
          baseFields.industries,
          baseFields.positions,
          baseFields.offices,
          baseFields.regions,
        ];
      case 'jobs':
        return [baseFields.jobtitle, baseFields.joblocation, baseFields.jobregions];
      case 'site':
      default:
        return [
          baseFields.topics,
          baseFields.services,
          baseFields.industries,
          baseFields.regions,
          baseFields.offices,
          baseFields.positions,
          baseFields.dateRange,
          baseFields.contentTypes,
        ];
    }
  }, [baseFields, type]);

  const sectionFacets = useMemo(() => {
    const coreFacets = [
      baseFields.services,
      baseFields.industries,
      baseFields.regions,
      baseFields.offices,
    ];

    return {
      'All Results': [baseFields.topics, ...coreFacets],
      People: [...coreFacets, baseFields.positions],
      Services: [baseFields.topics],
      Industries: [baseFields.topics],
      Insights: [baseFields.topics, ...coreFacets, baseFields.dateRange, baseFields.contentTypes],
      'Client Stories': [baseFields.topics, baseFields.services, baseFields.industries],
      News: [baseFields.topics, ...coreFacets, baseFields.dateRange, baseFields.contentTypes],
    };
  }, [baseFields]);

  return { facetFields, sectionFacets };
}
