import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentSimpleStatStack.module.scss';

export type BasicContentSimpleStatStackProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.SimpleStatStack;

const BasicContentSimpleStatStack = ({
  rendering,
  fields = {},
}: BasicContentSimpleStatStackProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { hasItemsOrIsEE, isEE } = useExpEditor();

  const placeholder = rendering.placeholders?.['simple-stat-stack'] || [];
  if (!hasItemsOrIsEE(placeholder)) {
    return <></>;
  }

  const maxNumberOfStats = 4;
  const numberOfStats = placeholder.filter((item) => 'componentName' in item).length;

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <div className={styles.content}>
        <Text className={styles.header} field={moduleHeader} tag="h2" />
        <ul
          className={cn(
            styles.stats,
            styles[`stats--${Math.min(numberOfStats, maxNumberOfStats)}`]
          )}
        >
          <Placeholder
            rendering={rendering}
            name="simple-stat-stack"
            renderEach={(component, index) => {
              // Component will only render up to 4 stats per spec
              if (isEE || index < maxNumberOfStats) {
                return (
                  <li
                    className={cn(styles.stat, styles[`stat--${index + 1}`])}
                    key={`stat--${index}--${rendering.uid}`}
                  >
                    {component}
                  </li>
                );
              }
              return null;
            }}
          />
        </ul>
      </div>
    </AnimateInContainer>
  );
};

export default BasicContentSimpleStatStack;
