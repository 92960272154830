import cn from 'classnames';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './Overlay.module.scss';

export type OverlayProps = {
  isVisible?: boolean;
};

const Overlay = ({ isVisible }: OverlayProps): JSX.Element => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (isMounted) {
    // Check if document is finally loaded
    return (
      <>
        {createPortal(
          <div className={cn(styles.main, { [styles[`main--visible`]]: isVisible })}></div>,
          document.body
        )}
      </>
    );
  }
  return <></>;
};
export default Overlay;
