import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import { ComponentWithHasContentProps } from 'lib/component-props/has-content';
import { Website } from 'lib/component-props/model';

import { BasicContentTileProps } from 'components/BasicContent/BasicContentTile';

import AnimateInContainer from '../AnimateInContainer';
import ModuleHeader from '../ModuleHeader';
import RichText from '../RichText';

import styles from './BaseTiles.module.scss';

type ContentProps = { orientation: 'vertical' | 'horizontal' };
export type BaseTilesProps = ComponentWithHasContentProps &
  ContentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Tiles;

type Tile = Website.Project.Main.ComponentTypes.BasicContent.Tile;

const BaseTiles = (props: BaseTilesProps): JSX.Element => {
  const { isEE, hasValueOrIsEE, hasItemsOrIsEE } = useExpEditor();
  const { moduleHeader, moduleDescription, showLink } = props.fields || {};
  const showHeader = hasValueOrIsEE(moduleHeader) || hasValueOrIsEE(moduleDescription);

  const placeholderName = props.orientation === 'vertical' ? 'vertical-tiles' : 'horizontal-tiles';
  const containerClass = props.orientation === 'vertical' ? 'container-10' : 'container-12';

  const shouldRender = (component: unknown, index: number) => {
    return (props.orientation === 'vertical' || index < 4) && tileHasContent(component);
  };

  const tiles = props.rendering.placeholders?.[placeholderName] as Array<Tile> | undefined;
  const tilesToCheck = (props.orientation === 'horizontal' ? tiles : tiles?.slice(0, 4))?.filter(
    (tile) => hasValue(tile.fields?.headline) && hasValue(tile.fields?.image)
  );

  if (!hasItemsOrIsEE(tilesToCheck)) return <></>;

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', containerClass)}>
      {showHeader && (
        <div className={styles['header-container']}>
          <ModuleHeader header={moduleHeader} />
          <span className={styles['description']}>
            <RichText field={moduleDescription} />
          </span>
        </div>
      )}

      <ul className={cn(styles['flex-container'], styles[props.orientation])}>
        <Placeholder
          name={placeholderName}
          rendering={props.rendering}
          isVertical={props.orientation === 'vertical'}
          showLink={props.orientation === 'horizontal' && showLink?.value}
          renderEach={(component, index) => {
            if (isEE || shouldRender(component, index)) {
              return (
                <li
                  key={`tile-${index}`}
                  className={cn({
                    [styles['horizontal-list-item']]: props.orientation === 'horizontal',
                  })}
                >
                  {component}
                </li>
              );
            }
            return null;
          }}
        />
      </ul>
    </AnimateInContainer>
  );
};

const tileHasContent = (component: unknown) => {
  const typedComponent = component as { props: BasicContentTileProps };
  const { headline, image } = typedComponent.props.fields || {};
  return hasValue(headline) && hasValue(image);
};

export default BaseTiles;
