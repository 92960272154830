import {
  ComponentRendering,
  HtmlElementRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useMemo } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import TabNavigation from 'components/BaseHelpers/TabNavigation';
import { Tab } from 'components/BaseHelpers/TabNavigation/TabNavigation';

import TabItem from '../BasicContentTabItem/TabItem';

import styles from './BasicContentTabs.module.scss';

export type BasicContentTabsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Tabs;

export type BasicContentTabs =
  | (ComponentRendering & {
      uid: string;
      name?: string;
    } & Website.Project.Main.ComponentTypes.BasicContent.TabItem)
  | (HtmlElementRendering & {
      uid: string;
      name?: string;
    } & Website.Project.Main.ComponentTypes.BasicContent.TabItem);

export const mapBasicContentTabs = (tabs: BasicContentTabs[]): Tab[] => {
  return (
    tabs.map((tab): Tab => {
      return {
        title: tab?.fields?.title,
        id: tab?.uid,
        name: tab?.name,
        // Content fields in the tab panels will be different between this component and nav bar
        content: <TabItem fields={tab?.fields} />,
      };
    }) || []
  );
};

const BasicContentTabs = ({ rendering, fields = {} }: BasicContentTabsProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { isEE, hasItemsOrIsEE } = useExpEditor();
  const placeholderTabs = rendering?.placeholders?.tabs as BasicContentTabs[];

  const tabs = useMemo(() => {
    return mapBasicContentTabs(placeholderTabs);
  }, [placeholderTabs]);

  if (!hasItemsOrIsEE(tabs)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={moduleHeader} />
      {isEE ? (
        // My idea here was only needed to render this for editing content in EE
        // since the all tbe tabs are renderered in tabnavigation so it can render both placeholder and non placeholder panels
        <Placeholder rendering={rendering} name="tabs" />
      ) : (
        <TabNavigation tabs={tabs} />
      )}
    </AnimateInContainer>
  );
};

export default BasicContentTabs;
