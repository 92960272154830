import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import NoImageHero from 'components/BaseHelpers/NoImageHero';

import styles from './InsightsInsightLandingHeader.module.scss';

export type InsightsInsightLandingHeaderProps = {
  name?: Field<string>;
  description?: Field<string>;
};

const InsightsInsightLandingHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const fields = getRouteFields<InsightsInsightLandingHeaderProps>(sitecoreContext);

  return (
    <AnimateInContainer>
      <NoImageHero className={styles.hero} headline={fields.name} body={fields.description} />
    </AnimateInContainer>
  );
};

export default InsightsInsightLandingHeader;
