import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Facebook from 'assets/icons/facebook.svg';
import Instagram from 'assets/icons/instagram.svg';
import LinkedIn from 'assets/icons/linkedin.svg';
import Twitter from 'assets/icons/twitter-x.svg';

import styles from './PeopleSocialMediaIcon.module.scss';

export type PeopleSocialMediaIconProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.SocialMediaItem;

const PeopleSocialMediaIcon = ({ fields = {} }: PeopleSocialMediaIconProps): JSX.Element => {
  const { hasValueOrIsEE, isEE } = useExpEditor();

  const { platform, url } = fields;
  const platformName = platform?.fields?.name?.value;
  if (!platformName && !hasValueOrIsEE(url)) {
    return <></>;
  }

  const iconMap = {
    Facebook,
    LinkedIn,
    Twitter,
    Instagram,
  };
  const Icon = iconMap[platformName as keyof typeof iconMap];

  return (
    <a
      className={styles.main}
      target="_blank"
      rel="noreferrer"
      href={url?.value}
      aria-label={platformName}
    >
      {Icon && <Icon className={styles.icon} />}
      {isEE && !Icon && <span>Click to edit.</span>}
    </a>
  );
};

export default PeopleSocialMediaIcon;
