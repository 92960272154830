import { Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { useDictionary } from 'src/hooks/useDictionary';

import { getRouteField } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseContacts from 'components/BaseHelpers/BaseContacts';

export type PeopleAuthorsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Authors;

const PeopleAuthors = (props: PeopleAuthorsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const { t } = useDictionary();

  const authors = getRouteField<Array<Item>>('authors', sitecoreContext);

  if (props?.fields?.moduleHeader) {
    props.fields.moduleHeader.value = t((authors?.length ?? 0) > 1 ? 'Authors' : 'Author');
  }

  return <BaseContacts {...props} contacts={authors} />;
};

export default PeopleAuthors;
