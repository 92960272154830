import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import EditFrame from 'components/BaseHelpers/EditFrame';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import { richTextStyles } from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { usePeopleDetailFields } from '../../../hooks/usePeopleDetailFields';

import styles from './PeopleAdmissions.module.scss';

export type PeopleAdmissionsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Admissions;

const PeopleAdmissions = ({ fields = {} }: PeopleAdmissionsProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { barJurisdictions, courts } = usePeopleDetailFields();
  const { hasItemsOrIsEE, noItemsAndIsEE } = useExpEditor();
  const { t } = useDictionary();
  const {
    sitecoreContext: { itemId },
  } = useSitecoreContext();

  if (!hasItemsOrIsEE(barJurisdictions) && !hasItemsOrIsEE(courts)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={moduleHeader} />
      <EditFrame itemId={itemId} fields={['Bar Jurisdictions', 'Courts']}>
        {hasItemsOrIsEE(barJurisdictions) && (
          <div className={richTextStyles.main}>
            {noItemsAndIsEE(barJurisdictions) && (
              <span>Bar Jurisdictions: [No data entered]. Click here to add.</span>
            )}
            <ul>
              {barJurisdictions?.map((item) => (
                <li key={item.id}>
                  <Text field={item.fields?.name} editable={false} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {hasItemsOrIsEE(courts) && (
          <div className={cn(styles.courts, richTextStyles.main)}>
            <Text className={styles.subhead} tag="h3" field={{ value: t('Courts') }} />
            {noItemsAndIsEE(courts) && <span>Courts: [No data entered]. Click here to add.</span>}
            <ul>
              {courts?.map((item) => (
                <li key={item.id}>
                  <Text field={item.fields?.name} editable={false} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </EditFrame>
    </AnimateInContainer>
  );
};

export default PeopleAdmissions;
