import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';

import styles from './DefaultTemplate.module.scss';

export type DefaultTemplateProps = {
  route: RouteData;
};

const DefaultTemplate = ({ route }: DefaultTemplateProps): JSX.Element => {
  return (
    <div className={styles.main}>
      <Placeholder name="head" rendering={route} />
      <header>
        <Placeholder name="header" rendering={route} />
      </header>
      <main className={styles.content} id="main">
        <Placeholder name="page-header" rendering={route} />
        <Placeholder name="content" rendering={route} />
        <Placeholder name="page-footer" rendering={route} />
      </main>
      <footer>
        <Placeholder name="footer" rendering={route} />
      </footer>
    </div>
  );
};
export default DefaultTemplate;
