import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { MetadataMetadataProps } from 'components/MetadataMetadata';

export const useMetaData = () => {
  const { sitecoreContext } = useSitecoreContext();

  const headPlaceholder = sitecoreContext.route?.placeholders['head'] || [];
  const metaDataComponent = headPlaceholder.find(
    (component) => 'componentName' in component && component.componentName === 'MetadataMetadata'
  );

  const metaData: Partial<MetadataMetadataProps['fields']> =
    metaDataComponent && 'fields' in metaDataComponent && metaDataComponent.fields
      ? metaDataComponent.fields
      : {};

  return {
    metaData,
  };
};
