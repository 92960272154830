import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import shuffle from 'lodash/shuffle';
import { useEffect, useMemo, useState } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useTemplate } from 'src/hooks/useTemplate';

import { handleManualViewAll } from 'src/utils/coveo';
import { hasValue } from 'src/utils/hasValue';
import { createLinkObject } from 'src/utils/sitecoreFieldHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import Text from 'components/BaseHelpers/Text';

import { RelatedContentCard } from './RelatedContentCard';

import styles from './InsightsRelatedContent.module.scss';

export type InsightResult = Website.Feature.Insights._Insight &
  Website.Feature.Insights._Event & {
    fields?: { name?: string; date?: Field<string>; url?: string; contentTag?: Field<string> };
  };

export type InsightsRelatedContentProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.RelatedContent & {
    fields: {
      search?: { results?: Array<InsightResult>; viewAllLink?: { text: string; url: string } };
      pinnedInsights?: Array<InsightResult>;
    };
  };

const MAX_CONTENT_CARDS = 4;

const CLIENT_STORIES_ID = '074d9ad9-3c30-4ade-b0b3-1ffb9e15cfff';
const AI_ENGINE_ID = '9b735a30-1bfc-40b4-b4e9-d4002736c3e9';

const InsightsRelatedContent = (props: InsightsRelatedContentProps): JSX.Element => {
  const {
    insightTypes,
    moduleHeader,
    pinnedInsights,
    search = {},
    hideImages,
    customViewAllLabel,
    customViewAllLink,
    hideViewAllLink,
  } = props.fields;
  const { results = [], viewAllLink } = search;
  const { isEE, hasItemsOrIsEE } = useExpEditor();
  const { isInsightLanding } = useTemplate();
  const { insightsEngine } = useEngineContext();
  const shouldHideImages = hideImages?.value;
  const isClientStories = useMemo(() => {
    return insightTypes?.[0]?.id === CLIENT_STORIES_ID;
  }, [insightTypes]);
  const isAiEngine = useMemo(() => {
    return insightTypes?.[0]?.id === AI_ENGINE_ID;
  }, [insightTypes]);

  const selectedInsights = useMemo(() => {
    const searchResults = isClientStories ? shuffle(results) : results;
    return getInsightsList(pinnedInsights, searchResults, shouldHideImages);
  }, [isClientStories, pinnedInsights, results, shouldHideImages]);

  // the order of Client Story cards are shuffled on every render
  // so this causes a mismatch between server and client
  // to avoid this, we only render this component on the client
  const [isBrowser, setIsBrowser] = useState<boolean>(!isClientStories);
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  if (!isBrowser || !hasItemsOrIsEE(selectedInsights)) {
    return <></>;
  }

  const viewAllUrl = hasValue(customViewAllLink)
    ? customViewAllLink
    : hasValue(viewAllLink?.url)
      ? viewAllLink?.url
      : undefined;

  const viewAllLabel = hasValue(customViewAllLabel)
    ? customViewAllLabel
    : hasValue(viewAllLink?.text)
      ? { value: viewAllLink?.text }
      : undefined;

  const showCta =
    isEE ||
    (hideViewAllLink?.value !== true &&
      hasValue(viewAllUrl) &&
      hasValue(viewAllLabel) &&
      !isAiEngine);

  const linkObject = typeof viewAllUrl === 'object' ? viewAllUrl : createLinkObject(viewAllUrl);

  return (
    <>
      <StopIndex />
      <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
        <ModuleHeader
          header={moduleHeader}
          tag={'h2'}
          variant={shouldHideImages ? 'double-line' : ''}
        />
        <ul className={cn(styles['grid-container'], { [styles['hide-images']]: shouldHideImages })}>
          {selectedInsights?.map((insight, index) => (
            <li
              className={cn(styles['grid-item'], {
                [styles['grid-item__main']]: !shouldHideImages && index === 0,
                [styles['grid-item__side']]: !shouldHideImages && index !== 0,
                [styles['grid-item__hide-images']]: shouldHideImages,
              })}
              key={index}
              style={{ '--card-count': selectedInsights.length } as React.CSSProperties}
            >
              <RelatedContentCard
                {...insight}
                isMainCard={!shouldHideImages && index === 0}
                hideImage={shouldHideImages || index !== 0}
                showCta={shouldHideImages}
                hideDate={isClientStory(insight.fields?.contentTag?.value)}
                contentType={insight.fields?.contentTag}
              />
            </li>
          ))}
        </ul>
        {showCta && (
          <BoxCta
            tag={isEE ? 'span' : 'a'}
            centered={true}
            field={linkObject}
            onClick={(e: React.MouseEvent) => {
              if (isClientStories) return;

              if (isInsightLanding && isInsightsQueryString(linkObject)) {
                e.preventDefault();
                handleManualViewAll(linkObject, insightsEngine);
              }
            }}
          >
            <Text field={viewAllLabel} editable={false} />
          </BoxCta>
        )}
      </AnimateInContainer>
      <StartIndex />
    </>
  );
};

const getInsightsList = (
  pinnedInsights: Array<InsightResult> | undefined,
  results: Array<InsightResult>,
  hideImages: boolean | undefined
) => {
  const combined = [...(pinnedInsights || []), ...(results || [])];
  if ((hideImages && !combined.length) || (!hideImages && combined.length < 2)) return;
  return combined.slice(0, hideImages ? MAX_CONTENT_CARDS - 1 : MAX_CONTENT_CARDS);
};

export const isClientStory = (insightType: string | undefined) => {
  return Boolean(insightType?.toLowerCase() === 'client stories');
};

const isInsightsQueryString = (
  url: { href: string; querystring: string } | LinkField | undefined
) => {
  if (!url) return false;
  const linkValue = 'value' in url ? url.value : url;
  const { href, querystring } = linkValue;
  return href?.includes('insights') && (querystring !== undefined || href?.split('?').length > 1);
};

export default InsightsRelatedContent;
