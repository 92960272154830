import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { getRouteField } from 'src/utils/sitecoreHelpers';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Text from 'components/BaseHelpers/Text';

import styles from './OfficeJobDetailHeader.module.scss';

const OfficeJobDetailHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const locationArray =
    getRouteField<NonNullable<Website.Project.Main.PageTypes.Offices.Office[]>>(
      'location',
      sitecoreContext
    ) || [];
  const jobTitle = getRouteField<NonNullable<Website.Feature.Offices.JobTitle>>(
    'jobTitle',
    sitecoreContext
  );

  const { city } = locationArray[0]?.fields || {};

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <Text
        className={styles[`job-title`]}
        tag="h1"
        field={jobTitle?.fields?.title}
        editable={false}
      />
      <div className={styles[`location`]}>
        <Text field={city} editable={false} />
      </div>
    </AnimateInContainer>
  );
};

export default OfficeJobDetailHeader;
