import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';

import ServiceList from '../ServiceList';

import styles from './ServicesFeaturedServices.module.scss';

export type ServicesFeaturedServicesProps = ComponentProps & {
  fields: {
    featuredServices: Website.Project.Main.PageTypes.Services.Service[];
  };
};

const ServicesFeaturedServices = (props: ServicesFeaturedServicesProps): JSX.Element => {
  const { featuredServices } = props?.fields || {};
  const { hasItemsOrIsEE } = useExpEditor();

  if (!hasItemsOrIsEE(featuredServices)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
      <ServiceList className={styles[`list`]} list={featuredServices} variant="columns" />
    </AnimateInContainer>
  );
};

export default ServicesFeaturedServices;
