import { DateFacet, DateFacetValue, Facet, FacetValue } from '@coveo/headless';

export type FacetController = Facet | DateFacet;
export type FacetValues = FacetValue | DateFacetValue;

export const COVEO_FIELDS = {
  associations: 'associationstitles',
  topics: 'concepts',
  services: 'servicestitles',
  industries: 'industriestitles',
  regions: 'regionstitles',
  offices: 'officestitles',
  officeslinks: 'officeslinks',
  positions: 'peopletitle',
  dateRange: 'datesort',
  contentTypes: 'contenttypestitles',
  jobtitle: 'jobtitle',
  joblocation: 'joblocation',
  jobregions: 'jobregions',
} as const;

export const COVEO_FIELDS_TC = {
  services: 'servicestitlestc',
  industries: 'industriestitlestc',
  offices: 'officestitlestc',
  officeslinks: 'officeslinkstc',
} as const;

export const DATE_RANGE_FIELDS = {
  now: 'now',
  pastWeek: 'past-1-week',
  pastMonth: 'past-1-month',
  pastThreeMonths: 'past-3-month',
  pastSixMonths: 'past-6-month',
  pastYear: 'past-1-year',
  older: 'past-200-year',
} as const;

// these are hidden facets that need to be present for `coveo` searches from links to work (like Related Content)
// but we don't want them visible in the UI
export const HIDDEN_FIELDS = {
  templateName: 'z95xtemplatename',
  associations: 'associationstitles',
} as const;
