import { useBaseSearch } from 'src/hooks/useBaseSearch';

import { FacetDropdown } from './FacetDropdown';

import styles from './SearchFacets.module.scss';

export const SearchFacets = () => {
  const { state } = useBaseSearch();
  const { facets } = state;

  if (facets.every((f) => !f.display)) return <></>;

  return (
    <ul className={styles['facet-list']}>
      {facets.map((facet) => {
        if (!facet.display) return null;
        return (
          <li className={styles['facet-list-item']} key={facet.field}>
            <FacetDropdown facet={facet} label={facet.label} />
          </li>
        );
      })}
    </ul>
  );
};
