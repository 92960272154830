import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

export const useSite = () => {
  const {
    sitecoreContext: { site },
  } = useSitecoreContext();

  const isTCSite = site?.name === 'tc';

  return {
    isTCSite,
    isMainSite: !isTCSite,
  };
};
