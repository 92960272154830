import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './BasicContentIconCard.module.scss';

export type BasicContentIconCardProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.IconCard;

const BasicContentIconCard = ({ fields = {} }: BasicContentIconCardProps): JSX.Element => {
  const { hasValueOrIsEE } = useExpEditor();
  const { t } = useDictionary();
  const { icon, headline, description, link } = fields;

  if (!hasValueOrIsEE(headline) && !hasValueOrIsEE(description) && !hasValueOrIsEE(icon)) {
    return <></>;
  }

  const linkWithTextOverride = {
    ...link,
    value: {
      ...link?.value,
      text: t('Learn More'),
    },
  };

  return (
    <div className={styles.main}>
      {hasValueOrIsEE(icon?.fields?.image as ImageField) && (
        <Image className={styles.icon} field={icon?.fields?.image} alt="" width={50} height={50} />
      )}
      <Text
        tag="h3"
        className={cn(styles.headline, {
          [styles.headline__noIcon]: !hasValue(icon?.fields?.image),
        })}
        field={headline}
      />
      <RichText className={styles.description} field={description} />
      <UnderlineCta tag="a" field={linkWithTextOverride} />
    </div>
  );
};

export default BasicContentIconCard;
