import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useGlobalStore } from 'src/hooks/useGlobalStore';
import { useLanguages, useLocales } from 'src/hooks/useLanguages';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useSite } from 'src/hooks/useSite';
import { useTemplate } from 'src/hooks/useTemplate';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Image from 'components/BaseHelpers/Image';
import Languages from 'components/BaseHelpers/Languages';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Overlay from 'components/BaseHelpers/Overlay';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import { Tab } from 'components/BaseHelpers/TabNavigation/TabNavigation';

import InsightsPanel from './components/InsightsPanel';
import LocaleDropdown, { Locale, ToggleLocales } from './components/LocaleDropdown/LocaleDropdown';
import MobileMenu from './components/MobileMenu';
import ToggleMobileMenu from './components/MobileMenu/ToggleMobileMenu/ToggleMobileMenu';
import NavBar from './components/NavBar/NavBar';
import NavPanel from './components/NavPanel';
import SearchDropdown from './components/SearchDropdown';
import ToggleSearchDropdown from './components/SearchDropdown/ToggleSearchDropdown';

import styles from './NavigationHeader.module.scss';

export type PanelNavigation = Website.Feature.Navigation.NavigationLink &
  Website.Project.Main.ComponentTypes.Navigation.NavigationInsightsSubnav &
  Website.Feature.Insights._TrendingTopics &
  Website.Feature.Navigation.NavigationSubnav & {
    children?: Website.Feature.Navigation.NavigationLink[];
  };

export type NavigationHeaderProps = ComponentProps & {
  fields?: Omit<
    NonNullable<Website.Project.Main.ComponentTypes.Navigation.Header['fields']>,
    'navigation'
  > & {
    navigation?: PanelNavigation[];
    locales?: Locale[];
  };
};

export const mapNavBarTabs = (tabs: PanelNavigation[]): Tab[] => {
  return tabs.map(({ fields, children, id }, index: number): Tab => {
    return {
      title: fields?.text,
      id: id || '',
      name: '',
      link: fields?.link,
      viewAllLink: fields?.viewAllLink,
      content: hasItems(children) ? (
        <NavPanel navPanelLinks={children} index={index} {...fields} />
      ) : fields?.trendingTopics || fields?.navigationItems ? (
        <InsightsPanel {...fields} />
      ) : undefined,
    };
  });
};

const NavigationHeader = ({ fields = {} }: NavigationHeaderProps): JSX.Element => {
  const {
    languageDropdownText,
    navigation = [],
    logo,
    locales = [],
    siteSearchPlaceholderText,
  } = fields;
  const tabs = useMemo(() => mapNavBarTabs(navigation), [navigation]);
  const router = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const { homeAnimationPlayed } = useGlobalStore();
  const { isHomePage } = useTemplate();
  const { isTCSite } = useSite();

  const [localesOpen, setLocalesOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [tabPanelOpen, setTabPanelOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [subNavOpen, setSubNavOpen] = useState<number>(-1);
  const [scrollState, setScrollState] = useState<string>('');
  const [atLoad, setAtLoad] = useState<boolean>(true);
  const [atTop, setAtTop] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);
  const [transitionGoing, setTransitionGoing] = useState<boolean>(false);

  const { isEE } = useExpEditor();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const navbarRef = useRef<HTMLDivElement>(null);
  const navTopRef = useRef<HTMLDivElement>(null);
  const localesToggleRef = useRef<HTMLButtonElement>(null);
  const searchToggleRef = useRef<HTMLButtonElement>(null);
  const menuToggleRef = useRef<HTMLButtonElement>(null);

  const { t } = useDictionary();

  const languages = useLanguages();
  const filteredLocales = useLocales(locales);

  const hiddenClassName = useMemo(() => {
    return (!atTop && scrollState == 'down' && !atEnd && !atLoad) ||
      isEE ||
      (!homeAnimationPlayed && isHomePage)
      ? styles['main--hidden']
      : '';
  }, [atEnd, atLoad, atTop, homeAnimationPlayed, isEE, isHomePage, scrollState]);

  const handleTabPanel = (isPanelOpen: boolean) => {
    return setTabPanelOpen(isPanelOpen);
  };

  const overlayOpen = useMemo(() => {
    return (localesOpen || searchOpen || tabPanelOpen) && isDesktop;
  }, [localesOpen, searchOpen, tabPanelOpen, isDesktop]);

  useEffect(() => {
    if (!navbarRef?.current) return;
    if (!overlayOpen) {
      enableBodyScroll(navbarRef.current);
    } else {
      disableBodyScroll(navbarRef.current, {
        allowTouchMove: (_) => true,
      });
    }
  }, [overlayOpen]);

  useEffect(() => {
    if (isEE) return;
    let prevScrollTop = 0;
    const headroomListener = () => {
      const scrollTop = window.pageYOffset;

      if (atLoad && prevScrollTop !== 0 && prevScrollTop !== scrollTop) {
        setAtLoad(false);
      }

      if (window.pageYOffset <= (navbarRef.current?.offsetHeight || 0)) {
        setAtTop(true);
        setAtEnd(false);
      } else if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setAtEnd(true);
      } else {
        setAtTop(false);
        setAtEnd(false);
      }

      if (scrollTop > prevScrollTop) {
        setScrollState('down');
      } else if (scrollTop < prevScrollTop) {
        setScrollState('up');
      }
      prevScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    headroomListener();

    window.addEventListener('scroll', headroomListener);

    return () => {
      window.removeEventListener('scroll', headroomListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const navbarHeight = navbarRef.current?.offsetHeight;
    // the `top` property of the image in `PeopleDetailHeroLeft` needs to adjust depending on whether or not the NavBar is visible
    document.documentElement.style.setProperty(
      '--PeopleDetailHeroLeft__nav-bar-spacing',
      hiddenClassName ? `${navbarHeight}px` : '0px'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenClassName]);

  useEffect(() => {
    setMenuOpen(false);
    clearAllBodyScrollLocks();
  }, [sitecoreContext?.language, router.asPath]);

  useEffect(() => {
    const navbarHeight = navbarRef.current?.offsetHeight;
    document.documentElement.style.setProperty('--NavigationHeader__height', `${navbarHeight}px`);
    const navTopHeight = navTopRef.current?.offsetHeight;
    document.documentElement.style.setProperty('--NavigationTop__height', `${navTopHeight}px`);
  });

  const homeLinkLabel = isTCSite ? 'Tauil Chequer' : 'Mayer Brown';

  const handleSubNavSelect = useCallback((index: number) => {
    setTransitionGoing(true);
    setSubNavOpen(index);
  }, []);

  const handleSearchSubmit = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleTransitionEnd = useCallback(() => {
    setTransitionGoing(false);
  }, []);

  return (
    <>
      <StopIndex />
      <div
        ref={navbarRef}
        className={cn(styles[`main`], hiddenClassName, {
          [styles[`main--homepage`]]: isHomePage,
        })}
      >
        <div className={cn(styles[`header-container`])}>
          {!menuOpen && (
            <a href="#main" className={styles['skip-link']}>
              {t('Skip to main content')}
            </a>
          )}
          <FocusTrap
            active={menuOpen && subNavOpen === -1 && !transitionGoing}
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              fallbackFocus: subNavOpen === -1 ? `.${menuToggleRef?.current?.className}` : ``,
            }}
          >
            <div style={{ display: 'contents' }}>
              <div className={cn(styles[`top-container`])} ref={navTopRef}>
                <div className={cn(styles[`top`], 'container-12')}>
                  <div className={styles['menu-toggle']}>
                    <ToggleLocales
                      localesOpen={localesOpen}
                      setLocalesOpen={setLocalesOpen}
                      localeDropdownText={languageDropdownText}
                      toggleRef={localesToggleRef}
                    />
                    <ToggleMobileMenu
                      menuOpen={menuOpen}
                      setMenuOpen={setMenuOpen}
                      menuToggleRef={menuToggleRef}
                    />
                  </div>
                  <LinkOrComponent
                    field={isHomePage ? undefined : '/'}
                    tag={isHomePage ? 'h1' : undefined}
                    aria-label={`${homeLinkLabel} ${t('Home')}`}
                    className={styles[`logo`]}
                  >
                    <div className={styles[`logo-image`]}>
                      <Image
                        fill={true}
                        field={logo}
                        alt={isHomePage ? homeLinkLabel : ''}
                        priority={true}
                      />
                    </div>
                  </LinkOrComponent>
                  <div className={styles[`search-toggle`]}>
                    <ToggleSearchDropdown
                      searchToggleRef={searchToggleRef}
                      searchOpen={searchOpen}
                      setSearchOpen={setSearchOpen}
                      setMenuOpen={setMenuOpen}
                      menuOpen={menuOpen}
                    />
                  </div>
                </div>
                <MobileMenu
                  navigation={navigation}
                  siteSearchPlaceholderText={siteSearchPlaceholderText}
                  transitionGoing={transitionGoing}
                  menuOpen={menuOpen}
                  locales={filteredLocales}
                  localeDropdownText={languageDropdownText}
                  subNavIndex={subNavOpen}
                  onSubNavSelect={handleSubNavSelect}
                  onTransitionEnd={handleTransitionEnd}
                  onSearchSubmit={handleSearchSubmit}
                />
              </div>
            </div>
          </FocusTrap>
        </div>
        <LocaleDropdown
          localesOpen={localesOpen}
          locales={filteredLocales}
          setLocalesOpen={setLocalesOpen}
          toggleRef={localesToggleRef}
        />

        <NavBar tabs={tabs} onTabPanel={handleTabPanel} />

        <SearchDropdown
          siteSearchPlaceholderText={siteSearchPlaceholderText}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          toggleRef={searchToggleRef}
        />
        <div className={styles[`languages`]}>
          <Languages langs={languages} />
        </div>
        <Overlay isVisible={overlayOpen} />
      </div>

      <StartIndex />
    </>
  );
};

export default NavigationHeader;
