import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import FullWidthEmbed from 'components/BaseHelpers/FullWidthEmbed';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import styles from './BasicContentFullWidthEmbed.module.scss';

export type BasicContentFullWidthEmbedProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.FullWidthEmbed;

const BasicContentFullWidthEmbed = ({
  fields = {},
}: BasicContentFullWidthEmbedProps): JSX.Element => {
  const { hasValueOrIsEE } = useExpEditor();

  if (!hasValueOrIsEE(fields.embedCode)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn('container-10', 'spacer')}>
      <ModuleHeader className={styles.header} header={fields.moduleHeader} />
      <FullWidthEmbed embedCode={fields?.embedCode} />
    </AnimateInContainer>
  );
};

export default BasicContentFullWidthEmbed;
