import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';
import { fieldOrSourceField, imageOrSourceImage } from 'src/utils/sitecoreFieldHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ImageCta from 'components/BaseHelpers/ImageCta';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './InsightsFeaturedCard.module.scss';

type FeaturedCard = Website.Project.Main.ComponentTypes.Insights.FeaturedCard;

export type InsightsFeaturedCardProps = ComponentProps &
  Omit<FeaturedCard, 'fields'> & {
    fields?: Omit<NonNullable<FeaturedCard['fields']>, 'source'> & {
      source?: {
        url?: string;
        headline?: string;
        bodyText?: string;
        image?: ImageFieldValue;
      };
    };
  };

const InsightsFeaturedCard = ({ fields = {} }: InsightsFeaturedCardProps): JSX.Element => {
  const { source } = fields;
  const { hasValueOrIsEE, isEE } = useExpEditor();

  const image = imageOrSourceImage(fields, source, isEE);
  const headline = fieldOrSourceField(fields, fields?.source, 'headline', isEE);
  const bodyText = fieldOrSourceField(fields, fields?.source, 'bodyText', isEE);
  const url = hasValue(fields?.url) ? fields?.url : source?.url;

  if (!hasValueOrIsEE(image) || !hasValueOrIsEE(headline)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn('container-10', 'spacer')}>
      <LinkOrComponent field={url} className={cn(styles.main)} editable={false}>
        <ImageCta field={url} className={styles[`image`]} image={image} />
        <div className={styles['text-container']}>
          <Text className={styles[`headline`]} tag="h2" field={headline} editable={false} />
          <RichText
            className={styles[`body-text`]}
            field={bodyText}
            replaceAnchorTags={true}
            editable={false}
          />
        </div>
      </LinkOrComponent>
    </AnimateInContainer>
  );
};

export default InsightsFeaturedCard;
