import cn from 'classnames';
import { ReactNode } from 'react';

import { Tab } from '../TabNavigation';

import styles from './TabPanel.module.scss';

export type TabPanelProps = {
  tab: Tab;
  isActive: boolean;
  variant: string;
  children: ReactNode;
};

const TabPanel = ({ tab, isActive, variant, children }: TabPanelProps): JSX.Element => {
  return (
    <div
      key={`tabpanel-${tab.id}`}
      id={`tabpanel-${tab.id}`}
      role="tabpanel"
      tabIndex={isActive ? 0 : undefined}
      aria-labelledby={`tab-${tab.id}`}
      className={cn(styles[`panel`], styles[variant], {
        [styles[`active`]]: isActive,
      })}
    >
      {children}
    </div>
  );
};

export default TabPanel;
