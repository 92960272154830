import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

import { useCountryCode } from './useCountryCode';

export interface GlobalStoreContextValue {
  homeAnimationPlayed: boolean;
  setHomeAnimationPlayed: Dispatch<SetStateAction<boolean>>;
  countryCode: string;
}

export const GlobalStoreContext = createContext<GlobalStoreContextValue | undefined>(undefined);

export const GlobalStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [homeAnimationPlayed, setHomeAnimationPlayed] = useState(false);
  const { countryCode } = useCountryCode();

  const value = {
    homeAnimationPlayed,
    setHomeAnimationPlayed,
    countryCode,
  };

  return <GlobalStoreContext.Provider value={value}>{children}</GlobalStoreContext.Provider>;
};

export const useGlobalStore = () => {
  const context = useContext(GlobalStoreContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a ContextProvider');
  }
  return context;
};
