import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentCTAButton.module.scss';

export type BasicContentCTAButtonProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.CTAButton;

const BasicContentCTAButton = (props: BasicContentCTAButtonProps): JSX.Element => {
  const { buttonLabel, link } = props.fields || {};
  const { isEE } = useExpEditor();
  const ctaTag = isEE ? 'span' : 'a';

  return (
    <BoxCta className={cn(styles['main'], 'spacer')} tag={ctaTag} centered={true} field={link}>
      <Text field={buttonLabel} />
    </BoxCta>
  );
};

export default BasicContentCTAButton;
