import cn from 'classnames';

import { usePdfStore } from 'src/hooks/usePdfStore';

import { hasValue } from 'src/utils/hasValue';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Date from 'components/BaseHelpers/Date';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import { richTextStyles } from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { InsightsPeopleRelatedContentProps } from './InsightsPeopleRelatedContent';

import styles from './PdfInsightsPeopleRelatedContent.module.scss';

export type PdfInsightsPeopleRelatedContentProps = InsightsPeopleRelatedContentProps;

type ProfessionalRelatedInsights = {
  newsLabel: string;
  newsResults: Array<ProfessionalRelatedInsightsResult>;
  eventsLabel: string;
  eventsResults: Array<ProfessionalRelatedInsightsResult>;
  publicationsLabel: string;
  publicationsResults: Array<ProfessionalRelatedInsightsResult>;
};

type ProfessionalRelatedInsightsResult = {
  title: string;
  bodyText: string;
  image: string;
  date: string;
  displayDate: string;
  url: string;
  source: string;
  insightType: string;
};

const PdfInsightsPeopleRelatedContent = (
  props: PdfInsightsPeopleRelatedContentProps
): JSX.Element => {
  const { moduleHeader } = props.fields || {};
  const { additionalData } = usePdfStore();

  if (!additionalData?.professionalRelatedInsights) {
    return <></>;
  }

  const data = additionalData.professionalRelatedInsights as ProfessionalRelatedInsights;

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
      <ModuleHeader header={moduleHeader} />
      <div className={richTextStyles.main}>
        {renderList(data.publicationsLabel, data.publicationsResults)}
        {renderList(data.eventsLabel, data.eventsResults)}
        {renderList(data.newsLabel, data.newsResults)}
      </div>
    </AnimateInContainer>
  );
};

const renderList = (header: string, items: Array<ProfessionalRelatedInsightsResult>) => {
  if (items.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.group}>
      <h3 className={styles.header}>{header}</h3>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={index}>
            <span className={styles['article-title']}>
              &quot;
              <Text field={{ value: item.title }} />
              ,&quot;
            </span>
            {hasValue(item.source) && (
              <span className={styles['article-source']}>
                <Text field={{ value: item.source }} />,
              </span>
            )}
            <Date
              className={styles['article-date']}
              field={item.date}
              displayDate={item.displayDate}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PdfInsightsPeopleRelatedContent;
