import { RefObject } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import styles from './ToggleSearchDropdown.module.scss';

export type ToggleSearchDropdownProps = {
  searchOpen: boolean;
  setSearchOpen: (searchOpen: boolean) => void;
  searchToggleRef: RefObject<HTMLButtonElement>;
  setMenuOpen: (menuOpen: boolean) => void;
  menuOpen: boolean;
};

const ToggleSearchDropdown = ({
  searchOpen,
  setSearchOpen,
  searchToggleRef,
  setMenuOpen,
  menuOpen,
}: ToggleSearchDropdownProps): JSX.Element => {
  const { t } = useDictionary();
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <button
      className={styles[`main`]}
      aria-label={isDesktop ? t('Search') : t('Mobile Menu')}
      aria-expanded={searchOpen}
      aria-controls={'search-dropdown-desktop'}
      ref={searchToggleRef}
      onClick={() => {
        if (isDesktop) {
          setSearchOpen(!searchOpen);
        } else {
          setMenuOpen(!menuOpen);
        }
      }}
    >
      <svg
        className={styles.magnifying}
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1068 13.0226L12.8618 14.3165L20.255 22L21.5001 20.7061L14.1068 13.0226Z"
          fill={!searchOpen ? '#B2985F' : '#D8D8D8'}
        />
        <path
          d="M15.0139 2.63314C11.6981 -0.877712 6.30271 -0.877712 2.98687 2.63314C-0.328958 6.14399 -0.328958 11.8567 2.98687 15.3675C5.64524 18.1822 9.63979 18.7393 12.8281 17.041L11.5468 15.6843C9.09112 16.7292 6.17266 16.2004 4.18622 14.0972C1.53211 11.287 1.53211 6.71413 4.18622 3.90347C6.84033 1.09326 11.1596 1.09326 13.8137 3.90347C15.8579 6.06794 16.3263 9.27774 15.2217 11.9228L16.4899 13.2656C18.2196 9.85643 17.7279 5.5077 15.0139 2.63359V2.63314Z"
          fill={!searchOpen ? '#1D1D1B' : '#D8D8D8'}
        />
      </svg>
    </button>
  );
};

export default ToggleSearchDropdown;
