import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { ImageLoader, ImageLoaderProps } from 'next/image';
import { ComponentProps } from 'react';

import { usePdf } from 'src/hooks/usePdf';

import styles from './Image.module.scss';

const cloudflareLoader: ImageLoader = (props: ImageLoaderProps): string => {
  const { src, width, quality = 90 } = props;
  const params = [`width=${width}`, `quality=${quality}`, 'format=auto'];
  return `/cdn-cgi/image/${params.join(',')}/${src}`;
};

export type ImageProps = ComponentProps<typeof NextImage>;

const Image = (props: ImageProps): JSX.Element => {
  const { isPdf } = usePdf();

  return (
    <NextImage
      sizes="100vw"
      loader={cloudflareLoader}
      unoptimized={process.env.NEXT_PUBLIC_RESIZE_IMAGES !== 'true'}
      {...props}
      priority={isPdf || props.priority}
      className={cn(props.className, styles.main)}
    />
  );
};
export default Image;
