import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useMetaData } from 'src/hooks/useMetaData';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import SocialIcons from 'components/BaseHelpers/SocialIcon';
import { SocialIconName } from 'components/BaseHelpers/SocialIcon';

import styles from './BasicContentShareTray.module.scss';

export type BasicContentShareTrayProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.ShareTray;

const BasicContentShareTray = ({ fields = {} }: BasicContentShareTrayProps): JSX.Element => {
  const { platforms, shareLabel } = fields;
  const { hasValueOrIsEE, hasItemsOrIsEE } = useExpEditor();
  const {
    metaData: { canonicalUrl, pageTitle },
  } = useMetaData();

  if (!hasItemsOrIsEE(platforms) && !hasValueOrIsEE(shareLabel)) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'spacer')}>
      <div className={cn(styles.container)}>
        <RichText className={styles[`share-label`]} field={shareLabel} />
        <ul className={styles.platforms}>
          {platforms?.map((link, index) => {
            const hrefLink = link?.fields?.linkFormat?.value
              .replace('{0}', `${canonicalUrl}`)
              .replace('{1}', `${pageTitle}`);
            return (
              <li className={styles.item} key={index}>
                <a
                  href={hrefLink}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={link?.fields?.name?.value}
                >
                  {link?.fields?.name && (
                    <SocialIcons
                      theme="light"
                      iconName={link.fields?.name?.value as SocialIconName}
                    />
                  )}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BasicContentShareTray;
