import cn from 'classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import Text from 'components/BaseHelpers/Text';

import { Tab } from '../TabNavigation';

import styles from './TabButton.module.scss';

interface TabButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  tab: Tab;
  isSelected: boolean;
  variant: 'content-tab' | 'navbar';
}
export const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(function TabButtonComponent(
  { tab, isSelected, variant, ...restProps },
  ref
) {
  const { id, title, name } = tab;
  // These are not editable in sitecore due to being used outside the placeholder component so ignoring side effet unused elements
  if (name === 'code') {
    return <></>;
  }

  return (
    <button
      ref={ref}
      className={cn(styles[`tab-nav`], styles[variant], {
        [styles[`tab-nav--active`]]: isSelected,
      })}
      id={`tab-${id}`}
      role={variant === 'content-tab' ? 'tab' : undefined}
      aria-expanded={variant === 'navbar' ? isSelected : undefined}
      aria-selected={variant === 'content-tab' ? isSelected : undefined}
      aria-controls={`tabpanel-${id}`}
      tabIndex={-1}
      {...restProps}
    >
      <Text editable={false} className={styles[`nav-text`]} field={title} />
    </button>
  );
});
