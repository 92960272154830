import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useEffect, useRef } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';

import { formatInternalLinkToRelativeLink } from 'src/utils/formatInternalLinkToRelativeLink';

import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import SmallArrow from '../../../../../assets/icons/small-arrow.svg';
import { PanelNavigation } from '../../NavigationHeader';
import InsightsPanel from '../InsightsPanel';
import { Locale } from '../LocaleDropdown/LocaleDropdown';
import SiteSearchBox from '../SearchDropdown/SiteSearchBox';

import styles from './MobileMenu.module.scss';

export type MobileMenuProps = {
  menuOpen: boolean;
  transitionGoing: boolean;
  siteSearchPlaceholderText?: Field<string>;
  navigation?: PanelNavigation[];
  locales: Locale[];
  localeDropdownText?: Field<string>;
  onSubNavSelect: (index: number) => void;
  onTransitionEnd: () => void;
  onSearchSubmit: () => void;
  subNavIndex: number;
};

const MobileMenu = ({
  navigation,
  locales,
  siteSearchPlaceholderText,
  localeDropdownText,
  menuOpen,
  subNavIndex,
  transitionGoing,
  onSubNavSelect,
  onTransitionEnd,
  onSearchSubmit,
}: MobileMenuProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { t } = useDictionary();
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const subnavPanelRefs = useRef<HTMLDivElement[] | null[]>([]);
  const savedScrollPosition = useRef<number>(0);

  useEffect(() => {
    onSubNavSelect(-1);
    if (!mobileMenuRef.current) return;
    if (menuOpen) {
      savedScrollPosition.current = window.scrollY;
      disableBodyScroll(mobileMenuRef.current, {
        allowTouchMove: (_) => true,
      });
    } else {
      enableBodyScroll(mobileMenuRef.current);
      // TS thinks `'instant'` is not a valid value for `behavior`: https://github.com/microsoft/TypeScript/issues/47441
      window.scrollTo({ top: savedScrollPosition.current, behavior: 'instant' as ScrollBehavior });
      savedScrollPosition.current = 0;
    }
  }, [menuOpen, onSubNavSelect]);

  return (
    <div
      ref={mobileMenuRef}
      id="mobile-menu"
      aria-hidden={!menuOpen}
      className={cn(styles[`main`])}
    >
      <div className={cn(styles[`wrapper`], { [styles[`open`]]: menuOpen })}>
        <div
          className={cn(styles['inner'], { [styles[`visible`]]: menuOpen && subNavIndex === -1 })}
        >
          <SiteSearchBox
            siteSearchPlaceholderText={siteSearchPlaceholderText}
            onSearchSubmit={onSearchSubmit}
          />

          <div className={styles[`link-content`]}>
            <ul className={styles[`nav-links`]}>
              {navigation?.map(({ fields, id, children }, index) => {
                return (
                  <li key={`mobile-nav-link-${id}`} className={styles[`nav-link`]}>
                    {children?.length || fields?.trendingTopics || fields?.navigationItems ? (
                      <>
                        <UnderlineCta
                          tag="button"
                          underlineOnHover={true}
                          onClick={() => {
                            onSubNavSelect(index);
                          }}
                          onTransitionEnd={onTransitionEnd}
                          id={`toggle-nav-panel-${id}`}
                          aria-expanded={subNavIndex === index}
                          aria-controls={`subnav-panel-${id}`}
                          text={fields?.text?.value}
                          className={styles[`nav-link__text`]}
                        />
                        <SmallArrow className={styles[`nav-link__icon`]} />
                      </>
                    ) : (
                      <Link field={formatInternalLinkToRelativeLink(fields?.link)}>
                        <Text
                          className={cn(styles[`nav-link__text`], styles['nested-text'])}
                          field={fields?.text}
                          editable={false}
                        />
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
            <Text className={styles[`locale-label`]} field={localeDropdownText} />
            <ul className={styles[`locales`]}>
              {locales.map((locale, index) => {
                return (
                  <li key={`locale-mobile-${index}`} className={styles[`locale`]}>
                    <LinkOrComponent
                      className={cn(styles[`locale-link`])}
                      tag="span"
                      field={
                        sitecoreContext?.language !== locale?.name
                          ? `/${locale?.name?.toLowerCase()}`
                          : undefined
                      }
                    >
                      {locale?.name}
                    </LinkOrComponent>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles[`subnav-panels`]}>
          {navigation?.map(({ id, children, fields }, index) => {
            return (
              (children?.length || fields?.trendingTopics || fields?.navigationItems) && (
                <FocusTrap
                  key={`subnav-panel=${id}`}
                  active={menuOpen && subNavIndex === index && !transitionGoing}
                  focusTrapOptions={{
                    clickOutsideDeactivates: true,
                    fallbackFocus: `#back-to-main-${id}`,
                    setReturnFocus: `#toggle-nav-panel-${id}`,
                  }}
                >
                  <div
                    id={`subnav-panel-${id}`}
                    ref={(element) => (subnavPanelRefs.current[index] = element)}
                    key={`subnav-panel=${id}`}
                    className={cn(styles[`subnav-panel`], {
                      [styles[`active`]]: subNavIndex === index,
                    })}
                  >
                    <div className={styles[`back-link`]}>
                      <SmallArrow />
                      <UnderlineCta
                        aria-controls={`subnav-panel-${id}`}
                        underlineOnHover={true}
                        tag="button"
                        text={t('Main Menu')}
                        aria-expanded={subNavIndex === index}
                        onClick={() => onSubNavSelect(-1)}
                        onTransitionEnd={onTransitionEnd}
                        id={`back-to-main-${id}`}
                      ></UnderlineCta>
                    </div>
                    {children?.length && (
                      <ul className={styles[`subnav-links`]}>
                        {children?.map((childLink, childLinkIndex) => {
                          return (
                            <li key={`child-link-${id}-${childLinkIndex}`}>
                              <UnderlineCta
                                tag="a"
                                underlineOnHover={true}
                                className={styles[`subnav-link`]}
                                field={formatInternalLinkToRelativeLink(childLink?.fields?.link)}
                              >
                                <Text field={childLink?.fields?.text} editable={false} />
                              </UnderlineCta>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {fields?.trendingTopics || fields?.navigationItems ? (
                      <InsightsPanel {...fields} />
                    ) : (
                      <UnderlineCta
                        className={styles[`header-links`]}
                        field={formatInternalLinkToRelativeLink(fields?.viewAllLink)}
                        tag="a"
                      >
                        <Text
                          field={fields?.viewAllLinkText}
                          className={styles['nested-text']}
                          editable={false}
                        />
                      </UnderlineCta>
                    )}
                  </div>
                </FocusTrap>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
