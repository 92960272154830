import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useCoveoFieldConfig } from 'src/hooks/useCoveoFieldConfig';
import { useSite } from 'src/hooks/useSite';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';
import { BaseSearchBox } from 'components/BaseHelpers/BaseSearch/BaseSearchBox';
import BaseSearchResults from 'components/BaseHelpers/BaseSearchResults';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { SearchFacets } from './SearchFacets';
import { SectionFacet } from './SectionFacet';

import styles from './SearchSearch.module.scss';

export type SearchSettings = Website.Feature.Search.SearchSettings;

export type SearchSearchProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Search.Search & {
    fields: {
      settings: SearchSettings;
    };
  };

export type SearchRouteFields = {
  topicsFilter?: Array<{ fields: { numberOfResults: Field<number>; name: Field<string> } }>;
};

const DEFAULT_NUMBER_OF_RESULTS = 9;

const SearchSearch = (props: SearchSearchProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { isTCSite } = useSite();
  const { siteEngine } = useEngineContext();
  const {
    moduleHeader,
    searchKeywordPlaceholderText,
    resultCountLabelFormat,
    didYouMeanLabelFormat,
    searchResultsPageSize,
    typeaheadResultsPageSize,
    noResultsText,
    fallbackText,
  } = props.fields || {};
  const { topicsFilter } = getRouteFields<SearchRouteFields>(sitecoreContext);
  const topicsFacetValues = useMemo(() => {
    return (topicsFilter || []).map((value) => value.fields?.name?.value);
  }, [topicsFilter]);
  const { facetFields, sectionFacets } = useCoveoFieldConfig('site');
  const sectionFacetKeys = Object.keys(sectionFacets);
  const facetLabels = isTCSite
    ? sectionFacetKeys.filter((label) => label !== 'Client Stories')
    : sectionFacetKeys;
  const [currentSection, setCurrentSection] = useState<Array<string>>(sectionFacets['All Results']);
  const displayCondition = useCallback(
    (field: string) => {
      return currentSection.includes(field);
    },
    [currentSection]
  );

  const handleSelectSection = useCallback(
    (field: keyof typeof sectionFacets) => {
      setCurrentSection(sectionFacets[field]);
    },
    [sectionFacets]
  );

  const facetConfig = {
    facetFields,
    topicsFacetValues,
    displayCondition,
    config: {
      numberOfValues: 10,
    },
  };
  const resultConfig = {
    resultPageSize: searchResultsPageSize || { value: DEFAULT_NUMBER_OF_RESULTS },
    suggestionPageSize: typeaheadResultsPageSize,
  };

  return (
    <BaseSearch engine={siteEngine} facetConfig={facetConfig} resultConfig={resultConfig}>
      <div className={cn(styles['main'], 'spacer')}>
        <Text
          field={moduleHeader}
          tag="h2"
          className={cn(styles['search-header'], 'container-12')}
        />
        <form
          className={styles['search-form']}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={cn(styles['search-box-wrapper'], 'container-12')}>
            <BaseSearchBox
              className={styles['search-box']}
              placeholder={searchKeywordPlaceholderText}
            />
          </div>

          <div className={styles['section-facet-wrapper']}>
            <SectionFacet
              sectionLabel={'Sections'}
              facetLabels={facetLabels}
              onSelectSection={handleSelectSection}
            />
          </div>
          <div className={styles['facets-wrapper']}>
            <SearchFacets />
          </div>
        </form>
        <BaseSearchResults
          className={styles['results']}
          statsTemplate={resultCountLabelFormat}
          correctionTemplate={didYouMeanLabelFormat}
          fallback={<RichText field={fallbackText} styled={true} />}
          noResults={
            <RichText field={noResultsText} styled={true} className={styles['no-results']} />
          }
        />
      </div>
    </BaseSearch>
  );
};

export default SearchSearch;
