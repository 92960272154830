import { useI18n } from 'next-localization';
import { useCallback } from 'react';

export function useDictionary(): { t: (key: string) => string } {
  const { t: _t } = useI18n();

  const translate = useCallback((key: string) => {
    return _t(key) || key;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { t: translate };
}
