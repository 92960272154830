import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export const getPhone = (phone?: Field<string>) => {
  if (phone?.value) {
    return {
      text: phone?.value,
      href: `tel:${phone?.value}`,
    };
  }
  return undefined;
};
