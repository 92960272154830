import { RichText as JssRichText, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { ComponentProps, HTMLAttributes } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { replaceAnchors } from 'src/utils/replaceAnchors';

import styles from './RichText.module.scss';

export type RichTextProps = ComponentProps<typeof JssRichText> &
  HTMLAttributes<HTMLDivElement> & {
    /**
     * (default: `true`) disable rich text styling.
     * */
    styled?: boolean;
    /**
     * (default: `false`) replace `<a></a>` tags with `<span></span>` tags if this component is nested inside another link
     */
    replaceAnchorTags?: boolean;
  };

const RichText = (props: RichTextProps): JSX.Element => {
  const { hasValueOrIsEE, isEE } = useExpEditor();
  const { styled = true, replaceAnchorTags = false, className, field, ...rest } = props;

  if (hasValueOrIsEE(field)) {
    const richTextField = { ...field } as RichTextField;
    if (replaceAnchorTags) richTextField.value = replaceAnchors(richTextField.value || '');
    return (
      <JssRichText
        className={cn(styled && styles.main, className)}
        field={richTextField}
        internalLinksSelector={
          // Disable link prefetching if in exp editor or if environment variable is set
          isEE || process.env.NEXT_PUBLIC_DISABLE_NEXT_LINK_PREFETCH === 'true'
            ? 'this-wont-match-anything'
            : // We want to prefetch internal links but we don't need media files to be prefetched
              'a[href^="/"]:not(a[href^="/-/media"])'
        }
        {...rest}
      />
    );
  }

  return <></>;
};

export default RichText;
