import {
  RenderingType,
  SitecoreContext,
  ComponentPropsContext,
  EditingComponentPlaceholder,
  StaticPath,
  LayoutServiceData,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useEffect } from 'react';
import Layout from 'src/Layout';
import NotFound from 'src/NotFound';
// different componentFactory method will be used based on whether page is being edited
import { componentFactory, editingComponentFactory } from 'temp/componentFactory';

import { EngineProvider } from 'src/hooks/useCoveoEngine';
import { GlobalStoreProvider } from 'src/hooks/useGlobalStore';

import { deleteNextjsCachedFiles } from 'lib/deleteNextjsCachedFiles';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';

import ErrorBoundary from 'components/BaseHelpers/ErrorBoundary';

const SitecorePage = ({ notFound, componentProps, layoutData }: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isComponentRendering =
    layoutData.sitecore.context.renderingType === RenderingType.Component;

  return (
    <ErrorBoundary>
      <GlobalStoreProvider>
        <EngineProvider>
          <ComponentPropsContext value={componentProps}>
            <SitecoreContext
              componentFactory={isEditing ? editingComponentFactory : componentFactory}
              layoutData={layoutData}
            >
              {/*
              Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
              If you are using Experience Editor only, this logic can be removed, Layout can be left.
            */}
              {isComponentRendering ? (
                <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
              ) : (
                <Layout layoutData={layoutData} />
              )}
            </SitecoreContext>
          </ComponentPropsContext>
        </EngineProvider>
      </GlobalStoreProvider>
    </ErrorBoundary>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  let paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      paths = await sitemapFetcher.fetch(context);
    } catch (error) {
      console.log('Error occurred while fetching static paths');
      console.log(error);
    }

    fallback = process.env.EXPORT_MODE ? false : fallback;
  }

  return {
    paths,
    fallback,
  };
};

type LayoutServiceDataWithRedirectUrl = LayoutServiceData & {
  sitecore: {
    context: {
      redirectUrl?: string;
    };
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  const props = await sitecorePagePropsFactory.create(context);

  const customLayoutData = props.layoutData as LayoutServiceDataWithRedirectUrl;

  // Next.js will attempt to re-generate the page:
  // - When a request comes in
  // - At most once every 30 seconds
  const revalidate = parseInt(process.env.REVALIDATE_INTERVAL || '30', 10); // In seconds

  // Check if we have a redirect via the redirects module. Note: redirectUrl is not populated when FETCH_WITH=GraphQL
  if (customLayoutData?.sitecore?.context?.redirectUrl) {
    await deleteNextjsCachedFiles(context);
    return {
      revalidate,
      redirect: {
        destination: customLayoutData.sitecore.context.redirectUrl,
        permanent: true,
      },
    };
  }

  // Check if we have a redirect (e.g. custom error page)
  if (props?.redirect) {
    await deleteNextjsCachedFiles(context);
    return {
      revalidate,
      redirect: props.redirect,
    };
  }

  if (props?.notFound || !props.layoutData?.sitecore?.route) {
    await deleteNextjsCachedFiles(context);
  }

  return {
    props,
    revalidate,
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
