import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { formatInternalLinkToRelativeLink } from 'src/utils/formatInternalLinkToRelativeLink';

import { Website } from 'lib/component-props/model';

import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './NavPanel.module.scss';

export type NavPanelProps = {
  siteSearchPlaceholderText?: Field<string>;
  navPanelLinks?: Website.Feature.Navigation.NavigationLink[];
  index: number;
  viewAllLink?: LinkField;
  viewAllLinkText?: Field<string>;
};

const NavPanel = ({ navPanelLinks, viewAllLink, viewAllLinkText }: NavPanelProps): JSX.Element => {
  return (
    <div className={cn(styles[`main`], 'container-12')}>
      <ul className={cn(styles[`nav-panel-links`], 'container-10')}>
        {navPanelLinks?.length &&
          navPanelLinks.map(({ fields, id }) => {
            return (
              <li className={styles[`nav-panel-link`]} key={`nav-panel-link-${id}`}>
                <UnderlineCta
                  underlineOnHover={true}
                  tag="a"
                  field={formatInternalLinkToRelativeLink(fields?.link)}
                >
                  <Text field={fields?.text} editable={false} />
                </UnderlineCta>
              </li>
            );
          })}
      </ul>

      <UnderlineCta centered={true} tag="a" field={formatInternalLinkToRelativeLink(viewAllLink)}>
        <Text field={viewAllLinkText} editable={false} />
      </UnderlineCta>
    </div>
  );
};

export default NavPanel;
