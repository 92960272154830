import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

// You can add more Page Templates to this type
type TemplateName =
  | 'Home'
  | 'Home TC'
  | 'People Landing'
  | 'People Landing TC'
  | 'Professional'
  | 'Person TC'
  | 'Staff'
  | 'Service Landing'
  | 'Service'
  | 'Service TC'
  | 'Office Landing'
  | 'Office Landing TC'
  | 'Office'
  | 'Office TC'
  | 'Insight Landing'
  | 'Insight Landing TC'
  | 'News Landing'
  | 'News Landing TC'
  | 'Event'
  | 'Event TC'
  | 'News'
  | 'News TC'
  | 'Publication'
  | 'Publication TC'
  | 'Search Landing'
  | 'Client Story';

const searchLandings: Array<TemplateName> = [
  'People Landing',
  'Insight Landing',
  'News Landing',
  'People Landing TC',
  'Insight Landing TC',
  'News Landing TC',
];

export const useTemplate = () => {
  const { sitecoreContext } = useSitecoreContext();

  const isTemplate = (templateName: TemplateName): boolean => {
    return sitecoreContext.route?.templateName === templateName;
  };

  const isPeopleDetail =
    isTemplate('Professional') || isTemplate('Person TC') || isTemplate('Staff');

  const isHomePage = isTemplate('Home') || isTemplate('Home TC');

  const isSearchLanding = searchLandings.some((template) => isTemplate(template));
  const isServiceDetail = isTemplate('Service') || isTemplate('Service TC');

  const isInsightLanding = isTemplate('Insight Landing') || isTemplate('Insight Landing TC');
  const isNewsLanding = isTemplate('News Landing') || isTemplate('News Landing TC');

  const isNewsDetail = isTemplate('News') || isTemplate('News TC');
  const isEventDetail = isTemplate('Event') || isTemplate('Event TC');
  const isPublicationDetail = isTemplate('Publication') || isTemplate('Publication TC');
  const isNewsPublicationEventDetail = isNewsDetail || isEventDetail || isPublicationDetail;

  const isClientStory = isTemplate('Client Story');

  const isOfficeDetail = isTemplate('Office') || isTemplate('Office TC');

  return {
    isTemplate,
    isPeopleDetail,
    isHomePage,
    isSearchLanding,
    isServiceDetail,
    isInsightLanding,
    isNewsLanding,
    isNewsDetail,
    isEventDetail,
    isOfficeDetail,
    isPublicationDetail,
    isNewsPublicationEventDetail,
    isClientStory,
  };
};
