export const getIframeAspectRatio = (iframe: HTMLIFrameElement): number => {
  const width = parseInt(iframe.width);
  const height = parseInt(iframe.height);

  const isValidNumber = (num: number): boolean => Boolean(num);

  if (!isValidNumber(width) || !isValidNumber(height)) {
    return 0;
  }

  const isWidthPercentage = iframe.width.endsWith('%');
  if (isWidthPercentage) {
    return 0;
  }

  return width / height;
};

export const resizeIframesFullWidth = (element: HTMLElement): void => {
  element.querySelectorAll('iframe').forEach((iframe) => {
    const aspectRatio = getIframeAspectRatio(iframe);
    iframe.style.width = '100%';

    if (aspectRatio !== 0) {
      iframe.style.aspectRatio = aspectRatio.toString();
      iframe.style.height = 'auto';
    }
  });
};
