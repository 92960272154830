import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { useCoveoBuilder } from 'src/hooks/useCoveoBuilder';
import { useEngineContext } from 'src/hooks/useCoveoEngine';

import { executeManualSearch, executeRedirectSearch } from 'src/utils/coveo';

import { SearchBox } from 'components/BaseHelpers/BaseSearch/SearchBox';

import styles from './SiteSearchBox.module.scss';

export type SiteSearchBoxProps = {
  siteSearchPlaceholderText?: Field<string>;
  results?: string[];
  searchInputId?: string;
  onSearchSubmit: () => void;
  searchOpen?: boolean;
  setSearchOpen?: (searchOpen: boolean) => void;
};

const SiteSearchBox = ({
  searchInputId,
  onSearchSubmit,
  searchOpen,
  setSearchOpen,
}: SiteSearchBoxProps): JSX.Element => {
  const { siteEngine } = useEngineContext();
  const router = useRouter();
  const [query, setQuery] = useState<string>('');
  const [isSearchLanding, setIsSearchLanding] = useState<boolean>(false);
  const [standaloneSearchBoxController, standaloneSearchBoxState] = useCoveoBuilder(
    'standalonesearchbox',
    siteEngine,
    {
      redirectionUrl: 'search',
      highlightOptions: {
        exactMatchDelimiters: {
          open: '<strong>',
          close: '</strong>',
        },
      },
    }
  );

  useEffect(() => {
    setIsSearchLanding(Boolean(router.asPath.match(/^\/search/)));
  }, [router.asPath]);

  const manualSearchAction = useCallback(
    (value?: string) => {
      executeManualSearch(siteEngine, value || standaloneSearchBoxState?.value);
    },
    [siteEngine, standaloneSearchBoxState?.value]
  );

  const redirectSearch = useCallback(
    (value: string) => {
      const { analytics } = standaloneSearchBoxState || {};
      executeRedirectSearch({ router, value, analytics }).then(() => {
        standaloneSearchBoxController?.afterRedirection();
      });
    },
    [router, standaloneSearchBoxController, standaloneSearchBoxState]
  );

  const submitSearch = useCallback(
    (value?: string) => {
      const input = value || query;
      if (!input) return;

      if (isSearchLanding) {
        manualSearchAction(input);
      } else {
        redirectSearch(input);
      }
      onSearchSubmit();
      setQuery('');
    },
    [query, isSearchLanding, onSearchSubmit, manualSearchAction, redirectSearch]
  );

  const selectQuerySuggestion = useCallback(
    (value: string) => {
      standaloneSearchBoxController?.selectSuggestion(value);
      submitSearch(value);
    },
    [standaloneSearchBoxController, submitSearch]
  );

  const updateQueryText = useCallback(
    (value: string) => {
      standaloneSearchBoxController?.updateText(value);
      setQuery(value);
    },
    [standaloneSearchBoxController]
  );

  if (!standaloneSearchBoxState) {
    return <></>;
  }

  return (
    <div className={cn(styles[`main`])}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SearchBox
          variant="header"
          id={searchInputId || ''}
          value={query}
          updateText={updateQueryText}
          submitSearch={submitSearch}
          selectSuggestion={selectQuerySuggestion}
          suggestions={standaloneSearchBoxState?.suggestions}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      </form>
    </div>
  );
};

export default SiteSearchBox;
