import Date from 'components/BaseHelpers/Date';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Text from 'components/BaseHelpers/Text';

import { PeopleRelatedContentResult } from './InsightsPeopleRelatedContent';

import styles from './InsightsPeopleRelatedContent.module.scss';

export const PeopleRelatedContentCard = (props: PeopleRelatedContentResult): JSX.Element => {
  const { title, displayDate, date, startDateandTime, publicationType, eventType, newsType } =
    props.fields || {};
  const insightType = publicationType || eventType || newsType;
  const year = date?.value.substring(0, 4);

  if (!insightType) return <></>;

  return (
    <LinkOrComponent className={styles['insight-result-item__link']} field={props.url}>
      <Text
        className={styles['insight-result-item__content-type']}
        field={insightType[0]?.fields?.name}
        editable={false}
      />
      <Text className={styles['insight-result-item__heading']} tag={'h4'} field={title} />
      {/* only display date if it has a value */}
      {((year !== '0001' && year !== undefined) || displayDate) && (
        <Date
          field={date || startDateandTime}
          displayDate={displayDate}
          className={styles['insight-result-item__date']}
        />
      )}
    </LinkOrComponent>
  );
};
