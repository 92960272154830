import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { TypeaheadItem, useSearch } from 'src/hooks/useSolrSearch';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import { SearchBox, SuggestionOrLink } from 'components/BaseHelpers/BaseSearch/SearchBox';

import styles from './ServicesSearchBar.module.scss';

type ServicesSearchBarProps = {
  fields: {
    searchPlaceholderText?: Field<string>;
    searchResultCount?: Field<number>;
  };
};

const ServicesSearchBar = ({ fields = {} }: ServicesSearchBarProps): JSX.Element => {
  const { searchPlaceholderText, searchResultCount } = fields;
  const [suggestions, setSuggestions] = useState<SuggestionOrLink[]>([]);

  const { updateParams, response, params } = useSearch({
    path: 'services/typeahead',
    pageSize: searchResultCount?.value,
  });

  const updateQueryText = useCallback((input: string) => {
    updateParams({ key: 'query', value: input });
  }, []);

  useEffect(() => {
    setSuggestions(
      response.data.map((result: TypeaheadItem) => {
        const { url, name, label } = result;

        const addLabel = label ? ' - ' + label : '';
        return {
          rawValue: name || '',
          highlightedValue:
            name
              ?.toLowerCase()
              .replace(params?.query.toLowerCase(), `<strong>${params?.query}</strong>`) +
              addLabel || '',
          url: url,
        };
      })
    );
  }, [response.data]);

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10')}>
      <SearchBox
        id={'services-search-bar'}
        value={params?.query}
        suggestions={suggestions}
        updateText={updateQueryText}
        placeholder={searchPlaceholderText}
      />
    </AnimateInContainer>
  );
};

export default ServicesSearchBar;
