import { TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  ForwardedRef,
  HTMLAttributes,
} from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import Link, { LinkProps } from '../Link';
import Text from '../Text';
import { useBaseCta } from './useBaseCta';

type AnchorProps = {
  tag: 'a';
  ref?: ForwardedRef<HTMLAnchorElement>;
} & LinkProps;

type ButtonProps = {
  tag: 'button';
  ref?: ForwardedRef<HTMLButtonElement>;
  onClick: MouseEventHandler;
  editable?: boolean;
} & (
  | {
      text: TextField | string;
      children?: ReactNode;
    }
  | {
      text?: TextField | string;
      children?: ReactNode;
    }
) &
  ButtonHTMLAttributes<HTMLButtonElement>;

type SpanProps = {
  tag: 'span';
  text?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

export type BaseCtaProps = AnchorProps | ButtonProps | SpanProps;

const BaseCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, BaseCtaProps>(
  function BaseCtaComponent(props: BaseCtaProps, ref): JSX.Element {
    const { shouldRenderCta } = useBaseCta();
    const { isEE } = useExpEditor();

    if (!shouldRenderCta(props)) {
      return <></>;
    }

    if (isEE) {
      const { children, className, tag, onClick, ...otherProps } = props;
      const text = 'text' in props ? props.text : undefined;
      const editable = 'editable' in props ? props.editable : undefined;
      return (
        <span className={className} {...otherProps}>
          {children ||
            (typeof text === 'string' ? text : <Text field={text} editable={editable} />)}
        </span>
      );
    }

    if (props.tag === 'a') {
      const { children, className, tag, ...otherProps } = props;

      return (
        <Link className={className} {...otherProps} ref={ref as ForwardedRef<HTMLAnchorElement>}>
          {children}
        </Link>
      );
    }

    if (props.tag === 'span') {
      const { children, className, text, ...otherProps } = props;
      if (!children && !text) return <></>;
      return (
        <span className={className} {...otherProps}>
          {children || text}
        </span>
      );
    }

    const { children, editable, className, text, onClick, tag, ...otherProps } = props;
    return (
      <button
        className={className}
        onClick={onClick}
        {...otherProps}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        {children || (typeof text === 'string' ? text : <Text field={text} editable={editable} />)}
      </button>
    );
  }
);

export default BaseCta;
