import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import styles from './InsightsAgenda.module.scss';

export type InsightsAgendaProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.Agenda;

const InsightsAgenda = (props: InsightsAgendaProps): JSX.Element => {
  const { fields } = props;
  const { hasPlaceholderItemsOrIsEE } = useExpEditor();
  const { t } = useDictionary();

  if (!hasPlaceholderItemsOrIsEE('agenda', props)) return <></>;

  return (
    <div className={cn(styles.main, 'spacer', 'container-8')}>
      <ModuleHeader
        className={styles['header']}
        header={fields?.moduleHeader}
        defaultHeader={{ value: t('Agenda') }}
      />
      <ul className={styles['agenda-list']}>
        <Placeholder
          name="agenda"
          rendering={props.rendering}
          renderEach={(component, index) => (
            <li key={index} className={styles['agenda-list-item']}>
              {component}
            </li>
          )}
        />
      </ul>
    </div>
  );
};

export default InsightsAgenda;
