import {
  FacetState,
  SearchEngine,
  Unsubscribe,
  buildDateFacet,
  buildFacet,
  DateFacetProps,
  FacetProps,
  DateFacetState,
  buildDateRange,
  FacetOptions,
} from '@coveo/headless';
import { useEffect, useMemo, useState } from 'react';

import { COVEO_FIELDS, FacetController, HIDDEN_FIELDS } from 'src/types/coveo';

import { CoveoFields } from './useCoveoFields';
import { useFacetLabels } from './useFacetLabels';
import { useSite } from './useSite';
import { useTemplate } from './useTemplate';

export interface UseCoveoFacetsArgs {
  engine: SearchEngine;
  options: FacetConfig;
}

export interface FacetConfig {
  /** the Coveo fields to pass to the builders. use `COVEO_FIELDS` from `types/coveo.ts` */
  facetFields: FacetFields;
  /** options overrides to be passed to the Coveo facet builders */
  config?: FacetOptionsNoField;
  /**
   * array of `name.value` from `topicsFilter` or `aiTags`.
   * if empty or `undefined`, Topics facet won't display
   */
  topicsFacetValues?: Array<string>;
  /**
   * for Site Search -- callback function that determines whether to hide/display certain facets.
   * receives `facet.field` from each facet and returns a `boolean` value for each.
   * use `useCallback` so it isn't changed on every render
   */
  displayCondition?: (field: string) => boolean;
  /**
   * values we want to exclude from appearing in the UI, but still have to send to `coveo`
   * example: `Topics` need to know all possible values, but we don't want to display the ones
   * that appear in `Trending Topics`
   *
   * key is the facet `field` string and the value is an array of `value` options to exclude
   */
  exclude?: Record<string, Array<string>>;
}

type HiddenFacetFields = (typeof HIDDEN_FIELDS)[keyof typeof HIDDEN_FIELDS];
export type FacetFields = Array<CoveoFields[keyof CoveoFields] | HiddenFacetFields>;

export interface CoveoFacet extends FacetData {
  controller: FacetController;
  state: FacetState | DateFacetState | undefined;
}

export interface FacetData {
  label: string;
  field: string;
  config?: FacetOptionsNoField;
  display?: boolean;
  exclude?: Array<string>;
  hideValue?: (value: string) => boolean;
  topics?: Array<string>;
}

export type FacetOptionsNoField = Omit<FacetOptions, 'field' | 'facetId'>;

export const useCoveoFacets = (engine: SearchEngine, options: FacetConfig): Array<CoveoFacet> => {
  const { facetFields, config, topicsFacetValues = [], displayCondition, exclude } = options;
  const facetLabels = useFacetLabels();
  const { isNewsLanding } = useTemplate();
  const { isTCSite } = useSite();
  const [facetStates, setFacetStates] = useState<
    Record<string, FacetState | DateFacetState | undefined>
  >({});

  const facets = useMemo(() => {
    let fields = [...facetFields];
    const excludeFacets: FacetFields = [];
    if (!topicsFacetValues || !topicsFacetValues.length) {
      excludeFacets.push(COVEO_FIELDS.topics);
    }
    if (isTCSite) excludeFacets.push(COVEO_FIELDS.regions);
    if (excludeFacets.length) {
      fields = fields.filter((field) => !excludeFacets.includes(field));
    }
    return fields.map((field) => {
      const facet: FacetData = {
        label: facetLabels[field as keyof typeof facetLabels],
        field: field,
        config,
      };
      if (field === COVEO_FIELDS.topics && topicsFacetValues.length) {
        facet.topics = topicsFacetValues;
      }
      if (displayCondition) {
        facet.display = displayCondition(field);
      }
      if (exclude && exclude[field]) {
        facet.exclude = exclude[field].map((value) => value.toLowerCase());
        facet.hideValue = (value: string) =>
          facet.exclude !== undefined && facet.exclude.includes(value);
      }
      return facet;
    });
  }, [config, displayCondition, exclude, facetFields, facetLabels, isTCSite, topicsFacetValues]);

  const facetControllers = useMemo(() => {
    return facets.reduce(
      (acc, curr) => {
        const builder = curr.field === COVEO_FIELDS.dateRange ? 'datefacet' : 'facet';
        const options = getOptions(builder, curr, isNewsLanding);
        const controller = getFacet(engine, builder, options);
        return { ...acc, [curr.field]: controller };
      },
      {} as Record<string, FacetController>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsub: Array<Unsubscribe> = [];
    facets.forEach((facet) => {
      const controller = facetControllers[facet.field];
      unsub.push(
        controller.subscribe(() =>
          setFacetStates((curr) => {
            const next = { ...curr, [facet.field]: controller.state };
            return next;
          })
        )
      );
    });

    return () => {
      if (unsub.length) {
        unsub.forEach((callback) => callback());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const facetList = useMemo(() => {
    return facets.map((facet) => {
      const controller = facetControllers[facet.field];
      const state = facetStates[facet.field];
      return {
        ...facet,
        controller,
        state,
      };
    });
  }, [facets, facetControllers, facetStates]);

  return facetList;
};

const getFacet = (
  engine: SearchEngine,
  builder: 'facet' | 'datefacet',
  options: FacetProps['options'] | DateFacetProps['options']
) => {
  switch (builder) {
    case 'facet':
      return buildFacet(engine, { options } as FacetProps);
    case 'datefacet':
      return buildDateFacet(engine, { options } as DateFacetProps);
  }
};

const dateFacetValues = [
  buildDateRange({
    start: { period: 'now' },
    end: { period: 'next', unit: 'year', amount: 1 },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'week', amount: 1 },
    end: { period: 'now' },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'month', amount: 1 },
    end: { period: 'now' },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'month', amount: 3 },
    end: { period: 'past', unit: 'month', amount: 1 },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'month', amount: 6 },
    end: { period: 'past', unit: 'month', amount: 3 },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'year', amount: 1 },
    end: { period: 'past', unit: 'month', amount: 6 },
  }),
  buildDateRange({
    start: { period: 'past', unit: 'year', amount: 200 },
    end: { period: 'past', unit: 'year', amount: 1 },
  }),
];

const getOptions = (
  builder: 'facet' | 'datefacet',
  facet: FacetData,
  isNewsLanding: boolean
): FacetProps['options'] | DateFacetProps['options'] => {
  const facetConfig = {
    field: facet.field,
    facetId: facet.field,
    ...(facet?.config || {}),
  };
  return builder === 'datefacet'
    ? {
        generateAutomaticRanges: false,
        sortCriteria: 'descending',
        currentValues: isNewsLanding ? dateFacetValues.slice(1) : dateFacetValues,
        ...facetConfig,
      }
    : { sortCriteria: 'alphanumeric', numberOfValues: 1000, ...facetConfig };
};
