import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { useGlobalStore } from 'src/hooks/useGlobalStore';
import { useMetaData } from 'src/hooks/useMetaData';

import { formatPdfOptionsParam } from 'src/utils/pdfOptionsParam';

export const useGetUrls = () => {
  const { metaData } = useMetaData();
  const { sitecoreContext } = useSitecoreContext();
  const { language, site } = sitecoreContext;
  const { countryCode } = useGlobalStore();

  const getPdfUrl = (type?: 'full' | 'abbr') => {
    if (!metaData.canonicalUrl) {
      return '';
    }

    const url = new URL(metaData.canonicalUrl);
    const filename = url.pathname.split('/').filter(Boolean).pop() || 'professional';
    url.pathname = url.pathname.replace(new RegExp(`/${language}/`, 'i'), `/${language}/pdf/`);

    const pdfOptions = formatPdfOptionsParam(countryCode, type === 'full');
    if (pdfOptions.value) {
      url.searchParams.set(pdfOptions.key, pdfOptions.value);
    }
    const pdfPageUrl = url.toString();

    const pdfServerUrl = process.env.NEXT_PUBLIC_PDF_PRINT_SERVER_URL?.endsWith('/')
      ? process.env.NEXT_PUBLIC_PDF_PRINT_SERVER_URL
      : process.env.NEXT_PUBLIC_PDF_PRINT_SERVER_URL + '/';

    return `${pdfServerUrl}${filename}.pdf/?url=${pdfPageUrl}`;
  };

  const getVcardUrl = (itemId = '') => {
    const url = new URL(metaData.canonicalUrl || '');
    const slug = url.pathname.split('/').filter(Boolean).pop();
    return `/api/vcard?id=${itemId}&slug=${slug}&site=${site?.name}`;
  };

  return {
    getPdfUrl,
    getVcardUrl,
  };
};
