import {
  SearchEngine,
  SortOrder,
  StandaloneSearchBoxAnalytics,
  buildFieldSortCriterion,
  buildRelevanceSortCriterion,
  loadBreadcrumbActions,
  loadDateFacetSetActions,
  loadFacetSetActions,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  loadSortCriteriaActions,
} from '@coveo/headless';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import camelCase from 'lodash/camelCase';
import lowerCase from 'lodash/lowerCase';
import { NextRouter } from 'next/router';

import { COVEO_FIELDS, HIDDEN_FIELDS } from 'src/types/coveo';

export const encodeFieldNames = (fields: Array<string>) => {
  return fields.map((field) => encodeFieldName(field));
};

export const decodeFieldNames = (fields: Array<string>) => {
  return fields.map((field) => decodeFieldName(field));
};

export const decodeResultFields = <T>(result: Record<string, unknown>): T => {
  return Object.keys(result).reduce((acc, curr) => {
    const newKey = decodeFieldName(curr);
    return {
      ...acc,
      [newKey]: result[curr],
    };
  }, {} as T);
};

export const encodeFieldName = (field: string) => {
  if (field.includes('_')) return field.replace('_', 'z95x');
  return lowerCase(field).replace(' ', 'z32x');
};

export const decodeFieldName = (field: string) => {
  return camelCase(field.replace('z32x', ' ').replace('z95x', '_'));
};

export const relativeURL = (url: string | undefined) => {
  if (!url) return;
  if (url.startsWith('/')) return url;
  try {
    return new URL(url).pathname;
  } catch (e) {
    return;
  }
};

export const setShouldScrollToResults = () => {
  localStorage.setItem('search-scroll-override', 'true');
};

export const getShouldScrollToResults = () => {
  return localStorage.getItem('search-scroll-override');
};

export const removeShouldScrollToResults = () => {
  localStorage.removeItem('search-scroll-override');
};

export type ParsedLocalStorageData = {
  value: string;
  analytics: StandaloneSearchBoxAnalytics;
};

export const getSearchBoxData = () => {
  const data = localStorage.getItem('search-box-data');
  localStorage.removeItem('search-box-data');
  if (!data) return;
  const parsed: ParsedLocalStorageData = JSON.parse(data);
  return parsed;
};

// ESCAPE HATCHES TO EXECUTE SEARCH QUERIES OUTSIDE OF `BaseSearch` COMPONENT
export const executeManualSearch = (engine: SearchEngine, value: string | undefined) => {
  if (!value) return;
  setShouldScrollToResults();
  const { executeSearch } = loadSearchActions(engine);
  const { updateQuery } = loadQueryActions(engine);
  const { deselectAllBreadcrumbs } = loadBreadcrumbActions(engine);
  const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
  engine.dispatch(deselectAllBreadcrumbs());
  engine.dispatch(updateQuery({ q: value }));
  engine.dispatch(executeSearch(logSearchboxSubmit()));
};

export const executeRedirectSearch = async ({
  router,
  value,
  analytics,
}: {
  router: NextRouter;
  value: string;
  analytics: StandaloneSearchBoxAnalytics | undefined;
}) => {
  setShouldScrollToResults();
  const data = JSON.stringify({ value, analytics });
  localStorage.setItem('search-box-data', data);
  return router.push({ pathname: `search`, query: `q=${value}` });
};

export const executeClearSearch = (engine: SearchEngine) => {
  const { executeSearch } = loadSearchActions(engine);
  const { updateQuery } = loadQueryActions(engine);
  const { deselectAllBreadcrumbs } = loadBreadcrumbActions(engine);
  const { updateSortCriterion } = loadSortCriteriaActions(engine);
  const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
  engine.dispatch(deselectAllBreadcrumbs());
  engine.dispatch(updateSortCriterion(buildRelevanceSortCriterion()));
  engine.dispatch(updateQuery({ q: '' }));
  engine.dispatch(executeSearch(logSearchboxSubmit()));
};

const executeManualFacetSelect = (
  engine: SearchEngine,
  facet: string,
  value: string | undefined
) => {
  if (!value) return;
  setShouldScrollToResults();
  const { executeSearch } = loadSearchActions(engine);
  const { logFacetSelect } = loadSearchAnalyticsActions(engine);
  const { updateQuery } = loadQueryActions(engine);
  const { deselectAllBreadcrumbs } = loadBreadcrumbActions(engine);
  const { toggleSelectFacetValue } = loadFacetSetActions(engine);
  const { updateSortCriterion } = loadSortCriteriaActions(engine);
  engine.dispatch(updateQuery({ q: '' }));
  engine.dispatch(deselectAllBreadcrumbs());
  engine.dispatch(
    updateSortCriterion(buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Descending))
  );
  engine.dispatch(
    toggleSelectFacetValue({
      facetId: facet,
      selection: {
        value,
        numberOfResults: 0,
        state: 'selected',
      },
    })
  );
  engine.dispatch(executeSearch(logFacetSelect({ facetId: facet, facetValue: value })));
};

export const executeManualTemplateSelect = (engine: SearchEngine, value: string | undefined) => {
  executeManualFacetSelect(engine, HIDDEN_FIELDS.templateName, value);
};

export const parseAndExecuteSearch = (queryParams: string | undefined, engine: SearchEngine) => {
  if (!queryParams) return;
  setShouldScrollToResults();
  const { executeSearch } = loadSearchActions(engine);
  const { logSearchFromLink } = loadSearchAnalyticsActions(engine);
  const { updateQuery } = loadQueryActions(engine);
  const { toggleSelectFacetValue } = loadFacetSetActions(engine);
  const { toggleSelectDateFacetValue } = loadDateFacetSetActions(engine);
  const { updateSortCriterion } = loadSortCriteriaActions(engine);
  const { deselectAllBreadcrumbs } = loadBreadcrumbActions(engine);

  const params = queryParams.split('&');
  const query = params
    .find((param) => param.startsWith('q='))
    ?.split('=')
    .pop();
  const facets = params.filter((param) => param.startsWith('f-'));
  const dateFacet = params.find((param) => param.startsWith('df-'));
  const sort = params.find((param) => param.startsWith('sortCriteria'));

  engine.dispatch(deselectAllBreadcrumbs());
  engine.dispatch(updateQuery({ q: query || '' }));

  if (facets) {
    facets.forEach((facet) => {
      const [id, value] = facet.split('=');
      if (value) {
        const facetId = id.split('-').pop() || '';
        const parsedValue = decodeURIComponent(value);
        engine.dispatch(
          toggleSelectFacetValue({
            facetId,
            selection: {
              value: parsedValue,
              numberOfResults: 0,
              state: 'selected',
            },
          })
        );
      }
    });
  }

  if (dateFacet) {
    const [id, value] = dateFacet.split('=');
    const [start, end] = value.split('..');

    if (start && end) {
      const facetId = id.split('-').pop() || '';
      engine.dispatch(
        toggleSelectDateFacetValue({
          facetId,
          selection: {
            start,
            end,
            endInclusive: false,
            numberOfResults: 0,
            state: 'selected',
          },
        })
      );
    }
  }

  if (sort) {
    const sortOrder = sort.toLowerCase().includes('ascending')
      ? SortOrder.Ascending
      : SortOrder.Descending;
    engine.dispatch(
      updateSortCriterion(buildFieldSortCriterion(COVEO_FIELDS.dateRange, sortOrder))
    );
  }
  engine.dispatch(executeSearch(logSearchFromLink()));
};

export const handleManualViewAll = (
  linkObject: LinkField | { href: string; querystring: string } | undefined,
  engine: SearchEngine
) => {
  if (!linkObject) return;
  const linkValue = 'value' in linkObject ? linkObject.value : linkObject;
  const { href, querystring } = linkValue;
  const query = querystring || href?.split('?')?.pop();
  if (!query) return;
  parseAndExecuteSearch(query, engine);
};
