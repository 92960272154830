import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseTiles from 'components/BaseHelpers/BaseTiles';

export type BasicContentHorizontalTilesProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Tiles;

const BasicContentHorizontalTiles = (props: BasicContentHorizontalTilesProps): JSX.Element => {
  return <BaseTiles {...props} orientation={'horizontal'} />;
};

export default BasicContentHorizontalTiles;
