import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import NoImageHero from 'components/BaseHelpers/NoImageHero';

import styles from './BasicContentErrorPageHeader.module.scss';

export type BasicContentErrorPageHeaderProps = ComponentProps &
  Website.Feature.BasicContent._ErrorPage;

const BasicContentErrorPageHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const fields = getRouteFields<BasicContentErrorPageHeaderProps['fields']>(sitecoreContext);

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer')}>
      <NoImageHero className={styles.hero} headline={fields?.header} body={fields?.description} />
    </AnimateInContainer>
  );
};

export default BasicContentErrorPageHeader;
