import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseFlexibleFeatureItem from 'components/BaseHelpers/BaseFlexibleFeatures/BaseFlexibleFeatureItem';

type FlexibleFeatureItem = Website.Project.Main.ComponentTypes.Insights.FlexibleFeatureItem & {
  fields: {
    source?: {
      url?: string;
      headline?: string;
      bodyText?: string;
      image?: ImageFieldValue;
    };
  };
};

export type InsightsFlexibleFeatureItemProps = ComponentProps &
  FlexibleFeatureItem & { index: number; readyForAnimation: boolean };

const InsightsFlexibleFeatureItem = (props: InsightsFlexibleFeatureItemProps): JSX.Element => {
  return <BaseFlexibleFeatureItem {...props} />;
};

export default InsightsFlexibleFeatureItem;
