import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentWithHasContentProps } from 'lib/component-props/has-content';
import { Website } from 'lib/component-props/model';

import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import styles from './BasicContentAttachments.module.scss';

export type BasicContentAttachmentsProps = ComponentWithHasContentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Attachments;

const BasicContentAttachments = (props: BasicContentAttachmentsProps): JSX.Element => {
  const { fields, rendering } = props;
  const { isEE } = useExpEditor();
  const { t } = useDictionary();

  if (!isEE && !rendering.hasContent) return <></>;

  return (
    <div className={cn(styles.main, 'spacer', 'container-8')}>
      <ModuleHeader
        header={fields?.moduleHeader}
        defaultHeader={{ value: t('Resource Downloads') }}
      />
      <ul className={styles['attachments-list']}>
        <Placeholder name="attachments" rendering={props.rendering} />
      </ul>
    </div>
  );
};

export default BasicContentAttachments;
