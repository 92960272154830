import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentCTABanner.module.scss';

export type BasicContentCTABannerProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.CTABanner;

const BasicContentCTABanner = (props: BasicContentCTABannerProps): JSX.Element => {
  const { buttonLabel, headline, image, link, shortDescription } = props.fields || {};
  const { hasValueOrIsEE } = useExpEditor();
  const { t } = useDictionary();
  const ctaButtonLabel = buttonLabel?.value || link?.value?.text || t('Learn More');

  if (!hasValueOrIsEE(headline) || !hasValueOrIsEE(link) || !hasValueOrIsEE(image)) {
    return <></>;
  }

  return (
    <>
      <StopIndex />
      <AnimateInContainer className={cn(styles.main, 'container-12', 'spacer')}>
        <div className={styles['banner']}>
          <div className={'exp-editor-img-container'}>
            <Image
              field={image}
              fill={true}
              className={styles['image']}
              role="presentation"
              aria-hidden={true}
            />
          </div>
          <div className={styles['flex-container']}>
            <Text className={styles['headline']} tag={'h2'} field={headline} />
            <RichText className={styles['description']} field={shortDescription} />
            <BoxCta tag={'a'} field={link}>
              {ctaButtonLabel}
            </BoxCta>
          </div>
        </div>
      </AnimateInContainer>
      <StartIndex />
    </>
  );
};

export default BasicContentCTABanner;
