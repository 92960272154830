import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useTemplate } from 'src/hooks/useTemplate';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import RichText from 'components/BaseHelpers/RichText';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import Quote from '../../../assets/icons/quote.svg';

import styles from './BasicContentPullQuote.module.scss';

export type BasicContentPullQuoteProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.PullQuote;

const BasicContentPullQuote = ({ fields = {} }: BasicContentPullQuoteProps): JSX.Element => {
  const { hasValueOrIsEE } = useExpEditor();
  const { isPeopleDetail } = useTemplate();
  const { quoteAttribution, quoteText, background, crosslink } = fields;

  if (!hasValueOrIsEE(quoteText)) {
    return <></>;
  }
  return (
    <AnimateInContainer
      className={cn(
        styles['main'],
        { [styles['main--small']]: isPeopleDetail },
        styles[`${background?.fields?.name?.value}`],
        'container-10',
        'spacer'
      )}
    >
      <div className={styles[`quotation-mark`]}>
        <Quote width="32" height="32"></Quote>
      </div>
      <RichText className={styles[`quote-text`]} field={quoteText} />
      {hasValue(crosslink) && hasValue(quoteAttribution) ? (
        <UnderlineCta className={styles['cta']} underlineOnHover={true} tag="a" field={crosslink}>
          <RichText
            className={styles[`quote-attribution`]}
            field={quoteAttribution}
            styled={false}
          />
        </UnderlineCta>
      ) : (
        <RichText className={styles[`quote-attribution`]} field={quoteAttribution} styled={false} />
      )}
    </AnimateInContainer>
  );
};

export default BasicContentPullQuote;
