import { useEffect, useRef } from 'react';

export const useSetTop = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setTop = () => {
      if (containerRef.current && stickyRef.current) {
        const containerDistanceFromTop =
          window.pageYOffset + containerRef.current.getBoundingClientRect().top;

        const pxToRem = (px: number): string => `${(px / 16).toFixed(3)}rem`;

        stickyRef.current.style.setProperty(
          '--PeopleDetailHeroLeft__top',
          `${pxToRem(containerDistanceFromTop)}`
        );
      }
    };
    setTop();
    window.addEventListener('resize', setTop);
    return () => window.removeEventListener('resize', setTop);
  }, []);

  return {
    containerRef,
    stickyRef,
  };
};
