import {
  SearchEngine,
  SearchEngineOptions,
  buildSearchEngine,
  getOrganizationEndpoints,
} from '@coveo/headless';
import { createContext, useContext, useMemo } from 'react';

type SearchHub =
  | 'PeopleLanding'
  | 'SiteSearch'
  | 'InsightsLanding'
  | 'NewsLanding'
  | 'ResourceCenter'
  | 'CareersDetail';

const organizationId = process.env.NEXT_PUBLIC_COVEO_ORG_ID as string;
const accessToken = process.env.NEXT_PUBLIC_COVEO_ACCESS_TOKEN as string;

const DEFAULT_CONFIGURATION: SearchEngineOptions['configuration'] = {
  organizationId,
  organizationEndpoints: getOrganizationEndpoints(organizationId),
  accessToken,
};

/** creates a unique `coveo` `SearchEngine`
 *
 * do not use for landing page search forms
 *
 * only use for cases where a component can have multiple instances on one page (ie, `InsightsTopicsContent`)
 *
 */
export const useCoveoEngine = (searchHub?: SearchHub) => {
  const engine = useMemo(() => {
    const configuration = { ...DEFAULT_CONFIGURATION };
    if (searchHub) configuration.search = { searchHub };
    return buildSearchEngine({ configuration });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return engine;
};

type Engines =
  | 'siteEngine'
  | 'peopleEngine'
  | 'insightsEngine'
  | 'newsEngine'
  | 'resourceEngine'
  | 'careersEngine';
type EngineContextValue = Record<Engines, SearchEngine>;

export const EngineContext = createContext<EngineContextValue | undefined>(undefined);

export const EngineProvider = ({ children }: { children: React.ReactNode }) => {
  const siteEngine = useCoveoEngine('SiteSearch');
  const peopleEngine = useCoveoEngine('PeopleLanding');
  const insightsEngine = useCoveoEngine('InsightsLanding');
  const newsEngine = useCoveoEngine('NewsLanding');
  const resourceEngine = useCoveoEngine('ResourceCenter');
  const careersEngine = useCoveoEngine('CareersDetail');
  return (
    <EngineContext.Provider
      value={{
        siteEngine,
        peopleEngine,
        insightsEngine,
        newsEngine,
        resourceEngine,
        careersEngine,
      }}
    >
      {children}
    </EngineContext.Provider>
  );
};

export const useEngineContext = () => {
  const context = useContext<EngineContextValue | undefined>(EngineContext);

  if (context === undefined) {
    throw new Error('useEngineContext must be used within a ContextProvider');
  }

  return context;
};
