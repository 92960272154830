import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useRef } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { resizeIframesFullWidth } from 'src/utils/resizeIframeFullWidth';

import Text from 'components/BaseHelpers/Text';

import styles from './FullWidthEmbed.module.scss';

export type FullWidthEmbedProps = {
  embedCode?: Field<string>;
};

const FullWidthEmbed = ({ embedCode }: FullWidthEmbedProps): JSX.Element => {
  const { isEE } = useExpEditor();
  const embedContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (embedContainer.current) {
      resizeIframesFullWidth(embedContainer.current);
    }
  }, []);
  return (
    <div className={styles.main}>
      <div
        className={styles.embed}
        dangerouslySetInnerHTML={{ __html: embedCode?.value || '' }}
        ref={embedContainer}
      />
      {isEE && <Text className={styles['exp-editor']} field={embedCode} encode={true} />}
    </div>
  );
};

export default FullWidthEmbed;
