import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

export const formatInternalLinkToRelativeLink = (link: LinkField | undefined, isEE = false) => {
  if (isEE || link?.value.linktype !== 'internal') {
    return link;
  }
  try {
    const origin = new URL(link.value.href || '').origin;
    const updatedLink: LinkField = structuredClone(link);
    if (updatedLink.value.href) {
      updatedLink.value.href = updatedLink.value.href.replace(origin, '');
      return updatedLink;
    } else {
      return link;
    }
  } catch {
    return link;
  }
};
