import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusTrap from 'focus-trap-react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDictionary } from 'src/hooks/useDictionary';

import { resizeIframesFullWidth, getIframeAspectRatio } from 'src/utils/resizeIframeFullWidth';

import CloseIcon from '../../../assets/icons/close-icon.svg';

import styles from './VideoModal.module.scss';

export type VideoModalProps = {
  video: Field<string> | undefined;
};

type OpenModalArgs = {
  launchElement: HTMLAnchorElement | HTMLButtonElement | null;
};

export type VideoModalRef = {
  openModal: (options: OpenModalArgs) => void;
};

const VideoModal = forwardRef<VideoModalRef, VideoModalProps>(function VideoModalComponent(
  { video },
  ref
) {
  const { t } = useDictionary();
  const [isBrowser, setIsBrowser] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const launchElementRef = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null);

  const openModal = ({ launchElement }: OpenModalArgs) => {
    setIsOpen(true);
    // save the element that launched the modal so we can return focus when it's closed
    launchElementRef.current = launchElement;
  };

  const closeModal = () => {
    if (!modalRef.current) return;
    containerRef.current?.classList.add(styles['fade-out']);
    enableBodyScroll(modalRef.current);

    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const setIframeAspectRatio = (iframe: HTMLIFrameElement | null) => {
    if (iframe && embedContainerRef.current) {
      const aspectRatio = getIframeAspectRatio(iframe);
      embedContainerRef.current.style.aspectRatio = aspectRatio.toString();
    }
  };

  useClickOutside({
    refs: [closeButtonRef, embedContainerRef],
    callback: closeModal,
    resize: false,
  });

  // expose `openModal` on component instance
  // so we can add it to any `onClick` handlers
  // that need to control the modal
  useImperativeHandle(ref, () => ({
    openModal,
  }));

  useEffect(() => {
    // can't access `document` server-side
    // so we set a flag here because `useEffect`
    // only runs on the client
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (!isOpen || !embedContainerRef.current || !modalRef.current || !closeButtonRef.current)
      return;

    resizeIframesFullWidth(embedContainerRef.current);
    setIframeAspectRatio(embedContainerRef.current.querySelector('iframe'));
    disableBodyScroll(modalRef.current, {
      allowTouchMove: (_) => true,
    });

    const setCloseButtonPosition = () => {
      const closeButton = closeButtonRef.current;
      const modal = modalRef.current;
      if (!closeButton || !modal) return;
      const top = closeButton.offsetTop;
      const left = closeButton.offsetLeft;
      modal.style.setProperty('--CloseButton_top', `${top}px`);
      modal.style.setProperty('--CloseButton_left', `${left}px`);
    };
    setCloseButtonPosition();
    window.addEventListener('resize', setCloseButtonPosition);

    return () => {
      window.removeEventListener('resize', setCloseButtonPosition);
    };
  }, [isOpen]);

  useEffect(() => {
    const closeButton = closeButtonRef.current;
    const modal = modalRef.current;
    if (!isOpen || !modal || !closeButton) return;

    closeButton.focus();

    return () => {
      // return focus to the element that launched the modal
      launchElementRef.current?.focus();
    };
  }, [isOpen]);

  if (!video) return <></>;

  const modal = isOpen ? (
    <div className={styles['main']} ref={containerRef}>
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <div className={styles['content-container']} aria-modal="true" role="dialog" ref={modalRef}>
          <button
            className={styles['close-button']}
            aria-label={t('Close video modal')}
            onClick={closeModal}
            ref={closeButtonRef}
          >
            <CloseIcon className={styles['close-button-icon']} />
          </button>
          <div
            className={styles['iframe-container']}
            ref={embedContainerRef}
            dangerouslySetInnerHTML={{ __html: video.value }}
          />
          {/* 
          we need this extra close button because `focus-trap` only works with iframes when there are non-iframe, tabbable elements before _and_ after the iframe

          we use `position: absolute` to place it on top of the other button so the layout is correct.
          the side effect is that a user will have to tab an extra time
        */}
          <button className={styles['hidden-button']} onClick={closeModal}>
            {t('Close modal')}
          </button>
        </div>
      </FocusTrap>
    </div>
  ) : null;

  return isBrowser ? createPortal(modal, document.body) : null;
});

export default VideoModal;
