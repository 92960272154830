import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';
import { fieldOrSourceField, imageOrSourceImage } from 'src/utils/sitecoreFieldHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';

import { BaseLinkCard } from '../BaseLinkCard/BaseLinkCard';

import styles from './BaseFlexibleFeatureItem.module.scss';

export type FlexibleFeatureItem = Website.Project.Main.ComponentTypes.Insights.FlexibleFeatureItem;

export type FlexibleFeatureItemComponentProps = Partial<ComponentProps> &
  Omit<FlexibleFeatureItem, 'fields'> & {
    fields?: Omit<NonNullable<FlexibleFeatureItem['fields']>, 'source'> & {
      source?: {
        url?: string;
        headline?: string;
        bodyText?: string;
        image?: ImageFieldValue;
        date?: string;
        displayDate?: string;
      };
    };
  };

export type BaseFlexibleFeatureItemProps = FlexibleFeatureItemComponentProps & {
  index: number;
  readyForAnimation: boolean;
};

const BaseFlexibleFeatureItem = ({
  fields = {},
  index,
  readyForAnimation,
}: BaseFlexibleFeatureItemProps): JSX.Element => {
  const { isEE } = useExpEditor();
  const { source } = fields || {};

  const image = imageOrSourceImage(fields, source, isEE);
  const headline = fieldOrSourceField(fields, source, 'headline', isEE);
  const bodyText = fieldOrSourceField(fields, source, 'bodyText', isEE);
  const url = hasValue(fields?.url) ? fields?.url : source?.url;
  const date = fieldOrSourceField(fields, source, 'date', isEE);
  const displayDate = fieldOrSourceField(fields, source, 'displayDate', isEE);
  const showDate = Boolean(date || displayDate);
  const inViewClass = index % 2 === 1 ? 'slide-in-from-left' : 'slide-in-from-right';

  return (
    <div className={cn(styles.main)}>
      <AnimateInContainer
        className={cn(styles[`animate-container`])}
        inViewClass={inViewClass}
        startCondition={readyForAnimation}
      >
        <BaseLinkCard image={image} url={url}>
          {showDate && (
            <BaseLinkCard.Date field={date} displayDate={displayDate} className={styles['date']} />
          )}
          <BaseLinkCard.Title field={headline} underline={true} />
          <BaseLinkCard.Description field={bodyText} />
        </BaseLinkCard>
      </AnimateInContainer>
    </div>
  );
};

export default BaseFlexibleFeatureItem;
