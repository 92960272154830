import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { getPlaceholder } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import { PlaceholderList } from 'components/BaseHelpers/PlaceholderList/PlaceholderList';
import { richTextStyles } from 'components/BaseHelpers/RichText';

import { PeopleEducationItemProps } from '../PeopleEducationItem';

import styles from './PeopleEducation.module.scss';

export type PeopleEducationProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Education;

const PeopleEducation = ({ fields = {}, rendering }: PeopleEducationProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { isEE } = useExpEditor();
  const placeholderName = 'education';
  const hasItemsToRender = getPlaceholder<PeopleEducationItemProps>(
    placeholderName,
    rendering
  )?.some((item) => shouldRender(item.fields));

  if (!isEE && !hasItemsToRender) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={moduleHeader} />
      <div className={richTextStyles.main}>
        <Placeholder
          name={placeholderName}
          rendering={rendering}
          render={(components, data) => {
            return (
              <PlaceholderList components={components} data={data} shouldRender={shouldRender} />
            );
          }}
        />
      </div>
    </AnimateInContainer>
  );
};

const shouldRender = (fields: PeopleEducationItemProps['fields']) => {
  const { school, degree, concentration } = fields || {};
  const hasContent = [
    school?.fields?.name?.value,
    degree?.fields?.name?.value,
    concentration?.value,
  ].some(Boolean);
  return hasContent;
};

export default PeopleEducation;
