import { forwardRef } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { Website } from 'lib/component-props/model';

import ServiceList from 'components/Services/ServiceList';

import styles from './ServicesRelatedList.module.scss';

export type ServicesRelatedListProps = {
  items: Website.Feature.Services._Service[];
  serviceType: string;
  showMore: boolean;
  id: string;
};

const ServicesRelatedList = forwardRef<HTMLDivElement, ServicesRelatedListProps>(
  function ServicesRelatedList(props, ref) {
    const { items, serviceType, showMore, id } = props;
    const { hasItemsOrIsEE, isEE, noItemsAndIsEE } = useExpEditor();
    const { t } = useDictionary();

    if (!hasItemsOrIsEE(items)) {
      return <></>;
    }

    const numOfItemsBeforeLoadMore = 6;
    const firstList = isEE ? items : items.slice(0, numOfItemsBeforeLoadMore);
    const secondList = isEE ? [] : items.slice(numOfItemsBeforeLoadMore);

    return (
      <div className={styles.main}>
        <h3 className={styles.heading}>{t(serviceType)}</h3>
        {noItemsAndIsEE(items) && (
          <span>{`${serviceType}: [No data entered]. Click here to add.`}</span>
        )}
        <div>
          <ServiceList list={firstList} />

          {Boolean(secondList.length) && (
            <div
              className={styles.wrapper}
              id={id}
              role="region"
              aria-hidden={showMore ? 'false' : 'true'}
              tabIndex={showMore ? -1 : undefined}
              ref={ref}
            >
              <div className={styles.content}>
                <ServiceList list={secondList} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ServicesRelatedList;
