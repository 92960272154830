import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { RefObject, useEffect, useRef } from 'react';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDictionary } from 'src/hooks/useDictionary';

import { getEnglishLangName } from 'src/utils/getEnglishLangName';
import { hasItems } from 'src/utils/hasItems';

import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Text from 'components/BaseHelpers/Text';

import SmallArrow from '../../../../../assets/icons/small-arrow.svg';

import styles from './LocaleDropdown.module.scss';

export type Locale = {
  name: string;
  nativeName: string;
};

type LocaleToggleProps = {
  localeDropdownText?: Field<string>;
  localesOpen: boolean;
  setLocalesOpen: (localesOpen: boolean) => void;
  toggleRef: RefObject<HTMLButtonElement>;
};

export type LocaleDropdownProps = {
  locales?: Locale[];
  localesOpen: boolean;
  setLocalesOpen: (localesOpen: boolean) => void;
  toggleRef: RefObject<HTMLButtonElement>;
};

export const ToggleLocales = ({
  localeDropdownText,
  localesOpen,
  setLocalesOpen,
  toggleRef,
}: LocaleToggleProps) => {
  const { t } = useDictionary();
  return (
    <button
      ref={toggleRef}
      onClick={() => {
        setLocalesOpen(!localesOpen);
      }}
      aria-label={t('Languages')}
      aria-controls={`locale-dropdown`}
      aria-expanded={localesOpen}
      className={styles['toggle-locales']}
    >
      <Text className={styles['locale-text']} field={localeDropdownText} editable={false}></Text>
      <div className={cn(styles['arrow'], { [styles['arrow--open']]: localesOpen })}>
        <SmallArrow />
      </div>
    </button>
  );
};

const LocaleDropdown = ({
  locales,
  localesOpen,
  setLocalesOpen,
  toggleRef,
}: LocaleDropdownProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();

  const localeRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    refs: [localeRef, toggleRef],
    callback: () => {
      setLocalesOpen(false);
    },
  });

  useEffect(() => {
    setLocalesOpen(false);
  }, [sitecoreContext?.language]);

  if (!hasItems(locales) || !toggleRef?.current?.className) {
    return <></>;
  }

  return (
    <div className={styles[`wrapper`]}>
      <FocusTrap
        active={localesOpen}
        focusTrapOptions={{
          allowOutsideClick: true,
          fallbackFocus: `.${toggleRef?.current?.className}`,
        }}
      >
        <div
          id={`locale-dropdown`}
          ref={localeRef}
          aria-hidden={!localesOpen}
          className={cn(styles.main, { [styles['main--open']]: localesOpen })}
        >
          <ul
            className={cn(styles['locale-list'], 'container-10', {
              [styles[`locale-list--open`]]: localesOpen,
            })}
          >
            {locales?.map(({ nativeName, name }, index) => {
              const notCurrentLang = sitecoreContext?.language !== name;
              return (
                <li key={`locale-${index}`} className={styles['locale']}>
                  <LinkOrComponent
                    hrefLang={name}
                    className={cn(styles[`locale-link`])}
                    field={notCurrentLang ? `/${name?.toLowerCase()}` : undefined}
                    aria-label={`Switch to ${getEnglishLangName(name)} - ${nativeName}`}
                  >
                    {nativeName}
                  </LinkOrComponent>
                </li>
              );
            })}
          </ul>
        </div>
      </FocusTrap>
    </div>
  );
};

export default LocaleDropdown;
