import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';

import ResourceCard from '../ResourceCard';

import styles from './InsightsResourceCards.module.scss';

export type InsightResource = {
  image?: ImageField;
  headline?: string;
  description?: string;
  url?: string;
};

export type InsightsResourceCardsProps = ComponentProps & {
  fields?: {
    resourceCards?: Array<InsightResource>;
  };
};

const InsightsResourceCards = (props: InsightsResourceCardsProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const { resourceCards = [] } = props.fields || {};

  if (!hasItemsOrIsEE(resourceCards)) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'spacer', 'container-10')}>
      <ul className={styles.grid}>
        {resourceCards?.map((card, index) => (
          <li key={index} className={styles.card}>
            <ResourceCard {...card} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InsightsResourceCards;
