import { Field, ImageField, LinkField, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';

import { hasValue } from 'src/utils/hasValue';
import { getStringFieldValue } from 'src/utils/sitecoreFieldHelpers';

import Date from '../Date';
import { DateField } from '../Date/Date';
import ImageCta from '../ImageCta';
import LinkOrComponent from '../LinkOrComponent/LinkOrComponent';
import RichText from '../RichText';
import Text from '../Text';
import UnderlineCta from '../UnderlineCta';

import styles from './BaseLinkCard.module.scss';

type BaseLinkCardProps = {
  className?: string;
  url?: string | LinkField | LinkFieldValue | undefined;
  image?: ImageField;
  hideImage?: boolean;
  rowLayout?: boolean;
  children: React.ReactNode;
};

export const BaseLinkCard = ({
  className,
  image,
  url,
  hideImage,
  rowLayout = false,
  children,
}: BaseLinkCardProps) => {
  const { t } = useDictionary();

  return (
    <div
      className={cn(styles['main'], className, {
        [styles['has-link']]: hasValue(url),
        [styles['row']]: rowLayout,
      })}
    >
      {!hideImage && hasValue(image) && (
        <LinkOrComponent
          field={url}
          className={styles['image-link']}
          tabIndex={-1}
          aria-hidden={true}
        >
          <ImageCta
            sizes="336px"
            image={image}
            ctaText={t('Read More')}
            field={url}
            className={styles['image']}
            aria-hidden={true}
          />
        </LinkOrComponent>
      )}
      <LinkOrComponent field={url} className={styles['content']}>
        {children}
      </LinkOrComponent>
    </div>
  );
};

const BaseLinkTitle = ({
  field,
  underline,
  className,
}: {
  field?: Field<string> | undefined;
  underline?: boolean;
  className?: string;
}) => {
  return (
    <RichText
      tag={'h3'}
      field={field}
      styled={false}
      editable={false}
      className={cn(styles['title'], className, { [styles['underline']]: underline })}
    />
  );
};

const BaseLinkDate = ({
  field,
  displayDate,
  className,
}: {
  field?: DateField | undefined;
  displayDate: DateField | undefined;
  className?: string;
}) => {
  return <Date field={field} displayDate={displayDate} className={cn(styles['date'], className)} />;
};

const BaseLinkDescription = ({
  field,
  className,
}: {
  field?: Field<string> | undefined;
  className?: string;
}) => {
  return (
    <RichText
      field={field}
      className={cn(styles['description'], className)}
      styled={false}
      replaceAnchorTags={true}
      editable={false}
    />
  );
};

const BaseLinkContentType = ({
  field,
  className,
}: {
  field?: Field<string> | undefined;
  className?: string;
}) => {
  return <Text tag={'span'} field={field} className={cn(styles['content-type'], className)} />;
};

const BaseLinkCta = ({
  text,
  className,
}: {
  text?: string | Field<string> | undefined;
  className?: string;
}) => {
  const { t } = useDictionary();
  const ctaText = getStringFieldValue(text);
  return (
    <UnderlineCta
      tag="span"
      text={ctaText || t('Learn More')}
      className={cn(styles['cta'], className)}
    />
  );
};

BaseLinkCard.Title = BaseLinkTitle;
BaseLinkCard.Date = BaseLinkDate;
BaseLinkCard.Description = BaseLinkDescription;
BaseLinkCard.ContentType = BaseLinkContentType;
BaseLinkCard.Cta = BaseLinkCta;
