import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';

import { hasValue } from 'src/utils/hasValue';

import BoxCta from '../BoxCta';
import Image from '../Image';
import { LinkProps } from '../Link';

import styles from './ImageCta.module.scss';

export type ImageCtaProps = {
  image: ImageField | undefined;
  ctaText?: string;
  className?: string;
  imageLoadPriority?: boolean;
  sizes?: string;
} & LinkProps;

const ImageCta = (props: ImageCtaProps): JSX.Element => {
  const { image, imageLoadPriority, field, ctaText, className, sizes, ...restProps } = props;
  const { t } = useDictionary();

  return (
    <div className={cn(styles['main'], className)}>
      <Image
        sizes={sizes}
        fill={true}
        className={styles['image']}
        field={image}
        alt={''}
        priority={imageLoadPriority}
      />
      {hasValue(field) && (
        <div className={cn(styles['cta-container'])}>
          <div className={styles['cta-button']}>
            <BoxCta {...restProps} tag={'span'} text={ctaText || t('Learn More')} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCta;
