import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import React from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';
import { getPlaceholder } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import { PlaceholderList } from 'components/BaseHelpers/PlaceholderList/PlaceholderList';
import RichText from 'components/BaseHelpers/RichText';

import { BasicContentTextCardItemProps } from '../BasicContentTextCardItem';

import styles from './BasicContentTextCards.module.scss';

export type BasicContentTextCardsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.TextCards;

const BasicContentTextCards = (props: BasicContentTextCardsProps): JSX.Element => {
  const { hasItemsOrIsEE, hasValueOrIsEE } = useExpEditor();
  const { moduleHeader, moduleDescription } = props.fields || {};
  const placeholderName = 'text-cards';
  const itemsToRender =
    getPlaceholder<BasicContentTextCardItemProps>(placeholderName, props.rendering)?.filter(
      (item) => shouldRender(item.fields)
    ) || [];
  const evenNumberOfCards = itemsToRender.length % 2 === 0;

  if (!hasItemsOrIsEE(itemsToRender)) {
    return <></>;
  }

  return (
    <div className={styles[`wrapper`]}>
      <AnimateInContainer className={cn(styles.main, 'spacer', 'container-12')}>
        {(hasValueOrIsEE(moduleHeader) || hasValueOrIsEE(moduleDescription)) && (
          <div className={styles['header-container']}>
            <ModuleHeader className={styles['header']} header={moduleHeader} />
            <RichText className={styles['description']} field={moduleDescription} />
          </div>
        )}
        <Placeholder
          name="text-cards"
          rendering={props.rendering}
          render={(components, data) => {
            return (
              <PlaceholderList
                components={components}
                data={data}
                shouldRender={shouldRender}
                classNames={{
                  list: styles['cards-container'],
                  item: cn(styles.item, { [styles['even-cards']]: evenNumberOfCards }),
                }}
              />
            );
          }}
        />
      </AnimateInContainer>
    </div>
  );
};

const shouldRender = (fields?: BasicContentTextCardItemProps['fields']) => {
  return hasValue(fields?.body) && hasValue(fields?.title);
};

export default BasicContentTextCards;
