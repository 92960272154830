import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Link from 'components/BaseHelpers/Link';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentAttachment.module.scss';

export type BasicContentAttachmentProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Attachment;

const BasicContentAttachment = (props: BasicContentAttachmentProps): JSX.Element => {
  const { fields } = props;
  const { file, fileName, link } = fields || {};
  const { isEE } = useExpEditor();
  const { t } = useDictionary();

  if (isEE) {
    return (
      <li>
        <span className={styles.main}>
          <Text className={styles['file-name']} field={fileName} />
        </span>
      </li>
    );
  }
  if (!hasValue(file) && !hasValue(link)) return <></>;

  const linkField = file?.value ? { value: { ...file.value, target: '_blank' } } : link?.value;

  return (
    <li>
      <Link className={styles.main} field={linkField}>
        <Text
          className={styles['file-name']}
          field={hasValue(fileName) ? fileName : { value: t('Download Document') }}
        />
      </Link>
    </li>
  );
};

export default BasicContentAttachment;
