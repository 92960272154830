import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseTiles from 'components/BaseHelpers/BaseTiles';

export type BasicContentVerticalTilesProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Tiles;

const BasicContentVerticalTiles = (props: BasicContentVerticalTilesProps): JSX.Element => {
  return <BaseTiles {...props} orientation={'vertical'} />;
};

export default BasicContentVerticalTiles;
