import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { getLinkFieldValue } from 'src/utils/sitecoreFieldHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentFirmFeature.module.scss';

export type BasicContentFirmFeatureProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.FirmFeature;

const BasicContentFirmFeature = ({
  fields = {},
  rendering,
}: BasicContentFirmFeatureProps): JSX.Element => {
  const { image, headline, bodyText, imagePosition, ctaLink } = fields;
  const { hasValueOrIsEE } = useExpEditor();
  const { t } = useDictionary();
  const ctaLinkFields = getLinkFieldValue(ctaLink);
  const headlineID = `FirmFeature_Headline${rendering.uid}`;
  const ctaID = `FirmFeature_CTA${rendering.uid}`;

  if (hasValueOrIsEE(image)) {
    return (
      <AnimateInContainer className={cn('spacer', 'container-10', styles.main)}>
        <div className={styles.wrapper}>
          <div
            className={cn(
              styles['image-box'],
              { [styles['image-box--right']]: imagePosition?.value === 'Right' },
              'exp-editor-img-container'
            )}
          >
            <Image className={styles.image} field={image} fill={true} alt="" />
          </div>
          <div className={styles.content}>
            <Text className={styles.headline} id={headlineID} field={headline} tag="h2" />
            <RichText className={styles.body} field={bodyText} />
            {hasValueOrIsEE(ctaLinkFields) && (
              <BoxCta
                className={styles.cta}
                id={ctaID}
                aria-labelledby={`${ctaID} ${headlineID}`}
                tag="a"
                field={ctaLinkFields}
              >
                {ctaLinkFields.text || t('Read More')}
              </BoxCta>
            )}
          </div>
        </div>
      </AnimateInContainer>
    );
  }
  return <></>;
};

export default BasicContentFirmFeature;
