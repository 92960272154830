import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useExpansionFocus } from 'src/hooks/useExpansionFocus';

import { resizeIframesFullWidth } from 'src/utils/resizeIframeFullWidth';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './BasicContentRichText.module.scss';

export type BasicContentRichTextProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.RichText;

const BasicContentRichText = ({
  fields = {},
  rendering,
}: BasicContentRichTextProps): JSX.Element => {
  const { hasValueOrIsEE, isEE } = useExpEditor();
  const { t } = useDictionary();
  const [isExpanded, setIsExpanded] = useState(false);

  const { containerRef } = useExpansionFocus(isExpanded);

  const isExpandedOrIsEE = isExpanded || isEE;

  useEffect(function resizeIframes() {
    if (containerRef.current) {
      resizeIframesFullWidth(containerRef.current);
    }
  }, []);

  if (!hasValueOrIsEE(fields?.text)) {
    return <></>;
  }

  const regionId = `region-${rendering.uid}`;
  const buttonId = `button-${rendering.uid}`;

  return (
    <AnimateInContainer className={cn(styles.main, 'container-8', 'spacer')}>
      <ModuleHeader header={fields.header} />
      <RichText className={styles.text} field={fields.text} />
      {hasValueOrIsEE(fields.textReadMore) && (
        <>
          <div
            ref={containerRef}
            className={cn(styles.container)}
            id={regionId}
            role="region"
            aria-labelledby={buttonId}
            aria-hidden={isExpandedOrIsEE ? 'false' : 'true'}
            tabIndex={isExpanded ? -1 : undefined}
          >
            <RichText className={styles['read-more-text']} field={fields.textReadMore} />
          </div>
          <UnderlineCta
            className={styles.cta}
            tag="button"
            text={isExpandedOrIsEE ? t('Read Less') : t('Read More')}
            onClick={() => setIsExpanded((currentValue) => !currentValue)}
            centered={true}
            id={buttonId}
            aria-expanded={isExpandedOrIsEE ? 'true' : 'false'}
            aria-controls={regionId}
          />
        </>
      )}
    </AnimateInContainer>
  );
};

export default BasicContentRichText;
