import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useGlobalStore } from 'src/hooks/useGlobalStore';

import { formatDate, getDateString } from 'src/utils/formatDate';
import { hasValue } from 'src/utils/hasValue';

import styles from './Date.module.scss';

export type DateProps = {
  className?: string;
  field?: DateField;
  displayDate?: DateField;
};

export type DateField = Field<string> | string | number | undefined;

const Date = (props: DateProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { field, displayDate, className } = props;

  const { countryCode } = useGlobalStore();
  const [showDate, setShowDate] = useState<boolean>(false);

  const formattedDate = hasValue(displayDate)
    ? getDateString(displayDate)
    : formatDate(field, countryCode, sitecoreContext.language);

  useEffect(() => {
    setShowDate(true);
  }, []);

  if (!formattedDate) return <></>;

  return (
    <span
      className={cn(styles['date'], className)}
      style={{ opacity: showDate || process.env.STORYBOOK_TESTRUNNER ? 1 : 0 }}
    >
      {formattedDate}
    </span>
  );
};

export default Date;
