import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';

import ServiceList from '../ServiceList';

import styles from './ServicesServiceList.module.scss';

type Service = {
  name: string;
  url: string;
};

type ServiceListService = {
  label: string;
  isEnabled: boolean;
  services: Service[];
};

export type ServicesServiceListProps = ComponentProps & {
  fields: {
    serviceList: ServiceListService[];
  };
};

const ServicesServiceList = (props: ServicesServiceListProps): JSX.Element => {
  const { serviceList } = props?.fields || {};
  const { hasItemsOrIsEE } = useExpEditor();

  if (!hasItemsOrIsEE(serviceList)) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'container-10', 'spacer')}>
      <ul>
        {serviceList.map((serviceListItem, index) => {
          if (serviceListItem?.isEnabled && hasItems(serviceListItem?.services)) {
            return (
              <li key={index} id={serviceListItem?.label}>
                <AnimateInContainer className={cn('spacer')}>
                  <h2 className={styles[`label`]}>{serviceListItem?.label}</h2>
                  <ServiceList
                    variant={serviceListItem?.services?.length >= 6 ? 'columns' : 'default'}
                    list={serviceListItem?.services}
                  />
                </AnimateInContainer>
              </li>
            );
          }
          return <></>;
        })}
      </ul>
    </div>
  );
};

export default ServicesServiceList;
