import { useEffect, useState } from 'react';

export const useMediaQuery = (mediaQuery: string): boolean | undefined => {
  const [isMatch, setIsMatch] = useState<boolean>();

  useEffect(() => {
    const mql = window.matchMedia(mediaQuery);
    setIsMatch(mql.matches);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsMatch(e.matches);
    };

    mql.addEventListener('change', handleChange);

    return () => {
      mql.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMatch;
};
