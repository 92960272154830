import { Field, LinkField, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';

import { hasValue } from 'src/utils/hasValue';

import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './Address.module.scss';

export type AddressProps = {
  phone1?: LinkFieldValue;
  phone2?: LinkFieldValue;
  streetAddress?: string;
  googleMapsLink?: LinkField;
  telephoneLabel?: Field<string>;
};

const Address = ({
  streetAddress,
  googleMapsLink,
  phone1,
  phone2,
  telephoneLabel,
}: AddressProps): JSX.Element => {
  if (!hasValue(streetAddress) && !hasValue(googleMapsLink)) {
    return <></>;
  }

  return (
    <div className={styles[`main`]}>
      <LinkOrComponent className={styles[`street-address`]} field={googleMapsLink}>
        <RichText
          field={{ value: streetAddress }}
          tag="span"
          className={styles[`street-address-text`]}
        />
      </LinkOrComponent>
      {(phone1?.href || phone2?.href) && (
        <div className={styles[`label`]}>
          <Text field={telephoneLabel} />
        </div>
      )}
      {phone1?.href && <Link className={styles[`phone`]} field={phone1} />}
      {phone2?.href && <Link className={styles[`phone`]} field={phone2} />}
    </div>
  );
};

export default Address;
