import cn from 'classnames';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentTextCardItem.module.scss';

export type BasicContentTextCardItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.TextCardItem;

const BasicContentTextCardItem = (props: BasicContentTextCardItemProps): JSX.Element => {
  const { body, title } = props.fields || {};

  return (
    <div className={cn(styles.main)}>
      <div className={styles['content']}>
        <Text className={styles['title']} field={title} tag={'h3'} />
        <RichText className={styles['body']} field={body} />
      </div>
    </div>
  );
};

export default BasicContentTextCardItem;
