import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { getAddresses } from 'src/utils/getAddresses';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BaseContactsCard from 'components/BaseHelpers/BaseContacts/BaseContactsCard';
import Image from 'components/BaseHelpers/Image';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import NoImageHero from 'components/BaseHelpers/NoImageHero';
import Text from 'components/BaseHelpers/Text';

import Address from '../Address';

import styles from './OfficeOfficeHeader.module.scss';

export type OfficeOfficeHeaderFields = NonNullable<
  Website.Project.Main.PageTypes.Offices.Office['fields']
>;

const OfficeOfficeHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { hasItemsOrIsEE, hasValueOrIsEE } = useExpEditor();
  const { t } = useDictionary();

  const fields = sitecoreContext.route?.fields as OfficeOfficeHeaderFields;

  const {
    officeManagingPartner,
    formalOfficeName,
    name,
    mapImage,
    addressLabel,
    telephoneLabel,
    officeManagingPartnerLabel,
  } = fields;

  const addresses = getAddresses(fields);

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <NoImageHero className={cn(styles[`hero`])} headline={name} body={formalOfficeName} />
      <div>
        {hasValueOrIsEE(mapImage) && (
          <LinkOrComponent
            field={addresses[0]?.googleMapsLink}
            aria-label={`${t('View Map')} ${formalOfficeName || ''} ${name || ''}`}
            className={cn(styles[`image`], 'exp-editor-img-container')}
          >
            <Image fill={true} field={mapImage} priority={true} alt="" />
          </LinkOrComponent>
        )}
        {(hasItemsOrIsEE(officeManagingPartner) || hasItemsOrIsEE(addresses)) && (
          <div className={styles[`details`]}>
            {hasItemsOrIsEE(addresses) && (
              <div className={styles[`addresses`]}>
                <div className={styles[`label`]}>
                  <Text field={addressLabel} />
                </div>
                <ul className={styles[`container`]}>
                  {addresses.map((address, index) => {
                    return (
                      <li className={styles[`container-item`]} key={`address-${index}`}>
                        <Address {...address} telephoneLabel={telephoneLabel} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {hasItemsOrIsEE(officeManagingPartner) && (
              <div className={styles[`partners`]}>
                <div className={styles[`label`]}>
                  <Text field={officeManagingPartnerLabel} />
                </div>
                <ul className={styles[`container`]}>
                  {officeManagingPartner?.slice(0, 2).map((partner, index) => {
                    return (
                      <li
                        className={styles[`container-item`]}
                        key={`office-managing-partner-${index}`}
                      >
                        <BaseContactsCard fields={partner} hideImage={{ value: true }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </AnimateInContainer>
  );
};

export default OfficeOfficeHeader;
