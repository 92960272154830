import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';

import styles from './BasicContentIconCards.module.scss';

export type BasicContentIconCardsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.IconCards;

const BasicContentIconCards = ({
  fields = {},
  rendering,
}: BasicContentIconCardsProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();

  const placeholderName = 'icon-cards';

  const placeholder = rendering.placeholders?.[placeholderName] || [];
  if (!hasItemsOrIsEE(placeholder)) {
    return <></>;
  }

  const numberOfCards = placeholder.filter((item) => 'componentName' in item).length;
  const maxPerRow = 4;

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader className={styles.header} header={fields.moduleHeader} />
      <RichText className={styles.description} field={fields.moduleDescription} />

      <ul className={cn(styles.items, styles[`items--${Math.min(numberOfCards, maxPerRow)}`])}>
        <Placeholder
          name={placeholderName}
          rendering={rendering}
          renderEach={(component, index) => (
            <li className={styles.item} key={index}>
              {component}
            </li>
          )}
        />
      </ul>
    </AnimateInContainer>
  );
};

export default BasicContentIconCards;
