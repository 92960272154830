import { createContext, useContext } from 'react';

export type PdfAdditionalData = {
  professionalRelatedInsights?: unknown;
};

type PdfStoreContextValue = {
  additionalData?: PdfAdditionalData;
};

const PdfStoreContext = createContext<PdfStoreContextValue | undefined>(undefined);

export const PdfStoreProvider = ({
  additionalData,
  children,
}: {
  additionalData?: PdfAdditionalData;
  children: React.ReactNode;
}) => {
  const value = {
    additionalData,
  };

  return <PdfStoreContext.Provider value={value}>{children}</PdfStoreContext.Provider>;
};

export const usePdfStore = () => {
  const context = useContext(PdfStoreContext);
  if (context === undefined) {
    throw new Error('usePdfStore must be used within a PdfStoreProvider');
  }
  return context;
};
