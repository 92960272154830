import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { updateError } from 'src/utils/errorHelpers';

import { logger } from 'lib/logger';
import { parseRequestMetaData } from 'lib/logger/package';
import { SitecorePageProps } from 'lib/page-props';

import Page from './[[...path]]';

/**
 * Rendered for 500 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */

const ErrorPage: NextPage = () => {
  const [pageProps, setPageProps] = useState<SitecorePageProps>();
  const [isLoaded, setIsLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const getPageProps = async () => {
      try {
        const url = `/api/error/${router.locale || 'en'}`;
        const response = await fetch(url);
        const props = (await response.json()) as SitecorePageProps;

        if (props?.layoutData?.sitecore?.route) {
          setPageProps(props);
        } else {
          throw new Error('Unable to fetch error page');
        }
      } catch (error) {
        setPageProps(getFallbackProps());
        if (error instanceof Error) {
          logger.error(updateError(error, { message: '_error.tsx' }));
        }
      } finally {
        setIsLoaded(true);
      }
    };

    getPageProps();
  }, []);

  return isLoaded && pageProps ? <Page {...pageProps} /> : <></>;
};

ErrorPage.getInitialProps = ({ req, err }) => {
  const childLogger = logger.child(parseRequestMetaData(req));
  childLogger.error(err);
  return { error: err };
};

const getFallbackProps = (): SitecorePageProps => {
  return {
    site: { name: 'main', hostName: '*', language: 'en' },
    locale: 'en',
    dictionary: {},
    componentProps: {},
    notFound: false,
    layoutData: {
      sitecore: {
        context: {},
        route: {
          name: 'Error',
          fields: {
            header: {
              value: 'Something happened',
            },
            description: {
              value: 'Please visit this site again later.',
            },
          },
          placeholders: {
            head: [],
            header: [],
            'page-header': [
              {
                uid: '06753b8e-79d2-47cf-9e1e-427979a4b4c7',
                componentName: 'BasicContentErrorPageHeader',
                dataSource: '',
                params: {},
              },
            ],
            content: [],
            'page-footer': [],
            footer: [],
          },
        },
      },
    },
  };
};

export default ErrorPage;
