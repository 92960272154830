import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentSimpleStat.module.scss';

export type BasicContentSimpleStatProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.SimpleStat;

const BasicContentSimpleStat = ({ fields = {} }: BasicContentSimpleStatProps): JSX.Element => {
  const { dataPointDescription, dataPointText } = fields;
  const { hasValueOrIsEE } = useExpEditor();

  if (!hasValueOrIsEE(dataPointDescription) && !hasValueOrIsEE(dataPointText)) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <Text className={styles.number} field={dataPointText} tag="h3" />
      <RichText className={styles.description} field={dataPointDescription} />
    </div>
  );
};

export default BasicContentSimpleStat;
