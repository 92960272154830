import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';

import styles from './ServicesLetterFilter.module.scss';

export type Letter = {
  label: string;
  isEnabled: boolean;
};

export type ServicesLetterFilterProps = ComponentProps & {
  fields: {
    letterFilters: Letter[];
  };
};

const ServicesLetterFilter = (props: ServicesLetterFilterProps): JSX.Element => {
  const { letterFilters } = props?.fields || {};
  const { hasItemsOrIsEE } = useExpEditor();
  if (!hasItemsOrIsEE(letterFilters)) {
    return <></>;
  }
  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ul className={styles[`letters`]}>
        {letterFilters?.map((letter, index) => {
          return (
            <li
              key={index}
              className={cn(styles['letter'], { [styles[`disabled`]]: !letter?.isEnabled })}
            >
              <LinkOrComponent field={letter?.isEnabled ? `#${letter?.label}` : undefined}>
                {letter?.label}
              </LinkOrComponent>
            </li>
          );
        })}
      </ul>
    </AnimateInContainer>
  );
};

export default ServicesLetterFilter;
