import { InteractiveResult, Raw, Result, buildInteractiveResult } from '@coveo/headless';
import { useCallback, useMemo, useRef } from 'react';

import { decodeResultFields, relativeURL } from 'src/utils/coveo';
import { getEmail } from 'src/utils/getEmail';

import { useBaseSearch } from './useBaseSearch';
import { useCoveoExcerpt } from './useCoveoExcerpt';
import { useSite } from './useSite';

interface RawResult extends Raw {
  // default result fields
  titlecf?: string;
  tcsiteurl: string;
  displayDate?: string;
  datesort?: string | number;
  sectiontitle?: string;
  contenttypestitles?: Array<string>;
  imageurl?: string;
  imagealt?: string;
  hidedetailpage?: string;
  abstract?: string;
  desc?: string;
  // people-specific result fields
  peoplefullname?: string;
  peopletitle?: string;
  peopletitlegendered?: string;
  businessEmail?: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
  officeslinks?: string;
  officeslinkstc?: string;
}

export const useCoveoResult = ({
  result,
  hideExcerpt = false,
}: {
  result: Result;
  hideExcerpt?: boolean;
}) => {
  const { isTCSite } = useSite();
  const { engine } = useBaseSearch();
  const interactiveResultController = useRef<InteractiveResult>(
    buildInteractiveResult(engine, { options: { result } })
  );
  const excerpt = useCoveoExcerpt({ result, hideCondtion: hideExcerpt });

  const logResultAnalytics = useCallback(() => {
    interactiveResultController.current.select();
  }, []);

  const rawData = useMemo(() => {
    const raw = decodeResultFields<RawResult>(result.raw);
    const email = getEmail(raw.businessEmail);
    const officesLinks = parseOfficesLinks(isTCSite ? raw.officeslinkstc : raw.officeslinks);
    const phones = [raw.phone1, raw.phone2, raw.phone3];
    const officePhones = getOfficePhones(officesLinks, phones);
    return {
      ...raw,
      fullName: raw.peoplefullname,
      peopleTitle: raw.peopletitlegendered,
      displayDate: raw.displayDate,
      date: raw.datesort,
      title: raw.peoplefullname || raw.titlecf || result.title.split('|')[0].trim(),
      type: raw.contenttypestitles?.[0] || raw.peopletitlegendered || raw.sectiontitle,
      url: raw.hidedetailpage ? undefined : relativeURL(isTCSite ? raw.tcsiteurl : result.clickUri),
      email,
      officePhones,
      image: {
        src: raw.imageurl,
        alt: raw.imagealt,
      },
      excerpt,
    };
  }, [isTCSite, result, excerpt]);

  const data = useMemo(() => {
    return {
      ...rawData,
      excerpt,
    };
  }, [excerpt, rawData]);

  return { data, logResultAnalytics };
};

type OfficesLinksArray = Array<{
  text?: string;
  url?: string;
}>;

const parseOfficesLinks = (officesLinks: string | undefined): OfficesLinksArray => {
  if (!officesLinks) return [];
  try {
    return JSON.parse(officesLinks);
  } catch (e) {
    return [];
  }
};

const getOfficePhones = (officesLinks: OfficesLinksArray, phones: Array<string | undefined>) => {
  const results: Array<{
    office: string | undefined;
    officeurl: string | undefined;
    phone: string | undefined;
  }> = [];

  for (let i = 0; i < Math.max(officesLinks?.length || 0, phones?.length || 0); i++) {
    if (!officesLinks[i]?.text && !phones[i]) continue;

    results.push({
      office: officesLinks[i]?.text || '',
      phone: phones[i],
      officeurl: officesLinks[i]?.url || '',
    });
  }
  return results.slice(0, 3);
};
