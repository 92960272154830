import { Suggestion } from '@coveo/headless';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { TypeaheadItem, useSearch } from 'src/hooks/useSolrSearch';

import { stringFieldToNumberValue } from 'src/utils/sitecoreFieldHelpers';

import { SearchBox } from 'components/BaseHelpers/BaseSearch/SearchBox';

interface NameSearchBoxProps {
  className?: string;
  placeholder?: Field<string>;
  typeaheadPageSize: Field<string> | Field<number> | undefined;
}

export const NameSearchBox = ({
  className,
  placeholder,
  typeaheadPageSize,
}: NameSearchBoxProps) => {
  const { state, controller } = useBaseSearch();
  const { query } = state;
  const { updateQueryText, submitSearch } = controller;
  const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);

  const { updateParams, response } = useSearch({
    path: 'people/typeahead',
    pageSize: stringFieldToNumberValue(typeaheadPageSize) || 3,
  });

  useEffect(() => {
    updateParams({ key: 'query', value: query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setSuggestions(
      response.data.map((result: TypeaheadItem) => ({
        rawValue: result.name || '',
        highlightedValue: result.name || '',
        url: result.url,
      }))
    );
  }, [response.data]);

  return (
    <SearchBox
      className={className}
      id={'people-search-name-box'}
      value={query}
      updateText={updateQueryText}
      submitSearch={submitSearch}
      suggestions={suggestions}
      placeholder={placeholder}
    />
  );
};
