import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';

import { getCookie, setCookie } from 'src/utils/cookie';
import { parsePdfOptionsParam } from 'src/utils/pdfOptionsParam';

export const useCountryCode = () => {
  const router = useRouter();
  const hasRan = useRef(false);
  const [countryCode, setCountryCode] = useState('');
  const pdfOptions = parsePdfOptionsParam(router.query);
  const queryParam =
    typeof router.query.countrycode === 'string'
      ? router.query.countrycode
      : pdfOptions.countryCode
        ? pdfOptions.countryCode
        : '';

  useEffect(() => {
    if (!hasRan.current) {
      hasRan.current = true;

      const cookieValue = getCookie('cf-ipcountry');
      if (cookieValue) {
        setCountryCode(cookieValue);
      } else {
        const getCountryCode = async () => {
          try {
            const response = await fetch('/api/country-code');
            const json: { countryCode: string } = await response.json();

            if (json?.countryCode) {
              const expireInOneHour = 60 * 60 * 1000;
              setCookie('cf-ipcountry', json.countryCode, expireInOneHour);
              setCountryCode(json.countryCode);
            }
          } catch (error) {
            console.log(error);
          }
        };
        getCountryCode();
      }
    }
  }, []);

  return {
    countryCode: queryParam || countryCode,
  };
};
