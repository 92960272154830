import {
  AdvancedSearchQueryActionCreatorPayload,
  AdvancedSearchQueryActionCreators,
  BreadcrumbValue,
  DateFacetBreadcrumb,
  DateFacetValue,
  FacetBreadcrumb,
  FacetValue,
  PaginationActionCreators,
  QueryActionCreators,
  Result,
  SearchActionCreators,
  SearchAnalyticsActionCreators,
  SearchEngine,
  SortCriteriaActionCreators,
  SortCriterion,
  SortOrder,
  StandaloneSearchBoxAnalytics,
  Suggestion,
  buildFieldSortCriterion,
  buildRelevanceSortCriterion,
  loadAdvancedSearchQueryActions,
  loadPaginationActions,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  loadSortCriteriaActions,
} from '@coveo/headless';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { RefObject, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useCoveoBuilder } from 'src/hooks/useCoveoBuilder';
import { CoveoFacet, FacetConfig, useCoveoFacets } from 'src/hooks/useCoveoFacet';
import { useCoveoFields } from 'src/hooks/useCoveoFields';
import { useUrlManager } from 'src/hooks/useUrlManager';

import {
  encodeFieldNames,
  getSearchBoxData,
  getShouldScrollToResults,
  removeShouldScrollToResults,
} from 'src/utils/coveo';
import { stringFieldToNumberValue } from 'src/utils/sitecoreFieldHelpers';

import { COVEO_FIELDS, DATE_RANGE_FIELDS, FacetController, FacetValues } from 'src/types/coveo';

export interface BaseSearchProps {
  /** `coveo` `SearchEngine` -- we use a different engine per page. use `useEngineContext` hook */
  engine: SearchEngine;
  /** the initial facet data to pass to `coveo` */
  facetConfig: FacetConfig;
  resultConfig: {
    /** number of results per page (how many on initial search and how many are added each time we click "Load More") */
    resultPageSize?: Field<string> | Field<number>;
    /** max number of typeahead suggestions */
    suggestionPageSize?: Field<string> | Field<number>;
    /** custom sort params. use `coveo` functions like `buildFieldSortCriterion` */
    sortCriterion?: SortCriterion | Array<SortCriterion>;
    /** for pages that show results on first load */
    alwaysShow?: boolean;
  };
  /** extra query params that we can manually pass to `coveo`. see docs for syntax: https://docs.coveo.com/en/1552/searching-with-coveo/query-syntax  */
  queryConfig?: {
    initialQuery?: string;
    constantQuery?: string;
    advancedQuery?: string;
  };
  /** whether or not to use `urlManager` to update query params. default is `true` */
  updateUrl?: boolean;
  children: React.ReactNode;
}

export interface BaseSearchContextValue {
  engine: SearchEngine;
  state: {
    facets: Array<CoveoFacet>;
    results: Array<Result>;
    hasResults: boolean;
    moreResultsAvailable: boolean;
    showResults: boolean;
    resultCount: {
      current: number;
      total: number;
    };
    breadcrumbs: Breadcrumbs;
    hasBreadcrumbs: boolean;
    suggestions: Array<Suggestion>;
    query: string;
    currentQuery: string | undefined;
    hasQuery: boolean;
    isLoading: boolean;
    isError: boolean;
    sortCriterionType: SortCriterion | Array<SortCriterion> | undefined;
    didYouMean: {
      originalQuery: string;
      correctedQuery: string;
      wasAutomaticallyCorrected: boolean;
      hasQueryCorrection: boolean;
    };
  };
  controller: {
    updateQueryText: (value: string) => void;
    selectQuerySuggestion: (value: string) => void;
    submitSearch: () => void;
    selectSingleFacetValue: (facet: CoveoFacet, value: FacetValues) => void;
    selectMultiFacetValue: (facet: CoveoFacet, value: FacetValues) => void;
    clearFacet: (facet: CoveoFacet) => void;
    loadMoreResults: () => void;
    clearQuery: () => void;
    clearBreadcrumb: (breadcrumb: BreadcrumbValue<FacetValues>) => void;
    clearAll: () => void;
    applyQueryCorrection: () => void;
    updateSort: (value: string, submitAfter?: boolean) => void;
  };
  refs: {
    resultsRef: RefObject<HTMLDivElement>;
  };
}

export const BaseSearchContext = createContext<BaseSearchContextValue | undefined>(undefined);

export type Criterion = {
  text: string;
  value: string;
  count?: number;
};

export type Breadcrumbs = Array<FacetBreadcrumb | DateFacetBreadcrumb>;

export type ParsedLocalStorageData = {
  value: string;
  analytics: StandaloneSearchBoxAnalytics;
};

const DEFAULT_NUMBER_OF_RESULTS = 8;
const DEFAULT_NUMBER_OF_SUGGESTIONS = 5;

const BaseSearch = ({
  engine,
  facetConfig,
  resultConfig,
  queryConfig,
  updateUrl = true,
  children,
}: BaseSearchProps) => {
  const { resultPageSize, suggestionPageSize, sortCriterion, alwaysShow } = resultConfig;
  const { advancedQuery, constantQuery, initialQuery } = queryConfig || {};
  const { sitecoreContext } = useSitecoreContext();
  const facets = useCoveoFacets(engine, facetConfig);
  const coveoFields = useCoveoFields();

  const numberOfResults = useMemo(
    () => stringFieldToNumberValue(resultPageSize) || DEFAULT_NUMBER_OF_RESULTS,
    [resultPageSize]
  );
  const [contextController] = useCoveoBuilder('context', engine);
  const [_q, querySummaryState] = useCoveoBuilder('querysummary', engine);
  const [searchBoxController, searchBoxState] = useCoveoBuilder('searchbox', engine, {
    numberOfSuggestions:
      stringFieldToNumberValue(suggestionPageSize) || DEFAULT_NUMBER_OF_SUGGESTIONS,
    highlightOptions: {
      exactMatchDelimiters: {
        open: '<strong>',
        close: '</strong>',
      },
    },
  });
  const [breadcrumbController, breadcrumbState] = useCoveoBuilder('breadcrumb', engine);
  const [didYouMeanController, didYouMeanState] = useCoveoBuilder('didyoumean', engine, {
    automaticallyCorrectQuery: true,
  });
  const [_r, resultListState] = useCoveoBuilder('resultlist', engine, {
    fieldsToInclude: getFieldsToInclude(coveoFields),
  });
  const [resultsPerPageController, resultsPerPageState] = useCoveoBuilder(
    'resultsperpage',
    engine,
    {
      initialState: {
        numberOfResults,
      },
    }
  );
  const [initialTitle, setInitialTitle] = useState<string>();
  const [sortCriterionType, setSortCriterionType] = useState<
    SortCriterion | Array<SortCriterion> | undefined
  >();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>([]);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const resultsRef = useRef<HTMLDivElement>(null);

  const searchActions = useRef<SearchActionCreators>(loadSearchActions(engine));
  const analyticsActions = useRef<SearchAnalyticsActionCreators>(
    loadSearchAnalyticsActions(engine)
  );
  const paginationActions = useRef<PaginationActionCreators>(loadPaginationActions(engine));
  const queryActions = useRef<QueryActionCreators>(loadQueryActions(engine));
  const sortActions = useRef<SortCriteriaActionCreators>(loadSortCriteriaActions(engine));
  const advancedQueryActions = useRef<AdvancedSearchQueryActionCreators>(
    loadAdvancedSearchQueryActions(engine)
  );

  const defaultSortCriterion = useMemo(() => {
    return alwaysShow && sortCriterion ? sortCriterion : buildRelevanceSortCriterion();
  }, [alwaysShow, sortCriterion]);

  useCoveoBuilder('sort', engine, {
    initialState: {
      criterion: defaultSortCriterion,
    },
  });

  useUrlManager(engine, updateUrl);

  useEffect(() => {
    setHasMounted(true);
    setInitialTitle(document.title);

    return () => {
      setHasMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!hasMounted) return;

    const query: AdvancedSearchQueryActionCreatorPayload = {};
    if (constantQuery) query.cq = constantQuery;
    // don't update advanced query if the URL contains search params on load
    if (advancedQuery && !location.search) {
      query.aq = advancedQuery;
    }
    if (query.aq || query.cq) {
      const { updateAdvancedSearchQueries } = advancedQueryActions.current;
      engine.dispatch(updateAdvancedSearchQueries(query));
    }

    const { updateQuery } = queryActions.current;

    const contextConfig: Record<string, string> = {
      language: sitecoreContext.language || 'en',
      isontcsite: sitecoreContext?.site?.name === 'tc' ? '1' : '0',
    };
    if (process.env.NEXT_PUBLIC_COVEO_SOURCE) {
      contextConfig.source = process.env.NEXT_PUBLIC_COVEO_SOURCE;
    }
    contextController?.set(contextConfig);

    // if we were redirected here from Search Box in the nav bar,
    // get data from local storage for Coveo analytics and update query
    const data = getSearchBoxData();
    // we can pass an initial query for components like `InsightsTopicsContent`
    const initial = data ? data.value : initialQuery ? initialQuery : undefined;
    if (initial) engine.dispatch(updateQuery({ q: initial }));

    if (querySummaryState?.firstSearchExecuted) {
      // if first search has been executed somewhere, that means we're setting
      // a facet value from an internal link -- like Trending Topics in the Insights Mega Menu --
      // or this is a subsequent search from Site Search Box
      const { executeSearch } = searchActions.current;
      const { logSearchFromLink } = analyticsActions.current;
      engine.dispatch(executeSearch(logSearchFromLink()));
    } else if (data) {
      // initial Site Search from the header
      engine.executeFirstSearchAfterStandaloneSearchBoxRedirect(data.analytics);
    } else {
      // initial search on this page
      engine.executeFirstSearch();
    }

    return () => {
      // clean up to avoid stale queries when navigating back to current page
      engine.dispatch(updateQuery({ q: '' }));
      breadcrumbController?.deselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted]);

  useEffect(() => {
    setBreadcrumbs([
      ...(breadcrumbState?.facetBreadcrumbs || []),
      ...(breadcrumbState?.dateFacetBreadcrumbs || []),
    ]);
  }, [breadcrumbState?.facetBreadcrumbs, breadcrumbState?.dateFacetBreadcrumbs]);

  useEffect(() => {
    const hasQueryOrFacet = querySummaryState?.hasQuery || breadcrumbState?.hasBreadcrumbs;
    setShowResults(hasQueryOrFacet || alwaysShow || false);
  }, [querySummaryState, breadcrumbState, alwaysShow]);

  useEffect(() => {
    if (!updateUrl || !initialTitle) return;
    if (querySummaryState?.hasQuery) {
      document.title = `Search: ${querySummaryState?.query} | ${initialTitle}`;
    } else {
      document.title = initialTitle;
    }
  }, [initialTitle, querySummaryState?.hasQuery, querySummaryState?.query, updateUrl]);

  // START AUTO SCROLL TO RESULTS LOGIC
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);

  useEffect(() => {
    if (querySummaryState?.isLoading) return;

    // normally, we only scroll to results when a query is executed from the search form.
    // but in a few cases, we link here from elsewhere and still want to scroll,
    // so we set a flag in `localStorage` at those call sites and read it here
    const shouldScrollOverride = getShouldScrollToResults();

    if (shouldScroll || shouldScrollOverride) {
      timeoutRef.current = setTimeout(() => {
        const container = resultsRef.current;
        if (!container) return;
        const containerTop = container.getBoundingClientRect().top;
        // most of the time we scroll down to results
        // but a few components cause us to scroll up, which makes the nav bar appear
        // so we leave a little extra room for that case
        const scrollY = containerTop < 0 ? window.scrollY - 150 : window.scrollY;
        window.scrollTo({
          top: containerTop + scrollY,
          behavior: 'smooth',
        });
        setShouldScroll(false);
        removeShouldScrollToResults();
      }, 300);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [querySummaryState?.isLoading, shouldScroll]);
  // END AUTO SCROLL TO RESULTS LOGIC

  // START PRIVATE METHODS
  const updateAdvanedQuery = useCallback(
    (action: 'add' | 'remove') => {
      if (!advancedQuery) return;
      const { updateAdvancedSearchQueries } = advancedQueryActions.current;
      engine.dispatch(updateAdvancedSearchQueries({ aq: action === 'add' ? advancedQuery : '' }));
    },
    [advancedQuery, engine]
  );

  const updateSort = useCallback(
    (
      type: 'relevancy' | 'custom' | 'ascending' | 'descending' | 'default',
      submitAfter?: boolean
    ) => {
      let criterion = defaultSortCriterion;
      switch (type) {
        case 'relevancy':
          criterion = buildRelevanceSortCriterion();
          break;
        case 'ascending':
          criterion = buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Ascending);
          break;
        case 'descending':
          criterion = buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Descending);
          break;
        case 'custom':
          // upcoming events are sorted ascending so the soonest ones show first
          criterion = sortCriterion || defaultSortCriterion;
          break;
        case 'default':
          criterion = defaultSortCriterion;
          break;
      }

      const { updateSortCriterion } = sortActions.current;
      engine.dispatch(updateSortCriterion(criterion));
      setSortCriterionType(criterion);

      if (submitAfter) {
        const { executeSearch } = searchActions.current;
        const { logSearchFromLink } = analyticsActions.current;
        engine.dispatch(executeSearch(logSearchFromLink()));
      }
    },
    [defaultSortCriterion, engine, sortCriterion, sortCriterionType]
  );

  const queryPreSearch = useCallback(
    (isClearing: boolean) => {
      if (!isClearing) {
        updateSort('relevancy');
        updateAdvanedQuery('remove');
        return;
      }

      if (breadcrumbState?.hasBreadcrumbs) {
        updateSort('custom');
      } else {
        updateAdvanedQuery('add');
      }
    },
    [breadcrumbState?.hasBreadcrumbs, updateAdvanedQuery, updateSort]
  );

  const facetPreSearch = useCallback(
    ({ isClearing, isUpcoming }: { isClearing: boolean; isUpcoming?: boolean }) => {
      if (querySummaryState?.hasQuery) return;
      const isClearingLastFacet = isClearing && breadcrumbs.length === 1;
      const nextSort = isClearingLastFacet ? 'default' : isUpcoming ? 'ascending' : 'custom';
      updateSort(nextSort);
      updateAdvanedQuery(isClearingLastFacet ? 'add' : 'remove');
    },
    [breadcrumbs.length, querySummaryState?.hasQuery, updateAdvanedQuery, updateSort]
  );

  const resetNumberOfResults = useCallback(() => {
    if (paginationActions.current) {
      engine.dispatch(paginationActions.current.updateNumberOfResults(numberOfResults));
    }
  }, [engine, numberOfResults]);

  // `coveo` doesn't update state until _after_ a query is run, so we have to update things manually before that.
  // stick this at the top of any function that will execute a query (ie, adding or removing queries/facets/breadcrumbs).
  const handlePreSearch = useCallback(
    ({
      type,
      isClearing,
      isUpcoming,
    }: {
      type: 'query' | 'facet' | 'breadcrumb' | 'all';
      isClearing: boolean;
      isUpcoming?: boolean;
    }) => {
      resetNumberOfResults();

      switch (type) {
        case 'query':
          queryPreSearch(isClearing);
          break;
        case 'facet':
          facetPreSearch({ isClearing, isUpcoming });
          break;
        case 'breadcrumb':
          // breadcrumb actions always clear facet values
          facetPreSearch({ isClearing: true });
          break;
        case 'all':
        default:
          updateSort('default');
          updateAdvanedQuery('add');
      }

      if (!isClearing) setShouldScroll(true);
    },
    [facetPreSearch, queryPreSearch, resetNumberOfResults, updateAdvanedQuery, updateSort]
  );
  // END PRIVATE METHODS

  // START PUBLIC METHODS
  const updateQueryText = useCallback(
    (value: string) => {
      searchBoxController?.updateText(value);
    },
    [searchBoxController]
  );

  const submitSearch = useCallback(() => {
    if (!searchBoxState?.value) return;
    handlePreSearch({ type: 'query', isClearing: false });
    searchBoxController?.submit();
  }, [handlePreSearch, searchBoxController, searchBoxState?.value]);

  const selectQuerySuggestion = useCallback(
    (value: string) => {
      handlePreSearch({ type: 'query', isClearing: false });
      if (searchBoxState?.suggestions.length) {
        searchBoxController?.selectSuggestion(value);
      } else {
        // values from `Trending Topics` aren't in the `suggestions` array
        // so we don't get the built-in clearing of facets and sort criteria.
        // executing search manually allows us to handle that clean up
        searchBoxController?.updateText(value);
        searchBoxController?.submit();
      }
    },
    [handlePreSearch, searchBoxController, searchBoxState?.suggestions]
  );

  const selectFacetValue = useCallback(
    (type: 'single' | 'multi', facet: CoveoFacet, value: FacetValues) => {
      const { controller, field } = facet;
      // `topic` facets run a keyword search using the AI-generated value
      // because it returns more results
      if (field === COVEO_FIELDS.topics && 'value' in value) {
        handlePreSearch({ type: 'query', isClearing: false });
        searchBoxController?.updateText(value.value);
        searchBoxController?.submit();
      } else {
        const isUpcoming = 'start' in value && value.start === DATE_RANGE_FIELDS.now;
        handlePreSearch({
          type: 'facet',
          isClearing: isValueSelected(controller, value),
          isUpcoming,
        });
        toggleValue(type, controller, value);
      }
    },
    [handlePreSearch, searchBoxController]
  );

  const selectSingleFacetValue = useCallback(
    (facet: CoveoFacet, value: FacetValues) => {
      selectFacetValue('single', facet, value);
    },
    [selectFacetValue]
  );

  const selectMultiFacetValue = useCallback(
    (facet: CoveoFacet, value: FacetValues) => {
      selectFacetValue('multi', facet, value);
    },
    [selectFacetValue]
  );

  const clearFacet = useCallback(
    (facet: CoveoFacet) => {
      const { controller } = facet;
      handlePreSearch({ type: 'facet', isClearing: true });
      controller?.deselectAll();
    },
    [handlePreSearch]
  );

  const loadMoreResults = useCallback(() => {
    const currentNumberOfResults = resultsPerPageState?.numberOfResults || numberOfResults;
    resultsPerPageController?.set(currentNumberOfResults + numberOfResults);
  }, [resultsPerPageState, resultsPerPageController, numberOfResults]);

  const clearQuery = useCallback(() => {
    searchBoxController?.clear();
    handlePreSearch({ type: 'query', isClearing: true });
    searchBoxController?.submit();
  }, [searchBoxController, handlePreSearch]);

  const clearBreadcrumb = useCallback(
    (breadcrumb: BreadcrumbValue<FacetValue>) => {
      handlePreSearch({ type: 'breadcrumb', isClearing: true });
      breadcrumb.deselect();
    },
    [handlePreSearch]
  );

  const clearAll = useCallback(() => {
    handlePreSearch({ type: 'all', isClearing: true });
    engine.dispatch(queryActions.current.updateQuery({ q: '' }));
    breadcrumbController?.deselectAll();
  }, [breadcrumbController, engine, handlePreSearch]);

  const applyQueryCorrection = useCallback(() => {
    didYouMeanController?.applyCorrection();
  }, [didYouMeanController]);
  // END PUBLIC METHODS

  const controller = {
    updateQueryText,
    selectQuerySuggestion,
    submitSearch,
    selectSingleFacetValue,
    selectMultiFacetValue,
    clearFacet,
    loadMoreResults,
    clearQuery,
    clearAll,
    clearBreadcrumb,
    applyQueryCorrection,
    updateSort,
  };

  const state = {
    facets,
    results: resultListState?.results || [],
    hasResults: resultListState?.hasResults || false,
    moreResultsAvailable: resultListState?.moreResultsAvailable || false,
    showResults,
    resultCount: {
      current: resultListState?.results.length || 0,
      total: querySummaryState?.total || 0,
    },
    breadcrumbs,
    hasBreadcrumbs: breadcrumbState?.hasBreadcrumbs || false,
    suggestions: searchBoxState?.suggestions || [],
    query: searchBoxState?.value || '',
    currentQuery: querySummaryState?.query,
    hasQuery: querySummaryState?.hasQuery || false,
    isLoading: querySummaryState?.isLoading || false,
    isError: querySummaryState?.hasError || false,
    sortCriterionType,
    didYouMean: {
      originalQuery: didYouMeanState?.originalQuery || '',
      correctedQuery: didYouMeanState?.queryCorrection.correctedQuery || '',
      wasAutomaticallyCorrected: didYouMeanState?.wasAutomaticallyCorrected || false,
      hasQueryCorrection: didYouMeanState?.hasQueryCorrection || false,
    },
  };

  const refs = {
    resultsRef,
  };

  return (
    <BaseSearchContext.Provider
      value={{
        engine,
        state,
        controller,
        refs,
      }}
    >
      {children}
    </BaseSearchContext.Provider>
  );
};

const getFieldsToInclude = (fields: ReturnType<typeof useCoveoFields>) => {
  const resultFields = [
    'titlecf',
    'tcsiteurl',
    'imageurl',
    'businessEmail',
    'displayDate',
    'phone1',
    'phone2',
    'phone3',
    'imagealt',
    'peopletitlegendered',
    'peoplefullname',
    'peoplelastnameletter',
    'sectiontitle',
    'jobtitle',
    'jobcity',
    'hidedetailpage',
    'abstract',
    'desc',
    ...Object.values(fields),
  ];
  return encodeFieldNames(resultFields);
};

const isValueSelected = (facetController: FacetController | null, value: FacetValues) => {
  if (!facetController || !value) return false;
  return facetController.isValueSelected(value as FacetValue & DateFacetValue);
};

const toggleValue = (
  type: 'single' | 'multi',
  facetController: FacetController | null,
  value: FacetValues
) => {
  if (type === 'single') {
    facetController?.toggleSingleSelect(value as FacetValue & DateFacetValue);
  } else {
    facetController?.toggleSelect(value as FacetValue & DateFacetValue);
  }
};

export default BaseSearch;
