import { gsap } from 'gsap';
import { RefObject } from 'react';
import { useRef, useEffect } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';

import CloseIcon from '../../../../../../assets/icons/close.svg';

import styles from './ToggleMobileMenu.module.scss';

export type ToggleMobileMenuProps = {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  menuToggleRef: RefObject<HTMLButtonElement>;
};

const ToggleMobileMenu = ({
  menuOpen,
  setMenuOpen,
  menuToggleRef,
}: ToggleMobileMenuProps): JSX.Element => {
  const { t } = useDictionary();
  const tl = useRef<GSAPTimeline>();

  useEffect(() => {
    if (menuOpen) {
      tl?.current?.play();
    } else {
      tl?.current?.reverse(0);
    }
  }, [menuOpen]);

  useIsomorphicLayoutEffect(() => {
    gsap.set('.close_svg__top', { y: 0 });
    gsap.set('.close_svg__gold', { y: 0 });
    gsap.set('.close_svg__middle', { y: 0 });
    gsap.set('.close_svg__plus', { opacity: 0, y: 0 });

    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current
        .to('.close_svg__top', { y: 8, opacity: 0, duration: 0.25 })
        .to('.close_svg__gold', { y: -8, transformOrigin: '50% 50%', duration: 0.25 }, '<')
        .to('.close_svg__middle', { opacity: 0, duration: 0 })
        .to('.close_svg__gold', { rotation: -45, x: -1, duration: 0.2 })
        .to('.close_svg__plus', { opacity: 1, rotation: 45, x: 3, y: -8.2, duration: 0.25 }, '<');
    }, menuToggleRef);

    return () => ctx.revert();
  }, []);

  return (
    <button
      className={styles.main}
      aria-label={t('Mobile Menu')}
      aria-expanded={menuOpen}
      aria-controls={'mobile-menu'}
      ref={menuToggleRef}
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <CloseIcon className={styles.close} />
    </button>
  );
};

export default ToggleMobileMenu;
