import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useMemo } from 'react';

import { useCoveoEngine } from 'src/hooks/useCoveoEngine';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { COVEO_FIELDS } from 'src/types/coveo';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';
import Text from 'components/BaseHelpers/Text';

import { TopicFilterResults } from './TopicFilterResults';

import styles from './InsightsTopicFilterInsights.module.scss';

type AITag = {
  fields: {
    name: {
      value: string;
    };
  };
};

export type InsightsTopicFilterInsightsProps = ComponentProps & {
  fields: {
    aiTags: AITag[];
    moduleHeader: Field<string>;
    noResultsText: Field<string>;
    displayDateFilters: Field<boolean>;
    resultCountLabelFormat: Field<string>;
  };
};

const getFacetFields = (displayDateFilters: boolean | undefined) => {
  return displayDateFilters ? [COVEO_FIELDS.topics, COVEO_FIELDS.dateRange] : [COVEO_FIELDS.topics];
};

const InsightsTopicFilterInsights = (props: InsightsTopicFilterInsightsProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const {
    aiTags = [],
    moduleHeader,
    noResultsText,
    displayDateFilters,
    resultCountLabelFormat,
  } = props.fields || {};
  const topicsEngine = useCoveoEngine('ResourceCenter');
  const topicsFacetValues = useMemo(() => {
    return aiTags.map((value) => value.fields?.name?.value);
  }, [aiTags]);
  const initialQuery = useMemo(() => {
    if (!topicsFacetValues || !topicsFacetValues.length) return;
    return topicsFacetValues[0];
  }, [topicsFacetValues]);
  const sortCriterion = useMemo(
    () => buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Descending),
    []
  );

  const facetConfig = {
    facetFields: getFacetFields(displayDateFilters?.value),
    topicsFacetValues,
  };
  const resultConfig = {
    alwaysShow: true,
    sortCriterion,
  };
  const queryConfig = {
    initialQuery,
  };

  if (!hasItemsOrIsEE(aiTags)) return <></>;

  return (
    <AnimateInContainer className={styles.main}>
      <BaseSearch
        engine={topicsEngine}
        resultConfig={resultConfig}
        facetConfig={facetConfig}
        queryConfig={queryConfig}
        updateUrl={false}
      >
        <TopicFilterResults
          moduleHeader={moduleHeader}
          noResults={<Text field={noResultsText} />}
          statsTemplate={resultCountLabelFormat}
        />
      </BaseSearch>
    </AnimateInContainer>
  );
};

export default InsightsTopicFilterInsights;
