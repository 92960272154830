import cn from 'classnames';
import { gsap, Sine } from 'gsap';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useGlobalStore } from 'src/hooks/useGlobalStore';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { useSite } from 'src/hooks/useSite';
import { useTemplate } from 'src/hooks/useTemplate';

import MayerHomeLogo from '../../../assets/icons/mayer-home-logo.svg';
import TCHomeLogo from '../../../assets/icons/tc-home-logo.svg';

import styles from './HorizontalImageSplitHero.module.scss';

interface HomeLogoProps {
  isImagesLoaded: boolean;
}

export const HomeLogo = ({ isImagesLoaded }: HomeLogoProps) => {
  const { isHomePage } = useTemplate();
  const { isTCSite } = useSite();
  const { homeAnimationPlayed, setHomeAnimationPlayed } = useGlobalStore();
  const tl = useRef<GSAPTimeline>();
  const [hasPlayedAnimationToday, setHasPlayedAnimationToday] = useState<boolean>(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hasPlayed = getHasPlayedToday();
    setHasPlayedAnimationToday(hasPlayed);
    if (hasPlayed) {
      setHomeAnimationPlayed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!isHomePage) return;

    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        paused: true,
        onComplete: () => {
          setHasPlayedToday();
          setHomeAnimationPlayed(true);
        },
      });
      setTimeline(tl.current, isTCSite);
    }, containerRef);

    return () => {
      ctx.revert();
    };
  }, [isHomePage, isTCSite]);

  useEffect(() => {
    if (isImagesLoaded && isHomePage && !homeAnimationPlayed && !hasPlayedAnimationToday) {
      tl?.current?.restart();
    }
  }, [isImagesLoaded, homeAnimationPlayed, isHomePage, hasPlayedAnimationToday]);

  const HomeLogoSvg = useMemo(() => {
    return isTCSite ? TCHomeLogo : MayerHomeLogo;
  }, [isTCSite]);

  return (
    <div className={styles['logo-wrapper']} ref={containerRef}>
      <HomeLogoSvg
        className={cn(styles[`logo`], { [styles['has-played']]: hasPlayedAnimationToday })}
      />
    </div>
  );
};

const setTimeline = (tl: GSAPTimeline, isTCSite: boolean) => {
  if (isTCSite) {
    const wordConfig = { ease: Sine.easeInOut, duration: 2, delay: 0.5 };
    const lineConfig = {
      scale: 0,
      transformOrigin: '50% 50%',
      ease: Sine.easeInOut,
      duration: 0.6,
    };
    tl.to('.tc-home-logo_svg__st1', { x: '400px', ...wordConfig }, 0)
      .to('.tc-home-logo_svg__st3', { x: '400px', ...wordConfig }, 0)
      .to('.tc-home-logo_svg__st2', { x: '-400px', ...wordConfig }, 0)
      .to('.tc-home-logo_svg__st4', { x: '-600px', ...wordConfig }, 0)
      .to('.tc-home-logo_svg__st0', lineConfig, '>-0.5')
      .to('#tc-home-logo_svg__horizontal-bar', lineConfig, '>-0.5');
  } else {
    const wordConfig = { ease: Sine.easeInOut, duration: 2, delay: 0.5 };
    tl.to('.mayer-home-logo_svg__st1', { x: '400px', ...wordConfig }, 0)
      .to('.mayer-home-logo_svg__st2', { x: '-400px', ...wordConfig }, 0)
      .to(
        '#mayer-home-logo_svg__Logos',
        { height: '0px', ease: Sine.easeInOut, duration: 1 },
        '>-0.25'
      );
  }
};

const setHasPlayedToday = () => {
  localStorage.setItem('has-played-home-animation', JSON.stringify({ date: Date.now() }));
};

const getHasPlayedToday = () => {
  const hasPlayedHomeAnimation = localStorage.getItem('has-played-home-animation');
  const hasPlayedParsed: { date: string | number | undefined } = hasPlayedHomeAnimation
    ? JSON.parse(hasPlayedHomeAnimation)
    : {};
  const { date } = hasPlayedParsed;
  if (!date) return false;
  const previous = new Date(date);
  const today = new Date();
  return (
    previous.getDate() === today.getDate() &&
    previous.getMonth() === today.getMonth() &&
    previous.getFullYear() === today.getFullYear()
  );
};
