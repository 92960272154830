import { RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useLanguages } from 'src/hooks/useLanguages';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';

import Languages from 'components/BaseHelpers/Languages';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';

import Chevron from '../../../assets/icons/chevron.svg';

import styles from './NavigationBreadcrumbs.module.scss';

export type BreadcrumbLink = {
  targetUrl: string;
  text: string;
};

export type NavigationBreadcrumbsProps = ComponentProps & {
  fields: {
    breadcrumbs: BreadcrumbLink[];
  };
};

const NavigationBreadcrumbs = ({
  fields: { breadcrumbs },
}: NavigationBreadcrumbsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { isEE } = useExpEditor();
  const languages = useLanguages();

  if (!hasItems(languages) && !hasItems(breadcrumbs)) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'container-12')}>
      <StopIndex />
      {hasItems(breadcrumbs) ? (
        <ul className={styles[`breadcrumbs`]}>
          {breadcrumbs?.map((breadcrumb, index) => {
            return (
              breadcrumb?.text &&
              breadcrumb?.targetUrl && (
                <li key={`breadcrumb-${index}`} className={styles[`breadcrumb`]}>
                  <LinkOrComponent
                    className={styles[`link`]}
                    tag={isEE ? 'a' : 'div'}
                    field={
                      '/' + sitecoreContext?.language + sitecoreContext?.itemPath !==
                      breadcrumb?.targetUrl
                        ? breadcrumb?.targetUrl
                        : undefined
                    }
                  >
                    <RichText className={styles[`text`]} field={{ value: breadcrumb.text }} />
                  </LinkOrComponent>
                  {index !== breadcrumbs.length - 1 && <Chevron />}
                </li>
              )
            );
          })}
        </ul>
      ) : (
        <div></div>
      )}
      <div className={styles[`languages`]}>
        <Languages langs={languages} />
      </div>
      <StartIndex />
    </div>
  );
};

export default NavigationBreadcrumbs;
