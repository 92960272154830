import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import BackToTop from 'assets/icons/back-to-top.svg';

import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';

import styles from './BasicContentBackToTopButton.module.scss';

const BasicContentBackToTopButton = (): JSX.Element => {
  const { t } = useDictionary();
  const { isEE } = useExpEditor();

  const handleClick = () => {
    if (isEE) return;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    const firstFocusableElement = document.querySelector<HTMLElement>(
      'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    if (firstFocusableElement) {
      firstFocusableElement.focus({ preventScroll: true });
    }
  };

  return (
    <>
      <StopIndex />
      <div className={cn(styles.main, 'spacer', 'container-12')}>
        <button className={styles['button']} onClick={handleClick}>
          <span className={styles['icon-container']} aria-hidden="true">
            <BackToTop />
          </span>
          {t('Back to top')}
        </button>
      </div>
      <StartIndex />
    </>
  );
};

export default BasicContentBackToTopButton;
