import { Result } from '@coveo/headless';

import { useCoveoResult } from 'src/hooks/useCoveoResult';

import Date from 'components/BaseHelpers/Date';

import LinkOrComponent from '../LinkOrComponent/LinkOrComponent';

import styles from './BaseSearchResults.module.scss';

export const BaseSearchResult = ({ result }: { result: Result; showImages?: boolean }) => {
  const { data, logResultAnalytics } = useCoveoResult({ result });
  const { type, title, date, displayDate, url, excerpt } = data;

  return (
    <LinkOrComponent
      field={url}
      className={styles['result-link']}
      onClick={url ? logResultAnalytics : undefined}
    >
      <div className={styles['content']}>
        {(type || date) && (
          <div className={styles['content-type-and-date']}>
            {type && <span className={styles['content-type']}>{type}</span>}
            {(date !== undefined || displayDate !== undefined) && (
              <Date field={date} displayDate={displayDate} />
            )}
          </div>
        )}

        <h3 className={styles['title']} dangerouslySetInnerHTML={{ __html: title }}></h3>
        {excerpt && (
          <span className={styles['exerpt']} dangerouslySetInnerHTML={{ __html: excerpt }}></span>
        )}
      </div>
    </LinkOrComponent>
  );
};
