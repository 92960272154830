import {
  ComponentRendering,
  HtmlElementRendering,
  ImageField,
  ImageFieldValue,
  Item,
  LinkField,
  LinkFieldValue,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';

import { hasItems } from 'src/utils/hasItems';
import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';

export const useExpEditor = () => {
  const { sitecoreContext } = useSitecoreContext();

  const isEE = sitecoreContext?.pageEditing || false;

  const hasValueOrIsEE = (
    field:
      | undefined
      | string
      | number
      | ImageField
      | ImageFieldValue
      | LinkField
      | LinkFieldValue
      | TextField
      | Item
  ): boolean => {
    if (isEE) {
      return true;
    }

    return hasValue(field);
  };

  const hasItemsOrIsEE = (items: unknown): boolean => {
    if (isEE) {
      return true;
    }
    return hasItems(items);
  };

  const hasPlaceholderItemsOrIsEE = (name: string, props: ComponentProps): boolean => {
    if (isEE) {
      return true;
    }
    return hasItems(props?.rendering?.placeholders?.[name]);
  };

  const noItemsAndIsEE = (items: unknown): boolean => {
    return !hasItems(items) && isEE;
  };

  const isComponentRendering = (
    componentData: ComponentRendering | HtmlElementRendering
  ): componentData is ComponentRendering => {
    return 'componentName' in componentData;
  };

  const isHtmlRendering = (
    componentData: ComponentRendering | HtmlElementRendering
  ): componentData is HtmlElementRendering => {
    return !isComponentRendering(componentData);
  };

  return {
    isEE,
    hasValueOrIsEE,
    hasItemsOrIsEE,
    hasPlaceholderItemsOrIsEE,
    noItemsAndIsEE,
    isComponentRendering,
    isHtmlRendering,
  };
};
