import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { Placeholder, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';

import { FlexibleFeatureItem } from 'components/BaseHelpers/BaseFlexibleFeatures/BaseFlexibleFeatureItem';
import BaseFlexibleFeatures from 'components/BaseHelpers/BaseFlexibleFeatures/BaseFlexibleFeatures';
import BaseSearchResults from 'components/BaseHelpers/BaseSearchResults';
import RichText from 'components/BaseHelpers/RichText';

import styles from './InsightsNewsSearch.module.scss';

interface InsightsNewsResultsProps {
  correctionTemplate: Field<string> | undefined;
  statsTemplate: Field<string> | undefined;
  noResultsText: Field<string> | undefined;
  latestNews: Array<FlexibleFeatureItem>;
}

export const InsightsNewsResults = ({
  correctionTemplate,
  statsTemplate,
  noResultsText,
  latestNews,
}: InsightsNewsResultsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const { state } = useBaseSearch();
  const { hasQuery, hasBreadcrumbs } = state;
  const [showContent, setShowContent] = useState<boolean>(true);

  useEffect(() => {
    setShowContent(Boolean(!hasQuery && !hasBreadcrumbs));
  }, [hasBreadcrumbs, latestNews, hasQuery]);

  return (
    <>
      {latestNews && latestNews.length !== 0 && showContent && (
        <BaseFlexibleFeatures
          items={latestNews}
          rendering={{ componentName: 'InsightsFlexibleFeatures ' }}
          params={{}}
        />
      )}
      <BaseSearchResults
        className={cn(styles['results'], 'spacer', 'container-8')}
        correctionTemplate={correctionTemplate}
        statsTemplate={statsTemplate}
        fallback={<></>}
        noResults={
          <RichText field={noResultsText} styled={true} className={styles['no-results']} />
        }
      />
      {showContent && (
        <Placeholder name={'content'} rendering={sitecoreContext?.route as RouteData} />
      )}
    </>
  );
};
