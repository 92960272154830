import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import { Placeholder, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useMemo } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useCoveoFieldConfig } from 'src/hooks/useCoveoFieldConfig';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import { COVEO_FIELDS } from 'src/types/coveo';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import { BaseFacets } from 'components/BaseHelpers/BaseSearch/BaseFacets';
import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';
import { BaseSearchBox } from 'components/BaseHelpers/BaseSearch/BaseSearchBox';
import BaseSearchResults from 'components/BaseHelpers/BaseSearchResults';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';

import styles from './InsightsSearch.module.scss';

export type InsightsSearchProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.Search &
  Website.Feature.Insights._TrendingTopics;
export type InsightsRouteFields = Website.Project.Main.PageTypes.Insights.InsightLanding['fields'];

const InsightsSearch = (props: InsightsSearchProps): JSX.Element => {
  const {
    didYouMeanLabelFormat,
    searchKeywordPlaceholderText,
    resultCountLabelFormat,
    searchResultsPageSize,
    typeaheadResultsPageSize,
    noResultsText,
    trendingTopics,
  } = props.fields || {};
  const { sitecoreContext } = useSitecoreContext();
  const { insightsEngine } = useEngineContext();
  const { facetFields } = useCoveoFieldConfig('insights');
  const { topicsFilter, featuredSectionLabel } =
    getRouteFields<InsightsRouteFields>(sitecoreContext) || {};

  const trendingTopicsValues = useMemo(() => {
    return (trendingTopics || [])
      .map((topic) => {
        return topic.fields?.name?.value ?? '';
      })
      .filter((value) => Boolean(value))
      .slice(0, 5);
  }, [trendingTopics]);
  const topicsFilterValues = useMemo(() => {
    return (topicsFilter || []).map((value) => value.fields?.name?.value ?? '');
  }, [topicsFilter]);
  const topicsFacetValues = useMemo(() => {
    return Array.from(new Set([...trendingTopicsValues, ...topicsFilterValues]));
  }, [topicsFilterValues, trendingTopicsValues]);
  const topicsExclude = useMemo(() => {
    return topicsFacetValues.filter(
      (value) => trendingTopicsValues.includes(value) && !topicsFilterValues.includes(value)
    );
  }, [topicsFacetValues, topicsFilterValues, trendingTopicsValues]);
  const sortCriterion = useMemo(
    () => [buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Descending)],
    []
  );

  const facetConfig = {
    facetFields,
    topicsFacetValues,
    exclude: {
      [COVEO_FIELDS.topics]: topicsExclude,
      // TODO: uncomment if the client decides we need to hide this
      // [COVEO_FIELDS.contentTypes]: ['attorney only'],
    },
  };
  const resultConfig = {
    resultPageSize: searchResultsPageSize,
    suggestionPageSize: typeaheadResultsPageSize,
    sortCriterion,
  };

  return (
    <BaseSearch engine={insightsEngine} facetConfig={facetConfig} resultConfig={resultConfig}>
      <form
        className={cn(styles['form-container'], 'container-12')}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <BaseSearchBox
          className={styles['search-box']}
          placeholder={searchKeywordPlaceholderText}
          topics={trendingTopicsValues}
        />
        <BaseFacets />
      </form>
      <BaseSearchResults
        className={cn(styles['results'], 'spacer', 'container-8')}
        correctionTemplate={didYouMeanLabelFormat}
        statsTemplate={resultCountLabelFormat}
        fallback={
          <div className={styles['content']}>
            <ModuleHeader header={featuredSectionLabel} className={styles['featured-title']} />
            <Placeholder name={'content'} rendering={sitecoreContext?.route as RouteData} />
          </div>
        }
        noResults={
          <RichText field={noResultsText} styled={true} className={styles['no-results']} />
        }
      />
    </BaseSearch>
  );
};

export default InsightsSearch;
