import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentComplexStat.module.scss';

export type BasicContentComplexStatProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.ComplexStat;

const BasicContentComplexStat = (props: BasicContentComplexStatProps): JSX.Element => {
  const { fields } = props || {};
  const { dataPointDescription, dataPointText, eyebrow } = fields || {};

  return (
    <div className={styles.main}>
      <Text className={styles.eyebrow} field={eyebrow} />
      <div className={styles.body}>
        <Text className={styles.number} field={dataPointText} tag="h3" />
        <RichText className={styles.description} field={dataPointDescription} />
      </div>
    </div>
  );
};

export default BasicContentComplexStat;
