import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

export type PeopleEducationItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.EducationItem;

const PeopleEducationItem = ({ fields = {} }: PeopleEducationItemProps): JSX.Element => {
  const { school, degree, concentration, details } = fields;
  const { isEE } = useExpEditor();
  const education = [
    [school?.fields?.name?.value, degree?.fields?.name?.value].filter(Boolean).join(', '),
    concentration?.value,
  ]
    .filter(Boolean)
    .join(', ');

  if (!education && isEE) {
    return <span>Education: [No data entered]. Click here to add.</span>;
  }

  const secondLine = details?.value ? `<br>${details.value}` : '';

  return <span dangerouslySetInnerHTML={{ __html: `${education}${secondLine}` }} />;
};

export default PeopleEducationItem;
