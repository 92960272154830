import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDictionary } from 'src/hooks/useDictionary';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import { hasItems } from 'src/utils/hasItems';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';

import Chevron from '../../../assets/icons/chevron.svg';
import { useGetUrls } from './useGetUrls';
import { useSetTop } from './useSetTop';

import styles from './PeopleDetailHeroLeft.module.scss';

export type PeopleDetailHeroLeftProps = RouteData<
  Website.Project.Main.PageTypes.People.Professional['fields']
>;

const PeopleDetailHeroLeft = (rendering: PeopleDetailHeroLeftProps): JSX.Element => {
  const { fields = {}, itemId } = rendering;
  const { image } = fields;
  const { t } = useDictionary();
  const { containerRef, stickyRef } = useSetTop();
  const { getPdfUrl, getVcardUrl } = useGetUrls();
  const [showPdfLinks, setShowPdfLinks] = useState<boolean>(false);

  const placeholderWithShareTrayOnly = rendering.placeholders['page-header']?.filter(
    (component) =>
      'componentName' in component && component.componentName === 'BasicContentShareTray'
  );
  const renderingClone = structuredClone(rendering) as RouteData;
  renderingClone.placeholders['page-header'] = placeholderWithShareTrayOnly;

  const handlePdfClick = () => {
    setShowPdfLinks((prev) => !prev);
  };
  const handleClosePdfLinks = () => {
    setShowPdfLinks(false);
  };

  return (
    <div className={styles.main} ref={containerRef}>
      <div className={styles.wrapper} ref={stickyRef}>
        <AnimateInContainer>
          <div className={styles['image-container']}>
            <Image
              className={styles.image}
              field={image}
              priority={true}
              fill={true}
              sizes="(min-width: 768px) 50vw, (min-width: 1440px) 640px, 100vw"
              quality={90}
            />
          </div>

          <div className={styles.tools}>
            <StopIndex />
            <ul className={styles.links}>
              <li className={styles.item}>
                <PdfLinks
                  showPdfLinks={showPdfLinks}
                  getPdfUrl={getPdfUrl}
                  onClick={handlePdfClick}
                  onClickOutside={handleClosePdfLinks}
                />
              </li>
              <li aria-hidden={true}>
                <div className={styles['divider']} />
              </li>
              <li className={styles.item}>
                <a
                  className={cn(styles.link, styles.vcard)}
                  href={getVcardUrl(itemId)}
                  aria-label={t('Download vCard')}
                  download={true}
                >
                  {t('vCard')}
                </a>
              </li>
            </ul>
            {hasItems(placeholderWithShareTrayOnly) && (
              <div className={styles.share}>
                <Placeholder name="page-header" rendering={renderingClone} />
              </div>
            )}
            <StartIndex />
          </div>
        </AnimateInContainer>
      </div>
    </div>
  );
};

const PdfLinks = ({
  showPdfLinks,
  getPdfUrl,
  onClick,
  onClickOutside,
}: {
  showPdfLinks: boolean;
  getPdfUrl: (type?: string) => string;
  onClick: () => void;
  onClickOutside: () => void;
}) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { t } = useDictionary();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const fullLinkRef = useRef<HTMLLIElement>(null);
  const abbrLinkRef = useRef<HTMLLIElement>(null);
  useClickOutside({
    refs: [buttonRef, fullLinkRef, abbrLinkRef],
    callback: onClickOutside,
  });

  const handlePdfLinksPosition = useCallback(
    (button: HTMLButtonElement | null, list: HTMLUListElement | null) => {
      if (!button || !list || isMobile) return;
      const buttonRect = button.getBoundingClientRect();
      const listRect = list.getBoundingClientRect();
      const top =
        buttonRect.bottom + listRect.height >= window.innerHeight
          ? `-${listRect.height}px`
          : '100%';
      list.style.top = top;
    },
    [isMobile]
  );

  useEffect(() => {
    const button = buttonRef.current;
    const list = listRef.current;
    handlePdfLinksPosition(button, list);
    const handleScroll = () => {
      handlePdfLinksPosition(button, list);
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handlePdfLinksPosition]);

  return (
    <>
      <button
        className={cn(styles.link, { [styles['link--active']]: showPdfLinks })}
        onClick={onClick}
        aria-expanded={showPdfLinks}
        aria-controls={`pdf-links`}
        ref={buttonRef}
      >
        {t('Download PDF')}
        <Chevron className={cn(styles[`arrow`], { [styles[`arrow--active`]]: showPdfLinks })} />
      </button>
      <ul
        className={cn(styles['pdf-links-list'], {
          [styles['pdf-links-list--active']]: showPdfLinks,
        })}
        id={'pdf-links'}
        ref={listRef}
      >
        <li className={styles['pdf-links-item']} ref={fullLinkRef}>
          <BoxCta
            field={{ href: getPdfUrl('full'), target: '_blank' }}
            tag={'a'}
            tabIndex={showPdfLinks ? 0 : -1}
          >
            {t('Full Profile')}
          </BoxCta>
        </li>
        <li className={styles['pdf-links-item']} ref={abbrLinkRef}>
          <BoxCta
            field={{ href: getPdfUrl('abbr'), target: '_blank' }}
            tag={'a'}
            tabIndex={showPdfLinks ? 0 : -1}
          >
            {t('Abbreviated Profile')}
          </BoxCta>
        </li>
      </ul>
    </>
  );
};

export default PeopleDetailHeroLeft;
