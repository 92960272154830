import { COVEO_FIELDS, COVEO_FIELDS_TC, DATE_RANGE_FIELDS } from 'src/types/coveo';

import { useDictionary } from './useDictionary';

export const useFacetLabels = () => {
  const { t } = useDictionary();

  return {
    [DATE_RANGE_FIELDS.now]: t('Upcoming'),
    [DATE_RANGE_FIELDS.pastWeek]: t('Past Week'),
    [DATE_RANGE_FIELDS.pastMonth]: t('Past Month'),
    [DATE_RANGE_FIELDS.pastThreeMonths]: t('1 Month - 3 Months Ago'),
    [DATE_RANGE_FIELDS.pastSixMonths]: t('3 Months - 6 Months Ago'),
    [DATE_RANGE_FIELDS.pastYear]: t('6 Months - 1 Year Ago'),
    [DATE_RANGE_FIELDS.older]: t('Older than 1 year'),
    [COVEO_FIELDS.topics]: t('Topics'),
    [COVEO_FIELDS.services]: t('Services'),
    [COVEO_FIELDS_TC.services]: t('Services'),
    [COVEO_FIELDS.industries]: t('Industries'),
    [COVEO_FIELDS_TC.industries]: t('Industries'),
    [COVEO_FIELDS.regions]: t('Regions'),
    [COVEO_FIELDS.offices]: t('Offices'),
    [COVEO_FIELDS_TC.offices]: t('Offices'),
    [COVEO_FIELDS.positions]: t('Positions'),
    [COVEO_FIELDS.dateRange]: t('Date Ranges'),
    [COVEO_FIELDS.contentTypes]: t('Content Types'),
    [COVEO_FIELDS.jobtitle]: t('Job Title'),
    [COVEO_FIELDS.joblocation]: t('Job Location'),
    [COVEO_FIELDS.jobregions]: t('Job Region'),
  };
};
