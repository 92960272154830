import cn from 'classnames';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useSite } from 'src/hooks/useSite';

import { relativeURL } from 'src/utils/coveo';
import { isInLastRow } from 'src/utils/isInLastRow';

import { BaseResults } from 'components/BaseHelpers/BaseSearch/BaseResults';
import { BaseSearchResultsProps } from 'components/BaseHelpers/BaseSearchResults';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';

import styles from './JobResults.module.scss';

interface RawResult {
  jobtitle?: string;
  jobcity?: string;
  link?: string;
  tcsiteurl?: string;
}

const JobResults = ({
  statsTemplate,
  correctionTemplate,
  fallback,
  noResults,
  className,
}: BaseSearchResultsProps): JSX.Element => {
  const { isTCSite } = useSite();
  const { state } = useBaseSearch();
  const { results } = state;
  return (
    <BaseResults
      statsLayout={'row'}
      statsTemplate={statsTemplate}
      correctionTemplate={correctionTemplate}
      fallback={fallback}
      noResults={noResults}
      className={className}
      showHeader={false}
    >
      <ul className={styles['job-grid']}>
        {results.map(({ raw, clickUri }, index) => {
          const { jobtitle, jobcity, tcsiteurl } = raw as RawResult;
          const url = isTCSite ? tcsiteurl : clickUri;
          return (
            <li
              key={index}
              className={cn(styles['job'], {
                [styles['job--last-row']]: isInLastRow(index, results.length, 2),
              })}
            >
              <LinkOrComponent field={relativeURL(url)}>
                <span className={styles['title']}>{jobtitle}</span>
                <span className={styles['city']}>{jobcity}</span>
              </LinkOrComponent>
            </li>
          );
        })}
      </ul>
    </BaseResults>
  );
};

export default JobResults;
