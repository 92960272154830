import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useTemplate } from 'src/hooks/useTemplate';

import { isFutureDate } from 'src/utils/formatDate';
import { getName } from 'src/utils/getName';
import { hasItems } from 'src/utils/hasItems';
import { hasValue } from 'src/utils/hasValue';

import { Website } from 'lib/component-props/model';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Date from 'components/BaseHelpers/Date';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';
import { useGetUrls } from 'components/People/PeopleDetailHeroLeft/useGetUrls';

import styles from './InsightsInsightHeader.module.scss';

type InsightHeaderFields = NonNullable<
  Website.Project.Main.PageTypes.Insights.News['fields'] &
    Website.Project.Main.PageTypes.Insights.Publication['fields'] &
    Website.Project.Main.PageTypes.Insights.Event['fields']
>;

type Person = Website.Feature.People._Person;

const InsightsInsightHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { t } = useDictionary();
  const {
    date,
    displayDate,
    startDateandTime,
    title,
    authors,
    additionalAuthors,
    hostedBy,
    speakers,
    additionalSpeakers,
    source,
    sourceURL,
    registrationLink,
  } = (sitecoreContext.route?.fields || {}) as InsightHeaderFields;
  const attributions = authors || speakers;
  const additionalAttributions = additionalAuthors || additionalSpeakers;
  const attributionsList = createAttributionsList(
    attributions,
    additionalAttributions,
    sitecoreContext.language
  );

  const { getPdfUrl } = useGetUrls();
  const { isNewsDetail, isEventDetail } = useTemplate();
  const label = isNewsDetail
    ? 'Related People'
    : isEventDetail
      ? speakers != null && speakers.length > 1
        ? 'Speakers'
        : 'Speaker'
      : authors != null && authors.length > 1
        ? 'Authors'
        : 'Author';
  const attributionLabel = t(label);
  const split = hasValue(hostedBy);
  const hostLabel = t('Host/s');

  const sourceLabel = hasValue(sourceURL)
    ? `${t('To read complete article visit')} `
    : `${t('Source')}: `;

  return (
    <div className={cn(styles.main, 'spacer', 'container-12')}>
      <div className={styles['content']}>
        <Date
          field={startDateandTime || date}
          displayDate={displayDate}
          className={styles['date']}
        />
        <Text className={styles['title']} tag={'h1'} field={title} editable={false} />
        <div className={styles['split-format']}>
          {attributionsList && hasItems(attributionsList) && (
            <div className={styles[`attributions-container-${split}`]}>
              <span className={styles['attribution-label']}>{attributionLabel}: </span>
              <ul className={styles['attributions-list']}>
                {attributionsList.map((attribution, index) => {
                  const { name, url } = attribution;
                  return (
                    <li key={name} className={styles['attributions-list-item']}>
                      <LinkOrComponent
                        tag="span"
                        field={{ href: url, linktype: 'external' }}
                        rel="noreferrer"
                        className={styles['attributions-list-item__link']}
                      >
                        {name}
                      </LinkOrComponent>
                      {index !== attributionsList.length - 1 && ','}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {hasValue(hostedBy) && (
            <>
              {hasItems(attributionsList) && <div className={styles.divider} />}
              <div className={styles['hosts-container']}>
                <span className={styles['host-label']}>{hostLabel}: </span>
                <RichText className={styles['hosts-value']} field={hostedBy} />
              </div>
            </>
          )}
        </div>
        <div className={styles.links}>
          <a className={styles.pdf} href={getPdfUrl()} target="_blank" rel="noreferrer">
            {t('Download PDF')}
          </a>

          {hasValue(source) && (
            <div className={styles['source']}>
              <span>{sourceLabel}</span>
              <LinkOrComponent
                tag={'span'}
                field={{ href: sourceURL?.value, linktype: 'external', target: '_blank' }}
                className={styles['source__link']}
              >
                <Text field={source} editable={false} />
              </LinkOrComponent>
            </div>
          )}
        </div>
        {registrationLink && isFutureDate(startDateandTime) && (
          <BoxCta
            tag={'a'}
            field={{ href: registrationLink.value, linktype: 'external' }}
            className={styles['registration-cta']}
          >
            {t('Register')}
          </BoxCta>
        )}
      </div>
    </div>
  );
};

type Attribution = {
  name: string;
  url: string | undefined;
};

const createAttributionsList = (
  attributions: Array<Person> | undefined,
  additionalAttributions: Field<string> | undefined,
  language: string | undefined
): Array<Attribution> => {
  const additionalArray = (additionalAttributions?.value || '').split(',');
  return [...(attributions || []), ...additionalArray]
    .map((attribution) => getAttribution(attribution, language))
    .filter(attributionIsNotUndefined);
};

const getAttribution = (
  attribution: Person | string,
  language: string | undefined
): { name: string; url: string | undefined } | undefined => {
  const name =
    typeof attribution === 'string' ? attribution : getName(attribution.fields, language).value;
  const url = typeof attribution === 'string' ? '' : attribution.url;
  if (!name) return;
  return { name, url };
};

const attributionIsNotUndefined = (
  attribution: Attribution | undefined
): attribution is Attribution => {
  return attribution !== undefined && Boolean(attribution.name);
};

export default InsightsInsightHeader;
