import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import HorizontalImageSplitHero from 'components/BaseHelpers/HorizontalImageSplitHero';

export type InsightsHorizontalImageSplitHeroProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.InsightsHorizontalImageSplitHero;

const InsightsHorizontalImageSplitHero = (
  props: InsightsHorizontalImageSplitHeroProps
): JSX.Element => {
  return <HorizontalImageSplitHero {...props} />;
};

export default InsightsHorizontalImageSplitHero;
