import cn from 'classnames';
import { forwardRef } from 'react';

import BaseCta, { BaseCtaProps, useBaseCta } from '../BaseCta';

import styles from './UnderlineCta.module.scss';

export type UnderlineCtaProps = BaseCtaProps & {
  underlineOnHover?: boolean;
  centered?: boolean;
};

const UnderlineCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, UnderlineCtaProps>(
  function UnderlineCtaComponent(props: UnderlineCtaProps, ref) {
    const { className, centered, underlineOnHover, ...otherProps } = props;
    const { shouldRenderCta } = useBaseCta();

    if (!shouldRenderCta(props)) {
      return <></>;
    }

    const underlineClass = underlineOnHover
      ? styles['underline-on-hover']
      : styles['underlined-by-default'];

    if (centered) {
      return (
        <div className={cn(styles['outer-wrapper'], className)}>
          <BaseCta className={cn(styles.main, underlineClass)} {...otherProps} ref={ref} />
        </div>
      );
    }

    return (
      <BaseCta className={cn(styles.main, className, underlineClass)} {...otherProps} ref={ref} />
    );
  }
);

export default UnderlineCta;
