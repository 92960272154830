import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useImagesLoaded } from 'src/hooks/useImagesLoaded';

import { hasValue } from 'src/utils/hasValue';
import { getPlaceholder } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';

import { PlaceholderList } from '../PlaceholderList/PlaceholderList';
import BaseFlexibleFeatureItem, {
  BaseFlexibleFeatureItemProps,
  FlexibleFeatureItem,
} from './BaseFlexibleFeatureItem';

import styles from './BaseFlexibleFeatures.module.scss';

export type BaseFlexibleFeaturesProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.FlexibleFeatures & {
    items?: Array<FlexibleFeatureItem> | undefined;
  };

const BaseFlexibleFeatures = ({
  fields = {},
  rendering,
  items,
}: BaseFlexibleFeaturesProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const { moduleHeader, moduleDescription } = fields;
  const { imagesContainerRef, isImagesLoaded } = useImagesLoaded();

  const placeholderKey = 'flexible-features';
  const placeholder = getPlaceholder<BaseFlexibleFeatureItemProps>(placeholderKey, rendering);
  const featuresToCheck = placeholder?.filter((item) => shouldRender(item.fields));
  const itemsToRender = items?.filter((item) => shouldRender({ source: item }));

  if (!hasItemsOrIsEE(itemsToRender || featuresToCheck)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-8', 'spacer')}>
      <div className={styles['module-wrapper']} ref={imagesContainerRef}>
        <ModuleHeader header={moduleHeader} />
        <RichText field={moduleDescription} />
        {itemsToRender && itemsToRender.length !== 0 ? (
          <ul className={styles[`grid`]}>
            {itemsToRender.map((item, index) => {
              return (
                <li className={styles.item} key={`${item.id}-${index}`}>
                  <BaseFlexibleFeatureItem
                    index={index}
                    fields={{ source: item }}
                    readyForAnimation={isImagesLoaded}
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <Placeholder
            name={placeholderKey}
            rendering={rendering}
            readyForAnimation={isImagesLoaded}
            render={(components, data) => {
              return (
                <PlaceholderList
                  components={components}
                  data={data}
                  shouldRender={shouldRender}
                  classNames={{
                    list: styles['grid'],
                    item: styles.item,
                  }}
                />
              );
            }}
          />
        )}
      </div>
    </AnimateInContainer>
  );
};

const shouldRender = (fields?: BaseFlexibleFeatureItemProps['fields']) => {
  const hasContent =
    (hasValue(fields?.source?.image) || hasValue(fields?.image)) &&
    (hasValue(fields?.source?.headline) || hasValue(fields?.headline));
  return hasContent;
};

export default BaseFlexibleFeatures;
