import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { usePdf } from 'src/hooks/usePdf';

import { hasItems } from 'src/utils/hasItems';
import { createLinkObject } from 'src/utils/sitecoreFieldHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import PdfInsightsPeopleRelatedContent from './PdfInsightsPeopleRelatedContent';
import { PeopleRelatedContentCard } from './PeopleRelatedContentCard';

import styles from './InsightsPeopleRelatedContent.module.scss';

export type InsightsPeopleRelatedContentProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.PeopleRelatedContent & {
    fields?: {
      search: Array<PeopleRelatedContentSearch>;
      insightTypes: Array<ExtendedInsightType> | undefined;
    };
  };

type PeopleRelatedContentSearch = {
  totalFound: number;
  results: Array<PeopleRelatedContentResult>;
  viewAllLink: { text: string; url: string };
};

export type PeopleRelatedContentResult = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields?: {
    carouselImage: ImageField | undefined;
    title: Field<string> | undefined;
    date?: Field<string>;
    displayDate?: Field<string>;
    startDateandTime?: Field<string>;
    publicationType?: Array<ContentType>;
    newsType?: Array<ContentType>;
    eventType?: Array<ContentType>;
  };
};

interface ExtendedInsightType extends Website.Feature.Insights.InsightType {
  displayName: string;
}

type ContentType = {
  id: string;
  name: string;
  displayName: string;
  fields?: { contentType?: Field<string> | undefined; name: Field<string> | undefined };
};

const InsightsPeopleRelatedContent = (props: InsightsPeopleRelatedContentProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const { isPdf } = usePdf();
  const { moduleHeader, insightTypes, search } = props.fields || {};

  if (
    !hasItemsOrIsEE(insightTypes) ||
    !hasItemsOrIsEE(search) ||
    search?.every((s) => !hasItems(s.results))
  ) {
    return <></>;
  }

  if (isPdf) {
    return <PdfInsightsPeopleRelatedContent {...props} />;
  }

  return (
    <>
      <StopIndex />
      <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
        <ModuleHeader header={moduleHeader} />
        <ul className={styles['insight-types-list']}>
          {insightTypes?.map((insightType, index) => {
            const insightResult = search?.[index];
            if (!insightResult || !insightResult.results.length) return <></>;

            const linkObject = createLinkObject(insightResult.viewAllLink.url);

            return (
              <li key={`${insightType.id}`} className={styles['insight-types-item']}>
                <div className={styles['insight-types-item__header']}>
                  <Text
                    className={styles['insight-types-item__heading']}
                    tag={'h3'}
                    field={insightType.fields?.name}
                    editable={false}
                  />
                  <UnderlineCta
                    className={styles['insight-types-item__cta']}
                    tag={'a'}
                    field={linkObject}
                  >
                    {insightResult.viewAllLink.text}
                  </UnderlineCta>
                </div>
                <ul className={styles['insight-result-list']}>
                  {insightResult.results.map((result) => {
                    return (
                      <li key={result.id} className={styles['insight-result-item']}>
                        <PeopleRelatedContentCard {...result} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </AnimateInContainer>
      <StartIndex />
    </>
  );
};

export default InsightsPeopleRelatedContent;
