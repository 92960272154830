import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BasicContentImage from '../BasicContentImage';
import BasicContentVideo from '../BasicContentVideo';

import styles from './BasicContentMediaGridItem.module.scss';

export type BasicContentMediaGridItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.MediaGridItem;

const BasicContentMediaGridItem = (props: BasicContentMediaGridItemProps): JSX.Element => {
  const { caption, image, video } = props.fields || {};
  const { hasValueOrIsEE } = useExpEditor();

  const imageShouldRender = hasValueOrIsEE(image);
  const videoShouldRender = hasValueOrIsEE(image) && hasValueOrIsEE(video);
  if (!imageShouldRender && !videoShouldRender) return <></>;

  return (
    <div className={styles.main}>
      {videoShouldRender ? (
        <BasicContentVideo
          rendering={{ componentName: 'BasicContentVideo' }}
          fields={{ image, video }}
          params={{}}
          isMediaGrid={true}
        />
      ) : (
        <BasicContentImage
          rendering={{ componentName: 'BasicContentImage' }}
          fields={{ image }}
          params={{}}
          isMediaGrid={true}
        />
      )}
      <RichText className={styles['caption']} tag="span" field={caption} />
    </div>
  );
};

export default BasicContentMediaGridItem;
