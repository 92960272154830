import { ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

import { BaseLinkCard } from 'components/BaseHelpers/BaseLinkCard/BaseLinkCard';

import styles from './ResourceCard.module.scss';

export type InsightResource = {
  image?: ImageField;
  headline?: string;
  description?: string;
  url?: string;
};

const ResourceCard = (props: InsightResource): JSX.Element => {
  const { image, headline, description, url } = props || {};

  return (
    <BaseLinkCard className={styles.main} image={image} url={url}>
      <BaseLinkCard.Title
        className={styles.headline}
        field={headline ? { value: headline } : undefined}
      />
      <BaseLinkCard.Description field={description ? { value: description } : undefined} />
    </BaseLinkCard>
  );
};

export default ResourceCard;
