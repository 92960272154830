import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { RefObject, useEffect, useRef } from 'react';

import { useClickOutside } from 'src/hooks/useClickOutside';

import SiteSearchBox from './SiteSearchBox';

import styles from './SearchDropdown.module.scss';

export type SearchDropdownProps = {
  siteSearchPlaceholderText?: Field<string>;
  searchOpen: boolean;
  results?: string[];
  setSearchOpen?: (searchOpen: boolean) => void;
  toggleRef?: RefObject<HTMLButtonElement>;
};

const SearchDropdown = ({
  siteSearchPlaceholderText,
  searchOpen,
  setSearchOpen,
  toggleRef,
}: SearchDropdownProps): JSX.Element => {
  const searchRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const searchInputId = `desktop-site-search-input`;

  useEffect(() => {
    if (!searchOpen) return;

    timeoutRef.current = setTimeout(() => {
      const input = searchRef.current?.querySelector('input');
      input?.focus({ preventScroll: true });
    }, 100);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [searchOpen]);

  const closeDropdown = () => {
    setSearchOpen && setSearchOpen(false);
  };

  useClickOutside({
    refs: [searchRef, ...(toggleRef ? [toggleRef] : [])],
    callback: closeDropdown,
  });

  if (!toggleRef?.current) {
    return <></>;
  }

  return (
    <div className={cn(styles[`wrapper`])}>
      <FocusTrap
        active={searchOpen}
        focusTrapOptions={{
          allowOutsideClick: true,
          fallbackFocus: `.${toggleRef?.current?.className}`,
        }}
      >
        <div
          id={`search-dropdown`}
          ref={searchRef}
          aria-hidden={!searchOpen}
          className={cn(styles[`main`], { [styles[`active`]]: searchOpen })}
        >
          <SiteSearchBox
            siteSearchPlaceholderText={siteSearchPlaceholderText}
            searchInputId={searchInputId}
            onSearchSubmit={closeDropdown}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        </div>
      </FocusTrap>
    </div>
  );
};

export default SearchDropdown;
