import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import cn from 'classnames';
import { useMemo } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useCoveoFieldConfig } from 'src/hooks/useCoveoFieldConfig';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import { BaseFacets } from 'components/BaseHelpers/BaseSearch/BaseFacets';
import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import Text from 'components/BaseHelpers/Text';

import JobResults from '../JobResults';

import styles from './OfficesJobSearch.module.scss';

export type OfficesJobSearchProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Offices.JobSearch;

const OfficesJobSearch = (props: OfficesJobSearchProps): JSX.Element => {
  const { moduleHeader, resultCountLabelFormat, searchResultsPageSize, noResultsText } =
    props.fields || {};
  const { careersEngine } = useEngineContext();
  const { facetFields } = useCoveoFieldConfig('jobs');

  const sortCriterion = useMemo(
    () =>
      ['jobtitle', 'joblocation'].map((fieldName) =>
        buildFieldSortCriterion(fieldName, SortOrder.Ascending)
      ),
    []
  );
  const facetConfig = {
    facetFields,
  };
  const resultConfig = {
    resultPageSize: searchResultsPageSize,
    sortCriterion,
    alwaysShow: true,
  };

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
      <ModuleHeader header={moduleHeader} />
      <BaseSearch engine={careersEngine} facetConfig={facetConfig} resultConfig={resultConfig}>
        <form
          className={cn(styles['offices-job-search'], 'spacer', 'container-10')}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <BaseFacets />
        </form>
        <div className={cn('container-10', 'spacer')}>
          <JobResults
            fallback={<></>}
            noResults={<Text field={noResultsText} />}
            statsTemplate={resultCountLabelFormat}
          />
        </div>
      </BaseSearch>
    </AnimateInContainer>
  );
};

export default OfficesJobSearch;
