import {
  DefaultEditFrameButton,
  EditFrame as JssEditFrame,
  EditFrameProps as JssEditFrameProps,
} from '@sitecore-jss/sitecore-jss-react';

export type EditFrameProps = Omit<JssEditFrameProps, 'dataSource'> & {
  /**
   * If this is a component, this is usually `rendering.dataSource`. If this is on a page/item, this is the `route.itemId`
   */
  itemId: string | undefined;

  /**
   * These are the fields that should appear in the edit frame. It is the display name of the field which can be found in the model.ts above the field (@field) in the comments
   * For example: `fields={['Image Position', 'Body Text', 'Image']}`
   */
  fields: Array<string>;
  children?: React.ReactNode;
};

/**
 *
 * For use in Experience Editor. This component will allow the user to load a modal with
 * the selected fields when they click on this component.
 * Sample usage: 
 *  <EditFrame
      itemId={itemId}
      fields={[
        'Level',
        'First Name',
        'Last Name',
        'Services',
      ]}
    >
      {children}
    </EditFrame>`
 */
const EditFrame = ({
  title = 'Edit the item fields',
  itemId,
  fields,
  children,
  ...otherProps
}: EditFrameProps): JSX.Element => {
  return (
    <JssEditFrame
      title={title}
      dataSource={{ itemId: itemId || '' }}
      buttons={[
        {
          ...DefaultEditFrameButton.edit,
          fields,
          icon: '/~/icon/Office/16x16/pencil.png',
        },
      ]}
      {...otherProps}
    >
      {children}
    </JssEditFrame>
  );
};

export default EditFrame;
