import cn from 'classnames';

import { useBaseSearch } from 'src/hooks/useBaseSearch';

import { BaseFacetSelect } from './BaseFacetSelect';

import styles from './BaseFacets.module.scss';

interface BaseFacetsProps {
  className?: string;
}

export const BaseFacets = ({ className }: BaseFacetsProps): JSX.Element => {
  const { state } = useBaseSearch();
  const { facets } = state;
  return (
    <ul className={cn(styles['facet-list'], className)}>
      {facets
        .filter((facet) => facet.label !== undefined)
        .map((facet) => {
          return (
            <li className={styles['facet-list-item']} key={facet.field}>
              <BaseFacetSelect facet={facet} label={facet.label} />
            </li>
          );
        })}
    </ul>
  );
};
