import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import { InsightsFlexibleFeatureItemProps } from './InsightsFlexibleFeatureItem';

export const useInsightsFlexibleFeatureItem = () => {
  const { isEE } = useExpEditor();

  const shouldRenderFlexibleFeatureItem = (fields: InsightsFlexibleFeatureItemProps['fields']) => {
    const hasContent =
      (hasValue(fields?.source?.image) || hasValue(fields?.image)) &&
      (hasValue(fields?.source?.headline) || hasValue(fields?.headline));
    return isEE || hasContent;
  };

  return {
    shouldRenderFlexibleFeatureItem,
  };
};
