import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import HorizontalImageSplitHero from 'components/BaseHelpers/HorizontalImageSplitHero';
import RichText from 'components/BaseHelpers/RichText';

import styles from './ServicesServiceHeader.module.scss';

const ServicesServiceHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { hasValueOrIsEE } = useExpEditor();

  const routeFields =
    getRouteFields<NonNullable<Website.Project.Main.PageTypes.Services.Service['fields']>>(
      sitecoreContext
    );
  const { image1, image2, subtitle, name, summary } = routeFields;
  const fields = { image1, image2, headline: name, description: subtitle };
  return (
    <>
      <HorizontalImageSplitHero fields={fields} />
      {hasValueOrIsEE(summary) && (
        <AnimateInContainer className={cn('container-8', 'spacer')}>
          <RichText field={summary} className={styles[`summary`]} />
        </AnimateInContainer>
      )}
    </>
  );
};

export default ServicesServiceHeader;
