import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import { Placeholder, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useMemo } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useCoveoFieldConfig } from 'src/hooks/useCoveoFieldConfig';

import { encodeFieldName } from 'src/utils/coveo';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import { BaseFacets } from 'components/BaseHelpers/BaseSearch/BaseFacets';
import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';

import { NameSearchBox } from './NameSearchBox';
import { PeopleResults } from './PeopleResults';
import { PeopleSearchAlphaFilters } from './PeopleSearchAlphaFilters';

import styles from './PeopleSearch.module.scss';

export type PeopleSearchProps = ComponentProps & Website.Project.Main.ComponentTypes.People.Search;

const PeopleSearch = (props: PeopleSearchProps): JSX.Element => {
  const {
    searchNamePlaceholderText,
    resultCountLabelFormat,
    didYouMeanLabelFormat,
    searchResultsPageSize,
    typeaheadResultsPageSize,
    noResultsText,
  } = props.fields || {};
  const { sitecoreContext } = useSitecoreContext();
  const { peopleEngine } = useEngineContext();
  const { facetFields } = useCoveoFieldConfig('people');
  const sortCriterion = useMemo(
    () => buildFieldSortCriterion(encodeFieldName('alphasort'), SortOrder.Ascending),
    []
  );

  const facetConfig = { facetFields };
  const resultConfig = {
    resultPageSize: searchResultsPageSize,
    suggestionPageSize: typeaheadResultsPageSize,
    sortCriterion,
  };

  return (
    <BaseSearch engine={peopleEngine} facetConfig={facetConfig} resultConfig={resultConfig}>
      <form
        className={cn(styles['people-search-form-container'], 'spacer', 'container-12')}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <NameSearchBox
          className={styles['name-search-box']}
          placeholder={searchNamePlaceholderText}
          typeaheadPageSize={typeaheadResultsPageSize}
        />
        <PeopleSearchAlphaFilters />
        <BaseFacets />
      </form>
      <PeopleResults
        correctionTemplate={didYouMeanLabelFormat}
        statsTemplate={resultCountLabelFormat}
        noResultsText={noResultsText}
        fallback={<Placeholder name={'content'} rendering={sitecoreContext?.route as RouteData} />}
      />
    </BaseSearch>
  );
};

export default PeopleSearch;
