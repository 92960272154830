import { useRouter } from 'next/router';

export const usePdf = () => {
  const router = useRouter();
  const isPdf = router.route.startsWith('/[site]/pdf/');

  return {
    isPdf,
  };
};
