import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { useDictionary } from 'src/hooks/useDictionary';

import { PeopleInfo, getName } from 'src/utils/getName';
import { hasItems } from 'src/utils/hasItems';
import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './InsightsAgendaItem.module.scss';

export type InsightsAgendaItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.AgendaItem;

const InsightsAgendaItem = (props: InsightsAgendaItemProps): JSX.Element => {
  const { dateandTime, description, linktoSlides, title, professionals, linkLabel } =
    props.fields || {};
  const { sitecoreContext } = useSitecoreContext();
  const { t } = useDictionary();

  // if there is nothing in fields, skip item - no divider
  if (
    !hasValue(dateandTime) &&
    !hasValue(description) &&
    !hasValue(title) &&
    !hasValue(linktoSlides) &&
    !professionals?.length
  ) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <div className={styles['time-container']}>
        <Text className={styles['time']} field={dateandTime} />
      </div>
      <div className={styles['content-container']}>
        <Text tag="h3" className={styles['title']} field={title} />
        <RichText className={styles['description']} field={description} />
        {hasValue(linktoSlides) && hasValue(linkLabel) && (
          <div className={styles['link-container']}>
            <Text className={styles['link-title']} tag="h4" field={{ value: t('Link') }} />
            <Link className={styles['link']} field={linktoSlides}>
              <Text field={linkLabel} />
            </Link>
          </div>
        )}
        {hasItems(professionals) && (
          <div className={styles['speakers-container']}>
            <Text className={styles['speakers-title']} tag="h4" field={{ value: t('Speakers') }} />
            <ul>
              {professionals?.map((professional) => {
                const fields: PeopleInfo = professional.fields;
                const name = getName(fields, sitecoreContext?.language);
                const title = hasValue(fields?.title) ? fields?.title : fields?.level?.fields?.name;
                const url = professional?.url;
                return (
                  <li key={professional.id}>
                    <LinkOrComponent field={url} className={styles[`link`]}>
                      <Text tag="span" field={name} />
                    </LinkOrComponent>
                    {hasValue(title) && (
                      <>
                        {', '}
                        <Text tag="span" field={title} />
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsAgendaItem;
