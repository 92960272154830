import { useMemo } from 'react';

import { COVEO_FIELDS, COVEO_FIELDS_TC } from 'src/types/coveo';

import { useSite } from './useSite';

export const useCoveoFields = () => {
  const { isTCSite } = useSite();

  const fields = useMemo(() => {
    const variableFields = {
      services: isTCSite ? COVEO_FIELDS_TC.services : COVEO_FIELDS.services,
      industries: isTCSite ? COVEO_FIELDS_TC.industries : COVEO_FIELDS.industries,
      offices: isTCSite ? COVEO_FIELDS_TC.offices : COVEO_FIELDS.offices,
      officeslinks: isTCSite ? COVEO_FIELDS_TC.officeslinks : COVEO_FIELDS.officeslinks,
    };

    return {
      ...COVEO_FIELDS,
      ...variableFields,
    } as const;
  }, [isTCSite]);

  return fields;
};

export type CoveoFields = ReturnType<typeof useCoveoFields>;
