import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ViewAllLink } from 'src/types/viewAllLink';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BaseHighlights from 'components/BaseHelpers/BaseHighlights';
import BoxCta from 'components/BaseHelpers/BoxCta';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import Text from 'components/BaseHelpers/Text';

import styles from './OfficesRelatedJobs.module.scss';

type Job = {
  title?: string;
  city?: string;
  link?: string;
};

export type OfficesRelatedJobsProps = ComponentProps & {
  fields: {
    relatedJobs?: Job[];
    moduleHeader?: Field<string>;
    viewAllLink?: ViewAllLink;
  };
};

const OfficesRelatedJobs = (props: OfficesRelatedJobsProps): JSX.Element => {
  const { relatedJobs, moduleHeader, viewAllLink } = props?.fields || {};
  const { hasItemsOrIsEE } = useExpEditor();

  if (!hasItemsOrIsEE(relatedJobs)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-12')}>
      <ModuleHeader className={styles[`header`]} header={moduleHeader} variant="double-line" />
      <BaseHighlights>
        {relatedJobs?.map(({ title, city, link }, index) => {
          return (
            <LinkOrComponent key={`job-${index}`} tag="a" field={link} className={styles[`job`]}>
              <Text className={styles[`title`]} field={{ value: title }} editable={false}></Text>
              <Text className={styles[`city`]} field={{ value: city }} editable={false}></Text>
            </LinkOrComponent>
          );
        })}
      </BaseHighlights>
      {viewAllLink && (
        <div className={styles[`view-all`]}>
          <BoxCta tag="a" field={viewAllLink?.url}>
            {viewAllLink?.text}
          </BoxCta>
        </div>
      )}
    </AnimateInContainer>
  );
};

export default OfficesRelatedJobs;
