export const getEnglishLangName = (hrefLang: string) => {
  if (!(hrefLang in ENGLISH_NAME)) return '';
  return ENGLISH_NAME[hrefLang as keyof typeof ENGLISH_NAME];
};

const ENGLISH_NAME = {
  'x-default': 'English',
  en: 'English',
  de: 'German',
  fr: 'French',
  pt: 'Portuguese',
  'zh-Hans': 'Chinese',
  es: 'Spanish',
  ja: 'Japanese',
};
