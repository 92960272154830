import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useDictionary } from 'src/hooks/useDictionary';

import styles from './BaseResultsHeader.module.scss';

export interface BaseResultsHeaderProps {
  className?: string;
}

export const BaseResultsHeader = ({ className }: BaseResultsHeaderProps) => {
  const { controller, state } = useBaseSearch();
  const { hasQuery, showResults, sortCriterionType } = state;
  const { updateSort } = controller;
  const { t } = useDictionary();
  const [selectedSort, setSelectedSort] = useState<string | undefined>('relevancy');

  useEffect(() => {
    if (!sortCriterionType) return;
    if (Array.isArray(sortCriterionType)) {
      setSelectedSort(undefined);
    } else {
      setSelectedSort(sortCriterionType?.by);
    }
  }, [sortCriterionType]);

  const handleSortChange = (sortCriteria: string) => {
    if (sortCriteria === selectedSort) return;
    updateSort(sortCriteria, true);
    setSelectedSort(sortCriteria);
    console.log('handleSortChange ', sortCriteria);
  };

  if (!showResults && !hasQuery) return <></>;
  return (
    <div className={styles['results-header']}>
      <div className={styles['sort-container']}>{t('SORT BY: ')}</div>
      <div className={styles['sort-container']}>
        <button
          className={cn(styles['sort'], className, {
            [styles['selected']]: selectedSort === 'relevancy',
          })}
          onClick={() => handleSortChange('relevancy')}
        >
          {t('RELEVANCY')}
        </button>
      </div>
      <div className={styles['sort-container']}>
        <button
          className={cn(styles['sort'], className, {
            [styles['selected']]: selectedSort !== 'relevancy',
          })}
          onClick={() => handleSortChange('descending')}
        >
          {t('DATE')}
        </button>
      </div>
    </div>
  );
};

export default BaseResultsHeader;
