import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';

import { getEnglishLangName } from 'src/utils/getEnglishLangName';
import { hasItems } from 'src/utils/hasItems';

import Chevron from '../../../assets/icons/chevron.svg';
import LinkOrComponent from '../LinkOrComponent/LinkOrComponent';

import styles from './Languages.module.scss';

export type Lang = {
  url: string;
  hrefLang: string;
};

export type LanguagesProps = {
  langs?: Lang[];
};

const Languages = ({ langs }: LanguagesProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [langOpen, setLangOpen] = useState<boolean>(false);

  const { t } = useDictionary();

  const getLang = (hrefLang: string) => (hrefLang === 'x-default' ? 'en' : hrefLang);

  const getDisplayLang = (lang: string) => t(getLang(lang));

  const shouldDisplayLangs = (langs: Array<Lang> | undefined) => {
    if (!langs) return false;
    return (
      // if theres more than one option OR current language is not the option
      langs.length > 1 ||
      (langs.length === 1 && sitecoreContext?.language !== getDisplayLang(langs[0].hrefLang))
    );
  };

  useEffect(() => {
    const buttonHeight = buttonRef.current?.offsetHeight;
    document.documentElement.style.setProperty('--Languages__button-height', `${buttonHeight}px`);
  });

  if (!hasItems(langs)) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      {langs && langs?.length > 1 ? (
        <button
          ref={buttonRef}
          className={styles[`toggle`]}
          onClick={() => {
            setLangOpen(!langOpen);
          }}
          aria-expanded={langOpen}
          aria-controls={`languages`}
        >
          {t('Translate')}
          <Chevron className={cn(styles[`arrow`], { [styles[`arrow--active`]]: langOpen })} />
        </button>
      ) : (
        <div className={styles.divider}></div>
      )}

      <div
        id="languages"
        className={cn(styles[`list-wrapper`], {
          [styles[`active`]]: langOpen,
        })}
      >
        <ul className={styles[`list`]}>
          {shouldDisplayLangs(langs) &&
            langs?.map(({ url, hrefLang }: Lang, index: number) => {
              const displayLang = getDisplayLang(hrefLang);
              const notCurrentLang = sitecoreContext?.language !== getLang(hrefLang);
              return (
                <li key={`detail-language-${index}`} className={styles[`lang`]}>
                  <LinkOrComponent
                    className={cn(styles[`lang-link`])}
                    field={notCurrentLang ? url : undefined}
                    aria-label={
                      notCurrentLang
                        ? `Switch to ${getEnglishLangName(hrefLang)} - ${displayLang}`
                        : undefined
                    }
                  >
                    {displayLang}
                  </LinkOrComponent>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Languages;
