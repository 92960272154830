import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';

import styles from './BasicContentMediaGrid.module.scss';

export type BasicContentMediaGridProps = ComponentProps;

const BasicContentMediaGrid = (props: BasicContentMediaGridProps): JSX.Element => {
  const { isEE } = useExpEditor();

  if (!hasItems(props.rendering.placeholders?.['media-grid']) && !isEE) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles['main'], 'container-10', 'spacer')}>
      <Placeholder name="media-grid" rendering={props.rendering} />
    </AnimateInContainer>
  );
};

export default BasicContentMediaGrid;
