import Head from 'next/head';

import { ComponentProps } from 'lib/component-props';

type RobotsMetadataProps = ComponentProps & {
  fields: {
    robotsMeta: string; // Reflecting modification in Website.Feature.Robots.Resolvers.MetadataResolver
  };
};

const RobotsMetadata = (props: RobotsMetadataProps): JSX.Element => (
  <>
    {props.fields && (
      <Head>
        <meta name="robots" content={props.fields.robotsMeta} />
      </Head>
    )}
  </>
);

export default RobotsMetadata;
