import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BaseHighlights from 'components/BaseHelpers/BaseHighlights';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './BasicContentHighlights.module.scss';

export type BasicContentHighlightsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Highlights;

const BasicContentHighlights = ({ fields = {} }: BasicContentHighlightsProps): JSX.Element => {
  const { hasValueOrIsEE, hasItemsOrIsEE } = useExpEditor();
  const { highlights, moduleHeader } = fields;
  const filteredHighlights = highlights?.filter(({ fields }) => hasValueOrIsEE(fields?.title));

  if (!hasItemsOrIsEE(filteredHighlights)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-12', 'spacer')}>
      <ModuleHeader className={styles[`header`]} header={moduleHeader} variant="double-line" />
      <BaseHighlights>
        {filteredHighlights?.map(({ fields = {}, id }) => {
          const { eyebrow, title, linkText, link } = fields;
          return (
            <LinkOrComponent
              key={`highlight-${id}`}
              tag="a"
              field={link}
              className={styles[`highlight`]}
            >
              <Text field={eyebrow} className={styles[`eyebrow`]} />
              <Text className={styles[`title`]} field={title}></Text>
              {hasValueOrIsEE(linkText) && hasValueOrIsEE(link) && (
                <UnderlineCta className={styles[`link`]} tag="span">
                  <Text field={linkText} />
                </UnderlineCta>
              )}
            </LinkOrComponent>
          );
        })}
      </BaseHighlights>
    </AnimateInContainer>
  );
};

export default BasicContentHighlights;
