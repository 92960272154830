import { Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { getRouteField } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseContacts from 'components/BaseHelpers/BaseContacts';

type Link = {
  text: string;
  url: string;
};

export type PeopleContactsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Contacts & {
    fields?: Website.Project.Main.ComponentTypes.People.Contacts['fields'] & {
      viewAllLink?: Link;
      professionals?: Item[] | undefined;
    };
  };

const PeopleContacts = (props: PeopleContactsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const profs =
    props?.fields?.professionals || getRouteField<Array<Item>>('professionals', sitecoreContext);

  return <BaseContacts {...props} contacts={profs} />;
};

export default PeopleContacts;
