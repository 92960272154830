import cn from 'classnames';
import get from 'lodash/get';
import set from 'lodash/set';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useSite } from 'src/hooks/useSite';

import { createLinkObject } from 'src/utils/sitecoreFieldHelpers';

import { ViewAllLink } from 'src/types/viewAllLink';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import BaseContactsCard from './BaseContactsCard';

import styles from './BaseContacts.module.scss';

type Professional = Website.Project.Main.PageTypes.People.Professional;

export type BaseContactsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Contacts & {
    fields?: Website.Project.Main.ComponentTypes.People.Contacts['fields'] & {
      viewAllLink?: ViewAllLink;
    };
    contacts: Array<Professional> | undefined;
  };

export type Section = {
  section: string;
  list: Array<Professional>;
};

const BaseContacts = (props: BaseContactsProps): JSX.Element => {
  const { contacts, fields } = props;
  const { groupBy, hideAllImages, moduleHeader, viewAllLink } = fields || {};
  const { hasItemsOrIsEE } = useExpEditor();
  const { isTCSite } = useSite();

  if (!hasItemsOrIsEE(contacts)) return <></>;

  const groupByValue = groupBy?.fields?.name?.value;
  let sortedProfessionals: Array<Section> = [];
  const alphaProfessionals = [...(contacts || [])].sort(
    (
      a: Website.Project.Main.PageTypes.People.Professional,
      b: Website.Project.Main.PageTypes.People.Professional
    ) => {
      const textA = `${a?.fields?.lastName?.value?.toUpperCase()} ${a?.fields?.firstName?.value?.toUpperCase()} ${a?.fields?.middleInitial?.value?.toUpperCase()}`;
      const textB = `${b?.fields?.lastName?.value?.toUpperCase()} ${b?.fields?.firstName?.value?.toUpperCase()} ${b?.fields?.middleInitial?.value?.toUpperCase()}`;
      if (!textA || !textB) {
        return -1;
      }
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }
  );

  if (groupByValue === 'Region') {
    sortedProfessionals = sortIntoSections(
      alphaProfessionals,
      'fields.regions[0].fields.name.value',
      'fields.regions[0].fields.sortOrder.value'
    );
  } else if (groupByValue === 'Service') {
    sortedProfessionals = sortIntoSections(
      alphaProfessionals,
      isTCSite ? 'fields.servicesTC[0].fields.name.value' : 'fields.services[0].fields.name.value'
    );
  }

  const linkObject = createLinkObject(viewAllLink?.url);

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={moduleHeader} />
      {!groupByValue ? (
        <ul
          className={cn(styles[`contact-cards`], { [styles['hide-images']]: hideAllImages?.value })}
        >
          {alphaProfessionals?.map((contact) => {
            return (
              <li key={`professional-contact-${contact?.id}`} className={styles[`contact-card`]}>
                <BaseContactsCard fields={contact} hideImage={hideAllImages} />
              </li>
            );
          })}
        </ul>
      ) : (
        sortedProfessionals.length >= 1 &&
        sortedProfessionals.map(({ section, list }) => {
          return (
            <div key={`section-${section}`} className={styles[`category`]}>
              <ModuleHeader variant="double-line" header={{ value: section }} />
              <ul className={styles[`contact-cards`]}>
                {list.map((contact) => {
                  return (
                    <li key={`${section}-${contact?.id}`} className={styles[`contact-card`]}>
                      <BaseContactsCard fields={contact} hideImage={hideAllImages} />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })
      )}
      {viewAllLink && (
        <div className={styles[`footer`]}>
          <BoxCta tag="a" field={linkObject}>
            {viewAllLink?.text}
          </BoxCta>
        </div>
      )}
    </AnimateInContainer>
  );
};

function sortIntoSections(
  data: Website.Project.Main.PageTypes.People.Professional[],
  property: string,
  sortProperty?: string
) {
  const grouped: { [key: string]: Array<Professional> } = {};
  const groupSortMap: { [key: string]: string | number } = {};

  for (const item of data) {
    const propValue = get(item, property);
    if (propValue) {
      if (!get(grouped, propValue)) {
        set(grouped, propValue, []);
        // Try to sort by `sortProperty`, but fall back to sorting by `property`
        set(
          groupSortMap,
          propValue,
          sortProperty !== undefined ? get(item, sortProperty, propValue) : propValue
        );
      }
      get(grouped, propValue).push(item);
    }
  }

  return Object.keys(grouped)
    .sort((a, b) => {
      // Generic sort
      if (groupSortMap[a] < groupSortMap[b]) return -1;
      if (groupSortMap[a] > groupSortMap[b]) return 1;
      return 0;
    })
    .map((section) => ({
      section,
      list: grouped[section],
    }));
}

export default BaseContacts;
