import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import TabItem from './TabItem';

export type BasicContentTabItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.TabItem;

const BasicContentTabItem = ({ fields }: BasicContentTabItemProps): JSX.Element => {
  return <TabItem fields={fields} />;
};

export default BasicContentTabItem;
