import { Website } from 'lib/component-props/model';

export type PeopleInfo = Website.Project.Main.PageTypes.People.Professional['fields'];

export const getName = (fields: PeopleInfo = {}, language: string | undefined, isEE?: boolean) => {
  const { prefix, firstName, middleInitial, nickName, lastName, suffix, chineseName } = fields;
  const englishName = [
    prefix?.value,
    firstName?.value,
    middleInitial?.value,
    nickName?.value && `(${nickName.value})`,
    lastName?.value,
    suffix?.value,
  ]
    .filter(Boolean)
    .join(' ');

  if (language?.toLowerCase() === 'zh-hans' && chineseName?.value) {
    return chineseName;
  }

  const fallback = () => (isEE ? { value: 'Name: [No data in field]' } : { value: '' });

  return englishName ? { value: englishName } : fallback();
};
