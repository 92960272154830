import cn from 'classnames';
import { useRef } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import PlayButton from 'components/BaseHelpers/PlayButton';
import Text from 'components/BaseHelpers/Text';
import VideoModal, { VideoModalRef } from 'components/BaseHelpers/VideoModal';

import styles from './BasicContentVideoWithBanner.module.scss';

export type BasicContentVideoWithBannerProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.VideoWithBanner;

const BasicContentVideoWithBanner = (props: BasicContentVideoWithBannerProps): JSX.Element => {
  const { ctaLink, headline, image, video } = props.fields || {};
  const { hasValueOrIsEE } = useExpEditor();
  const { t } = useDictionary();

  const modalRef = useRef<VideoModalRef>(null);
  const launchButtonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    modalRef.current?.openModal({ launchElement: launchButtonRef.current });
  };

  if (!hasValueOrIsEE(video) || !hasValueOrIsEE(headline) || !hasValueOrIsEE(image)) return <></>;

  const headlineID = `VideoHeadline_${props.rendering.uid}`;
  const ctaID = `VideoCtaButton_${props.rendering.uid}`;

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <div className={styles['container']}>
        <Image className={styles['image']} fill={true} field={image} alt="" />
        <div className={styles['content-container']} onClick={handleClick}>
          <Text tag="h2" field={headline} className={styles['headline']} id={headlineID} />
          <PlayButton onClick={handleClick} label={headline} ref={launchButtonRef} />
          {hasValueOrIsEE(ctaLink) && (
            <BoxCta
              className={styles['cta-button']}
              id={ctaID}
              tag="a"
              field={ctaLink}
              aria-labelledby={`${ctaID} ${headlineID}`}
            >
              {ctaLink?.value?.text || t('Read More')}
            </BoxCta>
          )}
        </div>
        <VideoModal ref={modalRef} video={video} />
      </div>
    </AnimateInContainer>
  );
};

export default BasicContentVideoWithBanner;
