import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import { BasicContentComplexStatProps } from '../BasicContentComplexStat/BasicContentComplexStat';

import styles from './BasicContentComplexStatStack.module.scss';

export type BasicContentComplexStatStackProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.ComplexStatStack;

type Stat = Website.Project.Main.ComponentTypes.BasicContent.ComplexStat;

const BasicContentComplexStatStack = ({
  fields = {},
  rendering,
}: BasicContentComplexStatStackProps): JSX.Element => {
  const { hasItemsOrIsEE, isEE } = useExpEditor();

  const shouldRenderStat = (component: unknown) => {
    const typedComponent = component as { props: BasicContentComplexStatProps };
    const { dataPointDescription, dataPointText } = typedComponent?.props?.fields || {};
    return hasValue(dataPointDescription) && hasValue(dataPointText);
  };

  const items = (rendering.placeholders?.['complex-stat-stack'] as Array<Stat> | undefined)?.filter(
    (item) => shouldRenderStat({ props: item })
  );
  if (!hasItemsOrIsEE(items)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={fields.moduleHeader} />
      <ul className={cn(styles.stats)}>
        <Placeholder
          rendering={rendering}
          name="complex-stat-stack"
          renderEach={(component, index) => {
            if (!shouldRenderStat(component) && !isEE) {
              return null;
            }
            return (
              <li key={`complex-stat-${index}`} className={cn(styles.stat)}>
                {component}
              </li>
            );
          }}
        />
      </ul>
    </AnimateInContainer>
  );
};

export default BasicContentComplexStatStack;
