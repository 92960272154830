import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useMemo } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useCoveoFieldConfig } from 'src/hooks/useCoveoFieldConfig';

import { COVEO_FIELDS } from 'src/types/coveo';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import { BaseFacets } from 'components/BaseHelpers/BaseSearch/BaseFacets';
import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';
import { BaseSearchBox } from 'components/BaseHelpers/BaseSearch/BaseSearchBox';

import { InsightsNewsResults } from './InsightsNewsResults';

import styles from './InsightsNewsSearch.module.scss';

export type InsightsNewsSearchProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.NewsSearch & {
    fields?: {
      latestNews?: Array<FeaturedNews>;
    };
  };

interface FeaturedNews {
  id: string;
  url: string;
  image: ImageFieldValue;
  headline: string;
  bodyText: string;
}

const InsightsNewsSearch = (props: InsightsNewsSearchProps): JSX.Element => {
  const {
    didYouMeanLabelFormat,
    searchKeywordPlaceholderText,
    resultCountLabelFormat,
    searchResultsPageSize,
    typeaheadResultsPageSize,
    noResultsText,
    latestNews = [],
  } = props.fields || {};
  const { newsEngine } = useEngineContext();
  const { facetFields } = useCoveoFieldConfig('insights');
  const sortCriterion = useMemo(
    () => buildFieldSortCriterion(COVEO_FIELDS.dateRange, SortOrder.Descending),
    []
  );
  const advancedQuery = useMemo(() => {
    return `NOT @permanentid==(${latestNews.map((news) => news.id).join(',')})`;
  }, [latestNews]);

  const facetConfig = { facetFields };
  const resultConfig = {
    resultPageSize: searchResultsPageSize,
    suggestionPageSize: typeaheadResultsPageSize,
    sortCriterion,
    alwaysShow: true,
  };

  return (
    <BaseSearch
      engine={newsEngine}
      facetConfig={facetConfig}
      resultConfig={resultConfig}
      queryConfig={{ advancedQuery }}
    >
      <form
        className={cn(styles['form-container'], 'container-12')}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <BaseSearchBox
          className={styles['search-box']}
          placeholder={searchKeywordPlaceholderText}
          showSuggestions={false}
        />
        <BaseFacets />
      </form>
      <InsightsNewsResults
        correctionTemplate={didYouMeanLabelFormat}
        statsTemplate={resultCountLabelFormat}
        noResultsText={noResultsText}
        latestNews={latestNews}
      />
    </BaseSearch>
  );
};

export default InsightsNewsSearch;
