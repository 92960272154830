import { DateFacetState, DateFacetValue, FacetState, FacetValue } from '@coveo/headless';
import cn from 'classnames';
import { useMemo } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { CoveoFacet } from 'src/hooks/useCoveoFacet';
import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useFacetLabels } from 'src/hooks/useFacetLabels';

import { COVEO_FIELDS, FacetValues } from 'src/types/coveo';

import Chevron from '../../../assets/icons/facet-chevron.svg';

import styles from './BaseFacets.module.scss';

interface BaseFacetSelectProps {
  label: string;
  facet: CoveoFacet;
}

export const BaseFacetSelect = ({ facet, label }: BaseFacetSelectProps) => {
  const { controller, state } = useBaseSearch();
  const { query } = state;
  const { selectSingleFacetValue } = controller;
  const { isEE } = useExpEditor();
  const FACET_LABELS = useFacetLabels();
  const { t } = useDictionary();
  const isTopicFacet = useMemo(() => {
    return facet.field === COVEO_FIELDS.topics;
  }, [facet.field]);
  const values: Array<FacetValues> | undefined = useMemo(() => {
    // we have to handle `topics` differently because it isn't actually a facet -- selecting one executes a new search with the `topic` as the query
    return isTopicFacet
      ? facet.topics?.map((value) => ({
          state: 'idle',
          value: value,
          numberOfResults: 1,
        }))
      : facet.state?.values;
  }, [facet.topics, facet.state?.values, isTopicFacet]);

  const getValueLabel = (item: FacetValue | DateFacetValue) => {
    return 'value' in item ? item.value : FACET_LABELS[item.start as keyof typeof FACET_LABELS];
  };

  const getStartValue = (value: string, facetState: DateFacetState | undefined) => {
    if (!facetState) return;
    return Object.keys(FACET_LABELS).find(
      (option: keyof typeof FACET_LABELS) => FACET_LABELS[option] === value
    );
  };

  const getValue = (facetState: FacetState | DateFacetState | undefined) => {
    if (!facetState || !facetState.hasActiveValues) return '';
    const values: Array<FacetValue | DateFacetValue> = facetState.values;
    const activeValue = isTopicFacet
      ? values.find(
          (value) => 'value' in value && value.value.toLowerCase() === query.toLowerCase()
        )
      : values.find((value) => value.state === 'selected');
    if (!activeValue) return '';
    return getValueLabel(activeValue);
  };

  if (isEE) {
    return (
      <div className={styles['facet-select-label']}>
        <span className={styles['facet-select-name']}>{label}</span>
        <span className={styles['facet-icon']}>
          <Chevron />
        </span>
      </div>
    );
  }

  const handleChange = (value: string) => {
    const facetValue = values?.find((f) =>
      'value' in f
        ? f.value === value
        : f.start === getStartValue(value, facet.state as DateFacetState)
    );
    if (!facetValue) return;

    selectSingleFacetValue(facet, facetValue);
  };

  const disabled =
    !values ||
    values.length === 0 ||
    values.every((item) => 'value' in item && facet.exclude?.includes(item?.value?.toLowerCase()));

  return (
    <label className={cn(styles['facet-select-label'], { [styles['disabled-facet']]: disabled })}>
      <span className={styles['facet-select-name']}>{label}</span>
      <span className={styles['facet-icon']}>
        <Chevron />
      </span>
      <select
        className={styles['facet-select']}
        onChange={(e) => {
          e.preventDefault();
          handleChange(e.currentTarget.value);
        }}
        disabled={disabled}
        value={getValue(facet.state)}
      >
        <option value="" disabled>
          {label}
        </option>
        {values?.map((item) => {
          if (
            // for some reason, `coveo` doesn't exclude `DateFacet` values with `0` results
            item.numberOfResults === 0 ||
            ('value' in item && facet.exclude?.includes(item.value.toLowerCase()))
          ) {
            return null;
          }

          const valueLabel = getValueLabel(item);
          const numberOfResults = isTopicFacet ? '' : ` (${item.numberOfResults})`;

          return (
            <option key={valueLabel} value={valueLabel} aria-disabled={false}>
              {`${t(valueLabel)}${numberOfResults}`}
            </option>
          );
        })}
      </select>
    </label>
  );
};
