import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentFullWidthImage.module.scss';

export type BasicContentFullWidthImageProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Image;

const BasicContentFullWidthImage = (props: BasicContentFullWidthImageProps): JSX.Element => {
  const { image, caption } = props.fields || {};
  const captionId = `${props.rendering.uid}-FullWidthImage-caption`;

  const { hasValueOrIsEE } = useExpEditor();
  if (!hasValueOrIsEE(image)) {
    return <></>;
  }
  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <div className={cn(styles[`image`], 'exp-editor-img-container')}>
        <Image fill={true} field={image} aria-describedby={captionId} />
      </div>
      <Text className={styles[`caption`]} field={caption} id={captionId} />
    </AnimateInContainer>
  );
};

export default BasicContentFullWidthImage;
