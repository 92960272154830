import styles from './StartIndex.module.scss';

export type StartIndexProps = Record<string, never>;

const StartIndex = (): JSX.Element => {
  return (
    <div className={styles.main} dangerouslySetInnerHTML={{ __html: '<!-- startindex -->' }} />
  );
};

export default StartIndex;
