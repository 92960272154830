import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Text from 'components/BaseHelpers/Text';

import ServiceList from '../ServiceList';

import styles from './ServicesRegionalCapabilities.module.scss';

export type ServicesRegionalCapabilitiesProps = ComponentProps;

const ServicesRegionalCapabilities = (): JSX.Element => {
  const {
    sitecoreContext: { route },
  } = useSitecoreContext();
  const { hasItemsOrIsEE } = useExpEditor();
  const regions = route?.fields?.regions as Website.Project.Main.PageTypes.Services.Service[];
  const sectionTitle = route?.fields?.sectionTitle as Field<string>;
  if (!hasItemsOrIsEE(regions)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
      <Text tag="div" className={styles[`header`]} field={sectionTitle} />
      <ServiceList className={styles[`list`]} list={regions} variant="columns" />
    </AnimateInContainer>
  );
};

export default ServicesRegionalCapabilities;
