// https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from 'src/pages/_error';

import { updateError } from 'src/utils/errorHelpers';

import { logger } from 'lib/logger';

export interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.error(
      updateError(error, {
        message: `ErrorBoundary.tsx`,
        stack: `Component Stack: ${errorInfo.componentStack}`,
      })
    );
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
