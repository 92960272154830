import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import PeopleDetailHeroLeft, {
  PeopleDetailHeroLeftProps,
} from 'components/People/PeopleDetailHeroLeft';

import styles from './PeopleDetailTemplate.module.scss';

export type PeopleDetailTemplateProps = {
  route: RouteData;
};

const PeopleDetailTemplate = ({ route }: PeopleDetailTemplateProps): JSX.Element => {
  const shareTrayComponentName = 'BasicContentShareTray';

  const getRenderingWithShareTrayOnly = () => {
    const placeholderWithShareTrayOnly = route.placeholders['page-header']?.filter(
      (component) =>
        'componentName' in component && component.componentName === shareTrayComponentName
    );
    const routeClone = structuredClone(route);
    routeClone.placeholders['page-header'] = placeholderWithShareTrayOnly;
    return routeClone;
  };

  const getRenderingWithoutShareTray = () => {
    const placeholderWithShareTrayOnly = route.placeholders['page-header']?.filter(
      (component) =>
        'componentName' in component && component.componentName !== shareTrayComponentName
    );
    const routeClone = structuredClone(route);
    routeClone.placeholders['page-header'] = placeholderWithShareTrayOnly;
    return routeClone;
  };

  return (
    <div className={styles.main}>
      <Placeholder name="head" rendering={route} />
      <header>
        <Placeholder name="header" rendering={route} />
      </header>
      <main className={styles.content} id="main">
        <div className={cn(styles.grid, 'container-12')}>
          <div className={styles['left-column']}>
            <PeopleDetailHeroLeft
              {...route}
              fields={route.fields as PeopleDetailHeroLeftProps['fields']}
            />
          </div>
          <div className={styles['right-column']}>
            <Placeholder name="page-header" rendering={getRenderingWithoutShareTray()} />
            <Placeholder name="content" rendering={route} />
            <div className={styles['share-tray']}>
              <Placeholder name="page-header" rendering={getRenderingWithShareTrayOnly()} />
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Placeholder name="footer" rendering={route} />
      </footer>
    </div>
  );
};

export default PeopleDetailTemplate;
