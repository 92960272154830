import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';

import styles from './SearchTemplate.module.scss';

export type SearchTemplateProps = {
  route: RouteData;
};

const SearchTemplate = ({ route }: SearchTemplateProps): JSX.Element => {
  return (
    <div className={styles.main}>
      <Placeholder name="head" rendering={route} />
      <header>
        <Placeholder name="header" rendering={route} />
      </header>
      <main className={styles.content} id="main">
        <Placeholder name="page-header" rendering={route} />
        <Placeholder name="page-footer" rendering={route} />
      </main>
      <footer>
        <Placeholder name="footer" rendering={route} />
      </footer>
    </div>
  );
};
export default SearchTemplate;
