import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { Website } from 'lib/component-props/model';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './TabItem.module.scss';

export type TabItemProps = Website.Project.Main.ComponentTypes.BasicContent.TabItem;

const TabItem = ({ fields = {} }: TabItemProps): JSX.Element => {
  const { isEE } = useExpEditor();
  const { t } = useDictionary();
  const { link, text, title } = fields;
  return (
    <div className={cn(styles[`main`], 'container-8')}>
      {isEE && <Text field={title} />}
      <RichText className={cn(styles[`text`], 'color-contrast-ignore')} field={text} />
      <UnderlineCta tag="a" className={styles[`link`]} field={link}>
        {t('Learn More')}
      </UnderlineCta>
    </div>
  );
};

export default TabItem;
