import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { ForwardedRef, MouseEventHandler, forwardRef } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';

import PlayIcon from '../../../assets/icons/play-icon__light.svg';

import styles from './PlayButton.module.scss';

export type PlayButtonProps = {
  ref: ForwardedRef<HTMLButtonElement>;
  onClick: MouseEventHandler;
  variant?: 'light' | 'dark';
  label: Field<string> | undefined;
  className?: string;
};

const PlayButton = forwardRef<HTMLButtonElement, PlayButtonProps>(
  function PlayButtonComponent(props, ref): JSX.Element {
    const { t } = useDictionary();
    const { variant = 'dark', className, onClick, label, ...restProps } = props;
    return (
      <button
        {...restProps}
        className={cn(styles['play-button'], styles[variant], className)}
        onClick={onClick}
        ref={ref}
        aria-label={`${t('Play video')} - ${label?.value || ''}`}
      >
        <PlayIcon />
      </button>
    );
  }
);

export default PlayButton;
