import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Image from 'components/BaseHelpers/Image';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentImage.module.scss';

export type BasicContentImageProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Image & { isMediaGrid?: boolean };

const BasicContentImage = (props: BasicContentImageProps): JSX.Element => {
  const { caption, image } = props.fields || {};

  const { hasValueOrIsEE } = useExpEditor();

  if (!hasValueOrIsEE(image)) {
    return <></>;
  }

  return (
    <div
      className={cn(styles.main, {
        [styles['media-grid-item']]: props.isMediaGrid,
        spacer: !props.isMediaGrid,
        'container-10': !props.isMediaGrid,
      })}
    >
      <div className={cn(styles['image-container'], 'exp-editor-img-container')}>
        <Image className={styles['image']} fill={true} field={image} />
      </div>
      {!props.isMediaGrid && <Text className={styles['caption']} tag="span" field={caption} />}
    </div>
  );
};

export default BasicContentImage;
