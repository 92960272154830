import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useSite } from 'src/hooks/useSite';
import { useTemplate } from 'src/hooks/useTemplate';

import { decodeResultFields, handleManualViewAll, relativeURL } from 'src/utils/coveo';
import { hasValue } from 'src/utils/hasValue';
import { stringFieldToNumberValue } from 'src/utils/sitecoreFieldHelpers';

import { COVEO_FIELDS } from 'src/types/coveo';

import { BaseLinkCard } from 'components/BaseHelpers/BaseLinkCard/BaseLinkCard';
import BoxCta from 'components/BaseHelpers/BoxCta';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import Text from 'components/BaseHelpers/Text';

import { InsightResult } from './InsightsTopicsContent';

import styles from './InsightsTopicsContent.module.scss';

interface InsightsTopicsContentCardsProps {
  moduleHeader: Field<string> | undefined;
  showCta?: boolean;
  ctaLink?:
    | LinkField
    | {
        href: string;
        querystring: string;
      };
  ctaText?: Field<string>;
  pinnedInsights: Array<InsightResult> | undefined;
  rows: Field<string> | undefined;
}

interface RawResult {
  titlecf: string;
  displayDate: string;
  startDateandTime: string;
  [COVEO_FIELDS.dateRange]: string | number;
  imageurl: string;
  abstract: string;
  tcsiteurl: string;
  hidedetailpage: string;
}

export const InsightsTopicsContentCards = ({
  moduleHeader,
  showCta,
  ctaLink,
  ctaText,
  pinnedInsights,
  rows,
}: InsightsTopicsContentCardsProps) => {
  const { isTCSite } = useSite();
  const { isInsightLanding } = useTemplate();
  const { isEE } = useExpEditor();
  const { insightsEngine } = useEngineContext();
  const { state } = useBaseSearch();
  const { results, hasBreadcrumbs, hasResults, isLoading } = state;
  const [hideComponent, setHideComponent] = useState<boolean>(false);

  const mappedInsights = useMemo(() => {
    return (pinnedInsights || []).map((insight) => {
      const { carouselImage, title, date, startDateandTime, displayDate, abstract } =
        insight.fields || {};
      return {
        title,
        abstract,
        date: startDateandTime || date,
        displayDate: displayDate,
        image: carouselImage,
        url: insight.url,
      };
    });
  }, [pinnedInsights]);
  const mappedResults = useMemo(() => {
    return results.map((result) => {
      const { titlecf, datesort, displayDate, imageurl, abstract, tcsiteurl, hidedetailpage } =
        decodeResultFields<RawResult>(result.raw);
      return {
        title: { value: titlecf },
        abstract: { value: abstract },
        date: datesort,
        displayDate: { value: displayDate },
        image: { value: { src: imageurl } },
        url: hidedetailpage ? undefined : relativeURL(isTCSite ? tcsiteurl : result.clickUri),
      };
    });
  }, [isTCSite, results]);
  const topics = useMemo(() => {
    const combinedTopics = [...mappedInsights, ...mappedResults].filter((topic) => {
      const { image, title } = topic || {};
      return hasValue(image) && hasValue(title);
    });
    const maxTopics = stringFieldToNumberValue(rows) === 1 ? 3 : 6;
    return combinedTopics?.slice(0, maxTopics);
  }, [mappedInsights, mappedResults, rows]);

  useEffect(() => {
    if (isLoading) return;
    const shouldHide = !hasResults && !hasBreadcrumbs && !topics.length;
    setHideComponent(shouldHide);
  }, [hasBreadcrumbs, hasResults, isLoading, topics]);

  if (!isEE && hideComponent) return <></>;

  return (
    <div className={cn(styles.main, 'spacer', 'container-10')}>
      <ModuleHeader header={moduleHeader} tag={'h2'} className={'container-10'} />
      <ul className={styles.grid}>
        {topics?.map((topic, index) => (
          <li key={index} className={styles.item}>
            <BaseLinkCard image={topic.image} url={topic.url} className={styles['content-card']}>
              <BaseLinkCard.Date field={topic.date} displayDate={topic.displayDate} />
              <BaseLinkCard.Title field={topic.title} underline={true} />
              <BaseLinkCard.Description field={topic.abstract} />
            </BaseLinkCard>
          </li>
        ))}
      </ul>
      {showCta && (
        <BoxCta
          tag={isEE ? 'span' : 'a'}
          centered={true}
          field={ctaLink}
          onClick={(e: React.MouseEvent) => {
            if (isInsightLanding) {
              e.preventDefault();
              handleManualViewAll(ctaLink, insightsEngine);
            }
          }}
        >
          <Text field={ctaText} editable={false} />
        </BoxCta>
      )}
    </div>
  );
};
