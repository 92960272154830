export const getEmail = (email: string | undefined) => {
  if (!email) return;
  // Break up the email with spans so that email can be broken into 2 lines
  // for very long emails
  const emailHtml = email
    .split('@')
    .map((part, index) => `<span>${index === 1 ? '@' : ''}${part}</span>`)
    .join('');
  return { text: email, href: `mailto:${email}`, emailHtml };
};
