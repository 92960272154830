import { DateFacetValue, Facet, FacetState, FacetValue } from '@coveo/headless';
import cn from 'classnames';
import { UseSelectStateChangeTypes, useSelect } from 'downshift';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { CoveoFacet } from 'src/hooks/useCoveoFacet';
import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useFacetLabels } from 'src/hooks/useFacetLabels';

import { COVEO_FIELDS } from 'src/types/coveo';
import { DownshiftClickEvent, DownshiftFocusEvent } from 'src/types/downshift';

import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import Chevron from '../../../assets/icons/facet-chevron.svg';

import styles from './FacetDropdown.module.scss';

interface FacetDropdownProps {
  facet: CoveoFacet;
  sections?: Array<SectionItem>;
  label: string;
}

interface SectionItem {
  raw: string;
  label: string;
}

interface DefaultCoveoFacet extends CoveoFacet {
  controller: Facet;
  state: FacetState;
}

type FacetItem = FacetValue | DateFacetValue | SectionItem;

const SHOW_MORE = 'FacetDropdown_show-more';

export const FacetDropdown = ({ facet, label, sections }: FacetDropdownProps) => {
  const { state, controller } = useBaseSearch();
  const { resultCount, query } = state;
  const { selectSingleFacetValue, selectMultiFacetValue, clearFacet } = controller;
  const { isEE } = useExpEditor();
  const { t } = useDictionary();
  const FACET_LABELS = useFacetLabels();
  // we have to handle `topics` differently because it isn't actually a facet -- selecting one executes a new search with the `topic` as the query
  const {
    isTopicFacet,
    topicOptions,
    showMoreTopics,
    showLessTopics,
    canShowMoreValues,
    canShowLessValues,
  } = useTopicFacet(facet);

  const isSectionFacet = useMemo(() => {
    return sections !== undefined;
  }, [sections]);
  const hasShowMoreButton = useMemo(() => {
    if (isTopicFacet) return canShowMoreValues;

    return isDefaultFacet(facet) && facet.state?.canShowMoreValues;
  }, [canShowMoreValues, facet, isTopicFacet]);
  const options: Array<FacetItem> = useMemo(() => {
    if (isTopicFacet) return topicOptions;

    return sections || facet.state?.values || [];
  }, [isTopicFacet, sections, facet.state?.values, topicOptions]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    setHighlightedIndex,
    getItemProps,
  } = useSelect<FacetItem>({
    // include `Show More` button in `items`, so it's keyboard accessible
    items: hasShowMoreButton ? [...options, { value: SHOW_MORE } as FacetItem] : options,
    itemToString: (item) => {
      return valueToString(item);
    },
    onSelectedItemChange: (changes) => {
      const { selectedItem } = changes;
      if (!selectedItem) return;
      if ('value' in selectedItem && selectedItem.value === SHOW_MORE) {
        loadMoreValues();
        return;
      }
      selectOption(selectedItem);
    },
    scrollIntoView: (node) => {
      const { top, bottom } = node.getBoundingClientRect();
      if (top <= 0 || bottom >= window.innerHeight) {
        node.scrollIntoView({ block: 'center' });
      }
    },
    isItemDisabled(item) {
      return isDisabled(item);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      const events: Array<UseSelectStateChangeTypes> = [
        useSelect.stateChangeTypes.ToggleButtonBlur,
        useSelect.stateChangeTypes.ItemClick,
        useSelect.stateChangeTypes.ToggleButtonKeyDownEnter,
        useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton,
      ];
      if (state.isOpen && events.includes(type)) {
        return { ...changes, isOpen: true };
      }
      return changes;
    },
  });

  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const optionListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!optionListRef.current || isSectionFacet) return;

    const height = optionListRef.current.scrollHeight;
    optionListRef.current.style.maxHeight = `${height}px`;
  }, [options, isSectionFacet]);

  const handleShowLess = useCallback(() => {
    if (isTopicFacet) {
      showLessTopics();
      return;
    }
    if (isDefaultFacet(facet)) {
      facet.controller?.showLessValues();
    }
  }, [facet, isTopicFacet, showLessTopics]);

  useEffect(() => {
    const shouldShowLess =
      (isDefaultFacet(facet) && facet.state?.canShowLessValues) ||
      (isTopicFacet && canShowLessValues);
    if (!isOpen && shouldShowLess) handleShowLess();
  }, [isOpen, facet, isTopicFacet, showLessTopics, canShowLessValues, handleShowLess]);

  const selectOption = (item: FacetItem) => {
    if (!isSectionItem(item)) {
      selectMultiFacetValue(facet, item);
      return;
    }
    if (item.raw === 'All Results' && facet.state?.hasActiveValues) {
      clearFacet(facet);
      return;
    }
    const value = getFacetValue(item.raw);
    if (!value || (isDefaultFacet(facet) && facet.controller?.isValueSelected(value))) return;

    selectSingleFacetValue(facet, value);
  };

  const isSelected = (item: FacetItem) => {
    if (isSectionItem(item)) {
      return isContentTypeSelected(item.raw);
    }
    return isTopicFacet && 'value' in item
      ? query.toLowerCase() === item.value.toLowerCase()
      : item.state === 'selected';
  };

  const isContentTypeSelected = (section: string) => {
    if (section === 'All Results') {
      return !facet.state?.hasActiveValues;
    }

    const value = getFacetValue(section);
    if (!value) return false;
    return value.state === 'selected';
  };

  const getOptionLabel = (item: FacetItem) => {
    if (isSectionItem(item)) {
      return `${item.label} (${getResultCount(item.raw)})`;
    }
    const label = isDateRangeItem(item) ? t(formatDateRange(item)) : t(item.value);
    const numberOfResults = isTopicFacet ? '' : ` (${item.numberOfResults})`;
    return `${label}${numberOfResults}`;
  };

  const isDisabled = (item: FacetItem) => {
    const count = isSectionItem(item) ? getResultCount(item.raw) : item.numberOfResults;
    return count === 0;
  };

  const getResultCount = (section: string) => {
    const value = getFacetValue(section);
    if (!value) {
      return section === 'All Results' ? getAllSectionResults() : 0;
    }
    return value.numberOfResults;
  };

  const getAllSectionResults = () => {
    if (!isDefaultFacet(facet)) return;
    return facet.state?.hasActiveValues
      ? facet.state?.values.reduce((acc, curr) => curr.numberOfResults + acc, 0)
      : resultCount.total;
  };

  const getFacetValue = (section: string) => {
    if (!isDefaultFacet(facet)) return;
    return facet.state?.values.find((value) => value.value.toLowerCase() === section.toLowerCase());
  };

  const loadMoreValues = () => {
    // date and section facets can't have "Show More" buttons
    if (!isDefaultFacet(facet)) return;
    const nextIndex = (isTopicFacet ? topicOptions.length : facet.state.values.length) - 1;
    if (isTopicFacet) {
      showMoreTopics();
    } else {
      facet.controller?.showMoreValues();
    }
    toggleButtonRef.current?.focus();
    setHighlightedIndex(nextIndex);
  };

  const formatDateRange = (item: DateFacetValue) => {
    return FACET_LABELS[item.start as keyof typeof FACET_LABELS] || '';
  };

  const valueToString = (item: FacetItem | null) => {
    return isSectionItem(item)
      ? item?.label
      : isDateRangeItem(item)
        ? formatDateRange(item)
        : item?.value || '';
  };

  if (isEE) {
    return (
      <div className={styles['facet-button']}>
        <span className={styles['facet-button-label']}>{label}</span>
        <span className={styles['facet-icon']}>
          <Chevron />
        </span>
      </div>
    );
  }

  return (
    <div className={cn({ [styles['section-dropdown-wrapper']]: isSectionFacet })}>
      <label className={cn(styles['facet-label'], 'visually-hidden')} {...getLabelProps()}>
        {label}
      </label>
      <div
        ref={toggleButtonRef}
        className={cn(styles['facet-button'], {
          [styles['section-button']]: isSectionFacet,
          [styles['active-button']]: isOpen,
        })}
        {...getToggleButtonProps({
          onBlur: (e: DownshiftFocusEvent<HTMLDivElement>) => {
            e.nativeEvent.preventDownshiftDefault = true;
            setHighlightedIndex(-1);
          },
          disabled:
            !options ||
            options.length === 0 ||
            options.every((option: FacetItem) => isDisabled(option)),
        })}
      >
        <span className={styles['facet-button-label']}>{label}</span>
        <span className={cn(styles['facet-icon'], { [styles['expanded']]: isOpen })}>
          <Chevron />
        </span>
      </div>
      <div
        className={cn(styles['facet-dropdown'], {
          [styles['section-dropdown']]: isSectionFacet,
          [styles['expanded-dropdown']]: isOpen,
        })}
        {...getMenuProps()}
      >
        <ul
          className={cn(styles['facet-option-list'], {
            [styles['section-list']]: isSectionFacet,
            [styles['extended-list']]:
              (isDefaultFacet(facet) && facet.state?.canShowLessValues) ||
              (isTopicFacet && canShowLessValues),
          })}
          ref={optionListRef}
        >
          {options?.map((item, index) => {
            if (
              // for some reason, `coveo` doesn't exclude `DateFacet` values with `0` results
              (isDateRangeItem(item) && item.numberOfResults === 0) ||
              ('value' in item && facet.exclude?.includes(item.value.toLowerCase()))
            ) {
              return null;
            }

            const activeItem = isSelected(item);
            const { onMouseMove, ...restItemProps } = getItemProps({
              item,
              index,
              'aria-selected': activeItem,
            });
            return (
              <li
                key={valueToString(item)}
                className={cn(styles['facet-option-item'], {
                  [styles['section-item']]: isSectionFacet,
                  [styles['active-option']]: activeItem,
                  [styles['focused-option']]: highlightedIndex === index,
                  [styles['disabled-option']]: isDisabled(item),
                })}
                {...restItemProps}
              >
                <div
                  className={cn(styles['facet-option-checkbox'], {
                    [styles['radio-input']]: isSectionFacet,
                  })}
                ></div>
                <span
                  className={cn({
                    [styles['facet-option-label']]: !isSectionFacet,
                    [styles['section-option-label']]: isSectionFacet,
                    [styles['topic-label']]: isTopicFacet,
                  })}
                >
                  {getOptionLabel(item)}
                </span>
              </li>
            );
          })}
          {hasShowMoreButton && (
            <li
              className={styles['show-more-item']}
              {...getItemProps({
                // fake facet value so we can handle `onClick` with `downshift`
                item: { value: SHOW_MORE } as FacetItem,
                index: options.length,
                'aria-label': `${t('Show more')} ${label} options`,
                onMouseMove: (e: DownshiftClickEvent<HTMLLIElement>) => {
                  e.nativeEvent.preventDownshiftDefault = true;
                },
              })}
            >
              <UnderlineCta
                className={cn(styles['show-more-button'], {
                  [styles['focused-show-more']]: highlightedIndex === options.length,
                })}
                tag="span"
              >
                {t('Show More')}
              </UnderlineCta>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const isDefaultFacet = (facet: FacetDropdownProps['facet']): facet is DefaultCoveoFacet => {
  if (facet.field === COVEO_FIELDS.topics) return true;
  if (!facet || !facet.state || !facet.controller) return false;
  return 'canShowMoreValues' in facet.state && 'showMoreValues' in facet.controller;
};

const isSectionItem = (item: FacetItem | null | undefined): item is SectionItem => {
  if (!item) return false;
  return 'raw' in item;
};

const isDateRangeItem = (item: FacetItem | null | undefined): item is DateFacetValue => {
  if (!item) return false;
  return 'start' in item;
};

const useTopicFacet = (facet: CoveoFacet) => {
  const [optionsIndex, setOptionsIndex] = useState<number>(facet.config?.numberOfValues || 0);
  const isTopicFacet = useMemo(() => {
    return Boolean(facet.field === COVEO_FIELDS.topics && facet.topics?.length);
  }, [facet.topics, facet.field]);
  const allTopicOptions = useMemo(() => {
    if (!isTopicFacet && !facet.topics) return [];
    return createTopicFacetArray(facet.topics);
  }, [facet.topics, isTopicFacet]);
  const topicOptions = useMemo(() => {
    return allTopicOptions.slice(0, optionsIndex);
  }, [allTopicOptions, optionsIndex]);
  const showMoreTopics = useCallback(() => {
    if (!isTopicFacet) return;
    setOptionsIndex((value) => {
      const increment = value + (facet.config?.numberOfValues || 0);
      return Math.min(increment, allTopicOptions.length);
    });
  }, [allTopicOptions.length, facet.config?.numberOfValues, isTopicFacet]);
  const showLessTopics = useCallback(() => {
    if (!isTopicFacet) return;
    setOptionsIndex(facet.config?.numberOfValues || 0);
  }, [facet.config?.numberOfValues, isTopicFacet]);
  const canShowMoreValues = useMemo(() => {
    if (!isTopicFacet) return false;
    return topicOptions.length < allTopicOptions.length;
  }, [isTopicFacet, topicOptions.length, allTopicOptions.length]);
  const canShowLessValues = useMemo(() => {
    return topicOptions.length > (facet.config?.numberOfValues || 0);
  }, [facet.config?.numberOfValues, topicOptions.length]);

  return {
    isTopicFacet,
    topicOptions,
    showMoreTopics,
    showLessTopics,
    canShowMoreValues,
    canShowLessValues,
  };
};

const createTopicFacetArray = (allowedValues: Array<string> | undefined): Array<FacetValue> => {
  if (!allowedValues) return [];
  return allowedValues.map((value) => ({ state: 'idle', value: value, numberOfResults: 1 }));
};
