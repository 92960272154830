import { Suggestion } from '@coveo/headless';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useCombobox } from 'downshift';
import { useEffect, useMemo, useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useGTMSearchInput } from 'src/hooks/useGoogleTagManager';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

import { getStringFieldValue } from 'src/utils/sitecoreFieldHelpers';

import { DownshiftClickEvent, DownshiftKeyboardEvent } from 'src/types/downshift';

import Arrow from '../../../assets/icons/arrow.svg';
import Magnifying from '../../../assets/icons/magnifying-color.svg';
import CloseIcon from '../../../assets/icons/plus.svg';
import BoxCta from '../BoxCta';
import { LinkProps } from '../Link';
import LinkOrComponent from '../LinkOrComponent/LinkOrComponent';
import Text from '../Text';

import styles from './SearchBox.module.scss';

export type SuggestionOrLink = Suggestion & {
  url?: LinkProps;
};

interface SearchBoxProps {
  id: string;
  value?: string;
  placeholder?: Field<string> | string | undefined;
  updateText?: (text: string) => void;
  submitSearch?: () => void;
  selectSuggestion?: (suggestion: string) => void;
  suggestions?: SuggestionOrLink[];
  topics?: string[];
  className?: string;
  variant?: 'landing' | 'header';
  searchOpen?: boolean;
  setSearchOpen?: (searchOpen: boolean) => void;
}

export const SearchBox = ({
  id,
  value,
  placeholder,
  updateText,
  submitSearch,
  selectSuggestion,
  suggestions,
  topics,
  className = '',
  variant = 'landing',
  searchOpen,
  setSearchOpen,
}: SearchBoxProps): JSX.Element => {
  const { t } = useDictionary();
  const { isEE } = useExpEditor();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showTopics, setShowTopics] = useState<boolean>(false);
  const [choseItem, setChoseItem] = useState<boolean>(false);
  const items = useMemo(() => {
    return topics && showTopics ? topics : suggestions && showSuggestions ? suggestions : [];
  }, [showTopics, showSuggestions, suggestions, topics]);
  const { trackInput } = useGTMSearchInput();
  const {
    isOpen,
    openMenu,
    selectItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox<SuggestionOrLink | string>({
    items: items || [],
    itemToString: (item) => {
      return getStringValue(item) || '';
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectSuggestion || !selectedItem || isLink(selectedItem)) return;
      setChoseItem(true);
      const value = getStringValue(selectedItem);
      selectSuggestion(value || '');
    },
  });

  useEffect(() => {
    if (!suggestions) return;
    const hasQueryText = Boolean(value && value.length >= 3);
    const hasSuggestions = suggestions?.length !== 0;
    setShowSuggestions(hasQueryText && hasSuggestions);
  }, [suggestions, topics, value]);

  useEffect(() => {
    if (!topics) return;
    const hasNoQueryText = !value || value.length === 0;
    setShowTopics(Boolean(topics && topics.length) && hasNoQueryText);
  }, [topics, value]);

  useEffect(() => {
    if (selectedItem !== null && !value && choseItem) {
      selectItem(null);
    }
  }, [choseItem, selectItem, selectedItem, value]);

  useEffect(() => {
    if (selectedItem === null) {
      setChoseItem(false);
    }
  }, [selectedItem]);

  const placeholderValue = getStringFieldValue(placeholder) || t('SEARCH');

  const handleSearch = () => {
    if (submitSearch) submitSearch();
  };

  if (isEE) {
    return (
      <div className={cn(styles['search-box-container'], className, styles[variant])}>
        <Text
          field={typeof placeholder === 'string' ? { value: placeholder } : placeholder}
          className={styles['search-box']}
        />
        <div className={styles['magnifying-button']}>
          <Magnifying />
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles['search-box-container'], className, styles[variant])}>
      <label className={'visually-hidden'} {...getLabelProps()}>
        {placeholderValue}
      </label>
      <input
        className={styles['search-box']}
        type="text"
        enterKeyHint="search"
        {...getInputProps({
          id: id,
          value: value || '',
          placeholder: placeholderValue,
          onChange: (e) => {
            const input = e.currentTarget.value;
            updateText && updateText(input);
            trackInput(e);
          },
          onKeyDown: (e: DownshiftKeyboardEvent) => {
            const keyCode = [e.code, e.key];
            if (keyCode.includes('Enter')) {
              if (value) e.currentTarget.blur();
              handleSearch();
            }

            if (keyCode.includes('Home')) {
              e.preventDefault();
              e.nativeEvent.preventDownshiftDefault = true;
              e.currentTarget.setSelectionRange(0, 0);
            }
            if (keyCode.includes('End')) {
              e.preventDefault();
              e.nativeEvent.preventDownshiftDefault = true;
              const endIndex = e.currentTarget.value.length;
              e.currentTarget.setSelectionRange(endIndex, endIndex);
            }
          },
          onClick: (e: DownshiftClickEvent) => {
            e.nativeEvent.preventDownshiftDefault = true;
            if (!isOpen) openMenu();
          },
          onFocus: () => {
            if (!isOpen) openMenu();
          },
          onSubmit: () => {
            handleSearch();
          },
          'aria-expanded': isOpen && showSuggestions,
        })}
      />
      {(isDesktop || variant === 'landing') && (
        <button
          className={styles['magnifying-button']}
          type="button"
          aria-label="Submit search"
          onClick={() => handleSearch()}
        >
          <Magnifying />
        </button>
      )}

      {variant === 'header' && isDesktop && (
        <div>
          <button
            className={styles['close-button']}
            type="button"
            aria-label="Close search"
            onClick={() => setSearchOpen && setSearchOpen(!searchOpen)}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      {/* mobile */}
      {variant === 'header' && !isDesktop && (
        <div>
          <button
            className={styles['close-button']}
            type="button"
            aria-label="Clear search"
            onClick={() => updateText && updateText('')}
          >
            <CloseIcon />
          </button>
          <button
            className={styles['magnifying-button']}
            type="button"
            aria-label="Submit search"
            onClick={() => handleSearch()}
          >
            <Arrow />
          </button>
        </div>
      )}
      <div
        className={cn(styles['suggestions'], {
          [styles['expanded-list']]: isOpen && (showTopics || showSuggestions),
          [styles['collapsed-list']]: !isOpen || !(showTopics || showSuggestions),
        })}
      >
        <>
          <h2
            className={cn(styles['suggestions-heading'], {
              [styles['trending-topics-heading']]: Boolean(topics) && showTopics,
              [styles['closed-heading']]: !showTopics && !showSuggestions,
            })}
          >
            {t(showTopics ? 'Trending Topics' : 'Search Suggestions')}
          </h2>
          <ul
            className={cn(styles['suggestions-list'], {
              [styles['trending-topics-list']]: Boolean(topics) && showTopics && !showSuggestions,
            })}
            {...getMenuProps()}
          >
            {items?.map((item, index) => {
              const { onMouseMove, ...restItemProps } = getItemProps({ item, index });
              return (
                <li
                  key={`${index}-${getStringValue(item)}`}
                  className={cn(styles['suggestions-list-item'], {
                    [styles['selected-item']]: highlightedIndex === index,
                    [styles['closed-list']]: !showTopics && !showSuggestions,
                  })}
                  {...restItemProps}
                >
                  {typeof item === 'string' ? (
                    <BoxCta tag="span" className={styles['trending-topics-button']}>
                      {item}
                    </BoxCta>
                  ) : (
                    <LinkOrComponent tag={'span'} field={item.url}>
                      <span
                        className={styles['suggestions-list-button']}
                        dangerouslySetInnerHTML={{ __html: item.highlightedValue }}
                      ></span>
                    </LinkOrComponent>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      </div>
    </div>
  );
};

const getStringValue = (item: string | SuggestionOrLink | null | undefined) => {
  if (!item) return;
  return typeof item === 'string' ? item : item.rawValue;
};

const isLink = (item: string | SuggestionOrLink | null | undefined) => {
  return item && typeof item === 'object' && 'url' in item;
};
