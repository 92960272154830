import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import RichText from '../RichText';

import styles from './NoImageHero.module.scss';

export type NoImageHeroProps = {
  headline?: Field<string>;
  body?: Field<string>;
  className?: string;
};

const NoImageHero = ({ headline, body, className = '' }: NoImageHeroProps): JSX.Element => {
  const { hasValueOrIsEE } = useExpEditor();

  if (hasValueOrIsEE(headline)) {
    return (
      <div className={cn(styles.main, 'container-6', className)}>
        <div className={cn(styles[`hero-container`])}>
          <RichText className={cn(styles[`headline`])} field={headline} tag="h1"></RichText>

          <RichText className={cn(styles[`body`])} field={body}></RichText>
        </div>
      </div>
    );
  }

  return <></>;
};

export default NoImageHero;
