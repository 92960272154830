import { ParsedUrlQuery } from 'querystring';

const PDF_OPTIONS_QUERY_KEY = 'pdf-options';
const DELIMINATOR = ',';
const EQUALS = ':';

export const parsePdfOptionsParam = (query: ParsedUrlQuery) => {
  const queryValue = query[PDF_OPTIONS_QUERY_KEY];
  const options = typeof queryValue === 'string' ? queryValue : '';
  const result = {
    countryCode: '',
    fullBio: false,
  };
  const entries = options.split(DELIMINATOR).map((option) => option.split(EQUALS));
  entries.forEach((entry) => {
    if (entry.length === 2) {
      const [key, value] = entry;
      if (key === 'countrycode' && value && value !== 'undefined') {
        result.countryCode = value;
      }
      if (key === 'full' && value === 'true') {
        result.fullBio = true;
      }
    }
  });
  return result;
};

export const formatPdfOptionsParam = (countryCode?: string, fullBio = false) => {
  return {
    key: PDF_OPTIONS_QUERY_KEY,
    value: [countryCode && `countrycode${EQUALS}${countryCode}`, fullBio && `full${EQUALS}true`]
      .filter(Boolean)
      .join(DELIMINATOR),
  };
};
