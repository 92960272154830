import { useMemo } from 'react';

import { Locale } from 'components/Navigation/NavigationHeader/components/LocaleDropdown/LocaleDropdown';

import { useMetaData } from './useMetaData';
import { useSite } from './useSite';

const TC_LANGS = ['x-default', 'en', 'pt'];

export const useLanguages = () => {
  const { isTCSite } = useSite();
  const {
    metaData: { alternateLinks },
  } = useMetaData();

  const languages = useMemo(() => {
    return isTCSite
      ? alternateLinks?.filter((lang) => TC_LANGS.includes(lang.hrefLang))
      : alternateLinks;
  }, [alternateLinks, isTCSite]);

  return languages;
};

export const useLocales = (locales: Array<Locale> | undefined) => {
  const { isTCSite } = useSite();

  const filteredLocales = useMemo(() => {
    return isTCSite ? locales?.filter((locale) => TC_LANGS.includes(locale.name)) : locales;
  }, [locales, isTCSite]);

  return filteredLocales || [];
};
