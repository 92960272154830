import { useRef, useEffect, useState } from 'react';

export function useExpansionFocus(isExpanded: boolean) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (containerRef.current && !firstRender) {
      if (isExpanded) {
        containerRef.current.focus();
      } else {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const isContainerInView = containerTop > 0 && containerTop < window.innerHeight;

        if (isContainerInView) {
          return;
        }

        const halfWindowHeight = window.innerHeight / 2;
        const pageYOffset = window.pageYOffset;
        const top = containerTop + pageYOffset - halfWindowHeight;

        window.scrollTo({
          top: top || 0,
          behavior: 'smooth',
        });
      }
    }
  }, [isExpanded]);

  return {
    containerRef,
  };
}
