import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useBaseSearch } from 'src/hooks/useBaseSearch';

import styles from './BaseResultStats.module.scss';

interface BaseResultStatsProps {
  statsTemplate: Field<string> | undefined;
  className?: string;
}

export const BaseResultStats = ({ statsTemplate, className }: BaseResultStatsProps) => {
  const { state } = useBaseSearch();
  const { resultCount, showResults } = state;
  const template = statsTemplate?.value || 'Viewing {0} of {1} results';
  const resultStats = template
    .replace('{0}', `${resultCount.current}`)
    .replace('{1}', `${resultCount.total}`);

  if (!showResults) return <></>;

  return <span className={cn(styles['result-stats'], className)}>{resultStats}</span>;
};
