import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';
import { useExpansionFocus } from 'src/hooks/useExpansionFocus';
import { useSite } from 'src/hooks/useSite';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import EditFrame from 'components/BaseHelpers/EditFrame';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import ServicesRelatedList from './ServicesRelatedList';

import styles from './ServicesRelated.module.scss';

export type ServicesRelatedProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Services.RelatedServices;

const ServicesRelated = ({ fields = {}, rendering }: ServicesRelatedProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { hasItemsOrIsEE, isEE } = useExpEditor();
  const { t } = useDictionary();
  const {
    sitecoreContext: { itemId, route },
  } = useSitecoreContext();
  const [showMore, setShowMoreClicked] = useState(false);
  const { isTCSite } = useSite();

  const getCapabilities = () => {
    const capabilities = isTCSite ? route?.fields?.servicesTC : route?.fields?.services;
    if (Array.isArray(capabilities) && capabilities.length) {
      return capabilities as Website.Feature.Services._Service[];
    }
    return [];
  };

  const capabilities = getCapabilities();
  const { containerRef } = useExpansionFocus(showMore);

  if (!hasItemsOrIsEE(capabilities)) {
    return <></>;
  }

  const industries = (capabilities || []).filter((capability) =>
    capability.url?.toLowerCase().includes('/industries/')
  );
  const services = (capabilities || []).filter((capability) =>
    capability.url?.toLowerCase().includes('/services/')
  );

  const servicesRegionId = `services-${rendering.uid}`;
  const industriesRegionId = `industries-${rendering.uid}`;

  const showMoreLength = 7;
  const hasShowMore =
    !isEE && (industries.length >= showMoreLength || services.length >= showMoreLength);

  return (
    <AnimateInContainer className={cn(styles.main, 'container-8', 'spacer')} tabIndex={-1}>
      <ModuleHeader header={moduleHeader} />
      <div>
        <EditFrame itemId={itemId} fields={isTCSite ? ['Services TC'] : ['Services']}>
          <div className={cn(styles.lists)}>
            <ServicesRelatedList
              items={services}
              serviceType="Services"
              showMore={showMore}
              id={servicesRegionId}
              ref={services.length >= showMoreLength ? containerRef : undefined}
            />
            <ServicesRelatedList
              items={industries}
              serviceType="Industries"
              showMore={showMore}
              id={industriesRegionId}
              ref={
                services.length < showMoreLength && industries.length >= showMoreLength
                  ? containerRef
                  : undefined
              }
            />
          </div>
        </EditFrame>
      </div>
      {hasShowMore && (
        <UnderlineCta
          className={styles.cta}
          tag="button"
          onClick={() => setShowMoreClicked((currentValue) => !currentValue)}
          text={showMore ? t('Show Less') : t('Load More')}
          centered={true}
          aria-expanded={showMore ? 'true' : 'false'}
          aria-controls={`${servicesRegionId} ${industriesRegionId}`}
        />
      )}
    </AnimateInContainer>
  );
};

export default ServicesRelated;
