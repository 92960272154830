import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useTemplate } from 'src/hooks/useTemplate';

import { Website } from 'lib/component-props/model';

import Link from 'components/BaseHelpers/Link';
import { richTextStyles } from 'components/BaseHelpers/RichText';

import styles from './ServiceList.module.scss';

export type ServiceListProps = {
  list: Website.Feature.Services._Service[];
  className?: string;
  variant?: 'columns' | 'default';
};

const ServiceList = ({
  list = [],
  className = '',
  variant = 'default',
}: ServiceListProps): JSX.Element => {
  const { isPeopleDetail } = useTemplate();
  const { hasItemsOrIsEE } = useExpEditor();

  if (!hasItemsOrIsEE(list)) {
    return <></>;
  }

  return (
    <div
      className={cn(
        richTextStyles.main,
        { [styles['no-bullets']]: !isPeopleDetail },
        className,
        styles[variant]
      )}
    >
      <ul>
        {list.map((item, index) => (
          <li key={index}>
            <Link
              className={styles[`link`]}
              field={{ href: item.url, text: item.fields?.name?.value }}
              editable={false}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceList;
