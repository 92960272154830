import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { hasValue } from './hasValue';

const US_LANGUAGE_KEY = 'US';

export const formatDate = (
  date: Field<string> | string | number | undefined,
  countryCode: string = US_LANGUAGE_KEY,
  language = 'en'
) => {
  if (hasNoDate(date)) return;

  const jsDate = getJSDate(date);

  if (!isValidJSDate(jsDate)) {
    if (typeof date === 'number') return;
    return getDateString(date);
  }

  const lang = language.toLowerCase();
  const { year, month, day } = getDateParts(jsDate, lang);

  if (lang === 'zh-hans' || lang === 'ja') {
    return `${year}${month}${day}`;
  }

  if (isUSCountryCode(countryCode)) {
    return lang === 'en' ? `${month} ${day}, ${year}` : `${month} ${day} ${year}`;
  }

  if (isChinaCountryCode(countryCode)) {
    return `${year} ${month} ${day}`;
  }

  return `${day} ${month} ${year}`;
};

const NO_DATE_VALUES = [
  '0001/01/01@00:00:00Z', // coveo
  '0001-01-01T00:00:00Z', // sitecore
];

const hasNoDate = (date: Field<string> | string | number | undefined): date is undefined => {
  if (!date) return true;
  const extractedDate = getDateString(date);
  if (!extractedDate) return true;
  return NO_DATE_VALUES.includes(extractedDate);
};

const getJSDate = (date: Field<string> | string | number) => {
  if (typeof date === 'number') return new Date(date);
  const dateString = getDateString(date);
  return dateString.includes('@')
    ? new Date(dateString.substring(0, dateString.indexOf('@')))
    : new Date(dateString);
};

export const getDateString = (date: Field<string> | string | number) => {
  return typeof date === 'string' || typeof date === 'number' ? `${date}` : date.value;
};

const isValidJSDate = (jsDate: Date) => {
  return jsDate instanceof Date && !isNaN(jsDate.getTime());
};

export const isFutureDate = (date: Field<string> | string | undefined) => {
  if (date === undefined || !hasValue(date)) {
    return false;
  }

  const dateString = getDateString(date);
  const jsDate = new Date(dateString);
  if (!isValidJSDate(jsDate)) {
    return false;
  }

  const now = new Date();
  return now.getTime() < jsDate.getTime();
};

const isChinaCountryCode = (code = '') =>
  code.toUpperCase() === 'CN' || code.toUpperCase() === 'HK';

const isUSCountryCode = (code = '') =>
  // We also want the default country code to be US in case we are unable to get
  // the user's location information (such as when viewing page on the Admin site).
  code.toUpperCase() === 'US' || code === '' || code === 'undefined';

const getDateParts = (date: Date, language: string) => {
  const dateString = date.toISOString();
  const dateParts = dateString.slice(0, dateString.indexOf('T')).split('-');

  return {
    year: getYear(dateParts[0], language),
    month: getMonth(dateParts[1], language),
    day: getDay(dateParts[2], language),
  };
};

const getMonth = (monthDigits: string, language: string) => {
  const map = new Map<string, Record<string, string>>();
  map.set('en', {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  });
  map.set('es', {
    '01': 'enero',
    '02': 'febrero',
    '03': 'marzo',
    '04': 'abril',
    '05': 'mayo',
    '06': 'junio',
    '07': 'julio',
    '08': 'agosto',
    '09': 'septiembre',
    '10': 'octubre',
    '11': 'noviembre',
    '12': 'diciembre',
  });
  map.set('de', {
    '01': 'Januar',
    '02': 'Februar',
    '03': 'März',
    '04': 'April',
    '05': 'Mai',
    '06': 'Juni',
    '07': 'Juli',
    '08': 'August',
    '09': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'Dezember',
  });
  map.set('pt', {
    '01': 'janeiro',
    '02': 'fevereiro',
    '03': 'março',
    '04': 'abril',
    '05': 'maio',
    '06': 'junho',
    '07': 'julho',
    '08': 'agosto',
    '09': 'setembro',
    '10': 'outubro',
    '11': 'novembro',
    '12': 'dezembro',
  });
  map.set('fr', {
    '01': 'janvier',
    '02': 'février',
    '03': 'mars',
    '04': 'avril',
    '05': 'mai',
    '06': 'juin',
    '07': 'juillet',
    '08': 'août',
    '09': 'septembre',
    '10': 'octobre',
    '11': 'novembre',
    '12': 'décembre',
  });

  const lang = language.toLowerCase();
  if (lang === 'zh-hans' || lang === 'ja') {
    return `${parseInt(monthDigits)}月`;
  }
  return map.get(lang)?.[monthDigits] || monthDigits;
};

const getDay = (dayDigits: string, language: string) => {
  const lang = language.toLowerCase();
  if (lang === 'de') {
    return `${dayDigits}.`;
  } else if (lang === 'zh-hans' || lang === 'ja') {
    return `${dayDigits}日`;
  } else {
    return dayDigits;
  }
};

const getYear = (yearDigits: string, language: string) => {
  const lang = language.toLowerCase();
  return lang === 'zh-hans' || lang === 'ja' ? `${yearDigits}年` : yearDigits;
};
