import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useDictionary } from 'src/hooks/useDictionary';
import { useFacetLabels } from 'src/hooks/useFacetLabels';

import { hasItems } from 'src/utils/hasItems';

import CloseIcon from 'src/assets/icons/close-icon-black.svg';

import UnderlineCta from '../UnderlineCta';

import styles from './BaseBreadcrumbs.module.scss';

export const BaseBreadcrumbs = ({ className }: { className?: string }) => {
  const { state, controller } = useBaseSearch();
  const { breadcrumbs, hasQuery, currentQuery, showResults } = state;
  const { clearQuery, clearAll, clearBreadcrumb } = controller;
  const facetLabels = useFacetLabels();
  const { t } = useDictionary();
  const [showClearAll, setShowClearAll] = useState<boolean>(false);

  useEffect(() => {
    const hasTwoOrMoreBreadcrumbs =
      (hasQuery && breadcrumbs.length > 0) ||
      breadcrumbs.length > 1 ||
      breadcrumbs.some((breadcrumb) => breadcrumb.values.length > 1);
    setShowClearAll(hasTwoOrMoreBreadcrumbs);
  }, [hasQuery, breadcrumbs]);

  if (!showResults || (!hasItems(breadcrumbs) && !hasQuery)) return <div></div>;

  return (
    <div className={cn(styles['breadcrumb-container'], className)}>
      <span className={styles['breadcrumb-list-label']}>{`${t('Filters')}: `}</span>
      <ul className={styles['breadcrumb-list']}>
        {hasQuery && <Breadcrumb label={currentQuery} isQuery={true} onClick={clearQuery} />}
        {breadcrumbs.map((breadcrumb) => {
          return breadcrumb.values.map((b) => {
            const label =
              'value' in b.value
                ? b.value.value
                : facetLabels[b.value.start as keyof typeof facetLabels];
            return <Breadcrumb key={label} label={label} onClick={() => clearBreadcrumb(b)} />;
          });
        })}
        {showClearAll && (
          <li className={styles['clear-filters-container']}>
            <UnderlineCta tag="button" onClick={clearAll} className={styles['clear-filters']}>
              {t('Clear filters')}
            </UnderlineCta>
          </li>
        )}
      </ul>
    </div>
  );
};

const Breadcrumb = ({
  label,
  isQuery,
  onClick,
}: {
  label: string | undefined;
  isQuery?: boolean;
  onClick: () => void;
}) => {
  if (!label) return <></>;

  return (
    <li className={cn(styles['breadcrumb'], { [styles['query-breadcrumb']]: isQuery })}>
      <button className={styles['breadcrumb-close-button']} onClick={onClick}>
        <span className={styles['breadcrumb-label']}>{label}</span> <CloseIcon />
      </button>
    </li>
  );
};
