import cn from 'classnames';
import { useRef } from 'react';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import LocationCard from './LocationCard';

import styles from './OfficeLandingListing.module.scss';

type Region = {
  regionID: string;
  name: string;
  offices?: Website.Feature.Offices._Office[];
};

export type OfficeLandingListingProps = {
  fields?: {
    regions?: Region[];
  };
};

const OfficeLandingListing = ({ fields = {} }: OfficeLandingListingProps): JSX.Element => {
  const { regions = [] } = fields;
  const regionRefs = useRef<HTMLLIElement[]>([]);

  const scrollToRegion = (regionIndex: number) => {
    const region = regionRefs.current.at(regionIndex);
    if (region) {
      region.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => region.focus(), 1000);
    }
  };

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      {regions.length > 1 && (
        <ul className={styles.buttons}>
          {regions.map((region, regionIndex) => (
            <li className={styles['button-item']} key={region.regionID}>
              <button className={styles.button} onClick={() => scrollToRegion(regionIndex)}>
                {region.name}
              </button>
            </li>
          ))}
        </ul>
      )}
      <ul className={styles.regions}>
        {regions.map((region) => (
          <li
            className={styles['region-item']}
            key={region.regionID}
            tabIndex={-1}
            ref={(el: HTMLLIElement) => regionRefs.current.push(el)}
          >
            <ModuleHeader
              className={styles['region-name']}
              header={{ value: region.name }}
              tag="h2"
              variant="double-line"
            />
            <ul className={styles.offices}>
              {region.offices?.map((office) => (
                <li className={styles['office-item']} key={office.id}>
                  <LocationCard {...office} />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </AnimateInContainer>
  );
};

export default OfficeLandingListing;
