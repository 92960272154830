import { Link } from '@sitecore-jss/sitecore-jss-nextjs';

import { Website } from 'lib/component-props/model';

import Image from 'components/BaseHelpers/Image';

import SocialIcons, { SocialIconName } from '../SocialIcon/SocialIcons';

import styles from './SocialLinks.module.scss';

export type SocialLinksProps = {
  socialLinks: Website.Feature.Navigation.SocialLink[];
};

const SocialLinks = ({ socialLinks }: SocialLinksProps): JSX.Element => (
  <ul className={styles[`main`]}>
    {socialLinks.map(({ fields }, index) => {
      return (
        ((fields?.link?.value?.href && fields?.platform?.value) || fields?.qrCode?.value?.src) && (
          <li className={styles[`social-link`]} key={`social-link-footer-${index}`}>
            {fields?.link?.value?.href && fields?.platform?.value && (
              <Link
                field={fields?.link}
                target="_blank"
                rel="noreferrer"
                aria-label={fields?.platform?.value}
              >
                <SocialIcons
                  theme="dark"
                  iconName={fields?.platform?.value as SocialIconName}
                ></SocialIcons>
              </Link>
            )}
            {fields?.qrCode?.value?.src && !fields?.link?.value?.href && (
              <div className={styles[`wechat-qr`]}>
                <Image className={styles[`wechat-qr-code`]} field={fields?.qrCode}></Image>
                <SocialIcons theme="dark" iconName={'WeChat'}></SocialIcons>
              </div>
            )}
          </li>
        )
      );
    })}
  </ul>
);

export default SocialLinks;
