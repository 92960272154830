import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { useBaseSearch } from 'src/hooks/useBaseSearch';

import { BaseResults } from 'components/BaseHelpers/BaseSearch/BaseResults';

import { BaseSearchResult } from './BaseSearchResult';

import styles from './BaseSearchResults.module.scss';

export interface BaseSearchResultsProps {
  statsTemplate: Field<string> | undefined;
  correctionTemplate?: Field<string> | undefined;
  noResults: JSX.Element | undefined;
  fallback: JSX.Element | undefined;
  className?: string;
}

export const BaseSearchResults = ({
  statsTemplate,
  correctionTemplate,
  noResults,
  fallback,
  className,
}: BaseSearchResultsProps) => {
  const { state } = useBaseSearch();
  const { results } = state;
  return (
    <BaseResults
      statsLayout={'row'}
      statsTemplate={statsTemplate}
      correctionTemplate={correctionTemplate}
      fallback={fallback}
      noResults={noResults}
      className={className}
    >
      <ul className={styles['results-list']}>
        {results.map((result) => {
          return (
            <li key={result.uniqueId}>
              <BaseSearchResult result={result} />
            </li>
          );
        })}
      </ul>
    </BaseResults>
  );
};
