import { LayoutServiceData, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import React, { useEffect } from 'react';
import Scripts from 'src/Scripts';

import { useGTMPageView } from './hooks/useGoogleTagManager';
import { useSite } from './hooks/useSite';
import { useTemplate } from './hooks/useTemplate';
import DefaultTemplate from './templates/DefaultTemplate';
import PeopleDetailTemplate from './templates/PeopleDetailTemplate';
import SearchTemplate from './templates/SearchTemplate';
import { setCookie } from './utils/cookie';
import { getPublicUrl } from './utils/getPublicUrl';

interface LayoutProps {
  layoutData: LayoutServiceData;
}

interface RouteFields {
  [key: string]: unknown;
  pageTitle: Field;
}

const Layout = ({ layoutData }: LayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;
  const { isPeopleDetail, isSearchLanding } = useTemplate();
  const fields = route?.fields as RouteFields;

  const { isTCSite } = useSite();
  const publicUrl = getPublicUrl(isTCSite);

  useSetLanguageCookie(layoutData.sitecore.context.language);
  useGTMPageView();

  const Template = isPeopleDetail
    ? PeopleDetailTemplate
    : isSearchLanding
      ? SearchTemplate
      : DefaultTemplate;

  return (
    <>
      <Scripts />
      <Head>
        <title>{fields.pageTitle?.value.toString() || 'Page'}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="icon" href={`${publicUrl}/favicon.ico`} />
      </Head>
      {route && <Template route={route} />}
    </>
  );
};

const useSetLanguageCookie = (language = 'en') => {
  useEffect(() => setCookie('NEXT_LOCALE', language), [language]);
};

export default Layout;
