import cn from 'classnames';

import { useDictionary } from 'src/hooks/useDictionary';

import { hasValue } from 'src/utils/hasValue';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import { BaseLinkCard } from 'components/BaseHelpers/BaseLinkCard/BaseLinkCard';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './BasicContentTile.module.scss';

export type BasicContentTileProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Tile & {
    isVertical: boolean;
    showLink: boolean;
  };

const BasicContentTile = (props: BasicContentTileProps): JSX.Element => {
  const { t } = useDictionary();
  const { description, headline, image, imagePosition, link } = props.fields || {};
  const imagePositionRight = props.isVertical && imagePosition?.value?.toLowerCase() === 'right';
  const parentOrientationClass = props.isVertical ? 'vertical-parent' : 'horizontal-parent';
  const hasCtaLink = !props.isVertical && hasValue(link) && props.showLink;

  return (
    <BaseLinkCard
      image={image}
      url={link}
      rowLayout={props.isVertical}
      className={cn(styles['main'], styles[parentOrientationClass], {
        [styles['image-right']]: imagePositionRight,
      })}
    >
      <BaseLinkCard.Title field={headline} underline={true} />
      <BaseLinkCard.Description field={description} />
      {hasCtaLink && <UnderlineCta tag={'span'} text={t('Learn More')} />}
    </BaseLinkCard>
  );
};

export default BasicContentTile;
