import { useDictionary } from 'src/hooks/useDictionary';

import { getAddresses } from 'src/utils/getAddresses';
import { hasValue } from 'src/utils/hasValue';

import { Website } from 'lib/component-props/model';

import ImageCta from 'components/BaseHelpers/ImageCta';
import Link from 'components/BaseHelpers/Link';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './LocationCard.module.scss';

export type LocationCardProps = Website.Feature.Offices._Office;

const LocationCard = ({ fields = {}, url, id }: LocationCardProps): JSX.Element => {
  const addresses = getAddresses(fields);
  const sortedAddresses = addresses.sort(function streetAddressAlphaSort(a, b) {
    if (a.streetAddress < b.streetAddress) {
      return -1;
    }
    return a.streetAddress > b.streetAddress ? 1 : 0;
  });

  const { t } = useDictionary();

  const linkValue = { href: url };
  const titleId = `LocationCard_${id}`.replaceAll(' ', '');

  return (
    <div className={styles.main}>
      <LinkOrComponent className={styles['image-container']} field={{ href: url }} tabIndex={-1}>
        <ImageCta className={styles.image} field={linkValue} image={fields.tileImage} />
      </LinkOrComponent>
      <div className={styles.content}>
        <LinkOrComponent className={styles['name-container']} field={{ href: url }}>
          <Text className={styles.name} field={fields.name} tag="h3" id={titleId} />
        </LinkOrComponent>
        <ul className={styles.list}>
          {sortedAddresses.map(({ streetAddress, phone1, phone2, googleMapsLink }, index) => {
            const addressId = `address_${id}_${index}`.replaceAll(' ', '');
            let labelledBy = `${titleId}`;

            if (sortedAddresses.length > 1) {
              labelledBy += ` ${addressId}`;
            }

            return (
              <li className={styles.item} key={index}>
                <RichText
                  className={styles.address}
                  field={{ value: streetAddress }}
                  tag="span"
                  styled={false}
                  id={addressId}
                />
                {phone1 && (
                  <a className={styles.phone} href={phone1.href}>
                    {phone1.text}
                  </a>
                )}
                {phone2 && (
                  <a className={styles.phone} href={phone2.href}>
                    {phone2.text}
                  </a>
                )}
                {hasValue(googleMapsLink) && (
                  <Link
                    className={styles.map}
                    field={googleMapsLink}
                    id={`view-map_${titleId}`}
                    aria-labelledby={`view-map_${titleId} ${labelledBy}`}
                  >
                    {t('View Map')}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LocationCard;
