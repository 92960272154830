import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentHeadlineAndAbstract.module.scss';

type HeadlineAndAbstract = ComponentRendering &
  Website.Project.Main.ComponentTypes.BasicContent.HeadlineandAbstract;

const BasicContentHeadlineAndAbstract = (props: HeadlineAndAbstract): JSX.Element => {
  const { header, text, hideSeparatorLine } = props?.fields || {};
  const { hasValueOrIsEE } = useExpEditor();

  if (!hasValueOrIsEE(text)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-8')}>
      <Text tag={'h2'} field={header} className={styles['header']} />
      <RichText field={text} className={styles['text']} />
      {hideSeparatorLine?.value !== true && <div className={styles['line']} />}
    </AnimateInContainer>
  );
};

export default BasicContentHeadlineAndAbstract;
