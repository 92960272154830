import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { useSite } from 'src/hooks/useSite';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import RichText from 'components/BaseHelpers/RichText';
import SocialLinks from 'components/BaseHelpers/SocialLinks';
import StartIndex from 'components/BaseHelpers/StartIndex';
import StopIndex from 'components/BaseHelpers/StopIndex';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import Logo from '../../../assets/icons/logo.svg';
import TCHomeLogo from '../../../assets/icons/tc-home-logo.svg';

import styles from './NavigationFooter.module.scss';

export type NavigationFooterProps = {
  fields?: {
    footerLinks?: Website.Feature.Navigation.NavigationLink[];
    socialLinks?: Website.Feature.Navigation.SocialLink[];
    copyrightText?: Field<string>;
    disclaimerText?: Field<string>;
    socialLinksLabel?: Field<string>;
  };
} & ComponentProps;

const NavigationFooter = ({ fields = {} }: NavigationFooterProps): JSX.Element => {
  const { isTCSite } = useSite();
  const { copyrightText, socialLinksLabel, socialLinks, footerLinks, disclaimerText } = fields;

  return (
    <>
      <StopIndex />
      <div className={styles.main}>
        <div className={styles[`top`]}>
          {isTCSite ? (
            <TCHomeLogo className={styles[`logo`]} />
          ) : (
            <Logo className={styles[`logo`]} />
          )}
        </div>
        <div className={styles[`bottom`]}>
          <div className={styles[`spacer`]} />

          <div className={styles[`social-container`]}>
            <Text className={styles[`social-label`]} tag="h2" field={socialLinksLabel} />
            {socialLinks && socialLinks.length !== 0 && <SocialLinks socialLinks={socialLinks} />}
          </div>

          <div className={styles[`spacer`]} />
          {footerLinks && footerLinks?.length > 0 && (
            <ul className={styles[`footer-links`]}>
              {footerLinks?.map(({ fields }, index) => {
                return (
                  <li className={styles[`footer-link`]} key={`link-footer-${index}`}>
                    <UnderlineCta underlineOnHover={true} tag="a" field={fields?.link}>
                      <Text field={fields?.text} />
                    </UnderlineCta>
                  </li>
                );
              })}
            </ul>
          )}
          <Text tag="p" className={styles['copyright']} field={copyrightText} />
        </div>
        <RichText
          className={styles['disclaimer']}
          field={disclaimerText}
          styled={false}
          editable={false}
        />
      </div>
      <StartIndex />
    </>
  );
};
export default NavigationFooter;
