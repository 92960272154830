import { Field, LinkField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useRouter } from 'next/router';
import { RefObject, useEffect, useRef, useState } from 'react';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { formatInternalLinkToRelativeLink } from 'src/utils/formatInternalLinkToRelativeLink';

import Link from 'components/BaseHelpers/Link';
import { TabButton } from 'components/BaseHelpers/TabNavigation/TabButton/TabButton';
import TabPanel from 'components/BaseHelpers/TabNavigation/TabPanels';
import Text from 'components/BaseHelpers/Text';

import styles from './NavBar.module.scss';

export type Tab = {
  title?: Field<string>;
  id: string;
  htmlRef?: RefObject<HTMLLIElement>;
  content?: JSX.Element;
  name?: string;
  link?: LinkField;
  viewAllLink?: LinkField;
};

export type NavBarProps = {
  tabs: Tab[];
  onTabPanel?: (isPanelOpen: boolean) => void;
};

type CurrentTab = {
  index: number;
  tab: Tab;
  ref: HTMLButtonElement | HTMLAnchorElement | null;
};

const NavBar = ({ tabs, onTabPanel }: NavBarProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();

  const tabNavRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<(HTMLButtonElement | HTMLAnchorElement | null)[]>([]);

  const [selectedTab, setSelectedTab] = useState<CurrentTab | undefined>();
  const [hoveredTab, setHoveredTab] = useState<CurrentTab | undefined>();
  const [lastHoveredTab, setLastHoveredTab] = useState<CurrentTab | undefined>();
  const [focusTrap, setFocusTrap] = useState<boolean>(false);

  const handleSelectTab = (tab: Tab, index: number) => {
    const nextTab = { index, tab, ref: tabRefs.current[index] };

    const isNewTab = selectedTab?.tab !== tab;
    if (onTabPanel) onTabPanel(Boolean(tab.content) && isNewTab);
    setSelectedTab(isNewTab ? nextTab : undefined);
  };

  const handleMouseOver = (tab: Tab, index: number) => {
    setHoveredTab({ index, tab, ref: tabRefs.current[index] });
  };

  const handleMouseLeave = () => {
    setLastHoveredTab(hoveredTab);
    setHoveredTab(undefined);
  };

  const isSelected = (tabId: string) => {
    return tabId === selectedTab?.tab.id;
  };

  const closeTabNavigation = () => {
    setSelectedTab(undefined);
    if (onTabPanel) onTabPanel(false);
  };

  useClickOutside({
    refs: [tabNavRef],
    callback: () => {
      closeTabNavigation();
    },
  });

  const isCurrentPath = (path?: LinkField) => {
    if (path && sitecoreContext?.itemPath) {
      const currentPagePath = (sitecoreContext?.itemPath as string)
        .split('/')
        .filter((item) => item !== '')[0];

      const linkPath = path.value.href;
      if (linkPath) {
        return linkPath.includes(currentPagePath);
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    closeTabNavigation();
  }, [router.asPath]);

  useEffect(() => {
    const tabNav = tabNavRef?.current;
    tabNav?.querySelectorAll('a').forEach((aTag) => {
      aTag.addEventListener('click', closeTabNavigation);
    });
    return () => {
      tabNav?.querySelectorAll('a').forEach((aTag) => {
        aTag.removeEventListener('click', closeTabNavigation);
      });
    };
  }, []);

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (hoveredTab) return;

    timeoutRef.current = setTimeout(() => {
      setLastHoveredTab(undefined);
    }, 500);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [hoveredTab]);

  const underlineRef = hoveredTab?.ref ? hoveredTab.ref : lastHoveredTab?.ref;

  if (!hasItemsOrIsEE(tabs)) {
    return <></>;
  }

  return (
    <div ref={tabNavRef} className={styles[`main`]}>
      <div className={styles[`tabs`]}>
        <FocusTrap
          active={focusTrap}
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              setFocusTrap(false);
            },
          }}
        >
          <div className={styles[`tab-navs-outer`]}>
            <div className={styles[`tab-navs-container`]}>
              <ul className={cn(styles[`tab-navs`], 'container-12')}>
                {tabs?.map((tab, index) => {
                  const { id, title, name } = tab;
                  const selected = isSelected(tab.id);
                  const currentPath = isCurrentPath(tab?.viewAllLink || tab.link) && !selectedTab;
                  // These are not editable in sitecore due to being used outside the placeholder component so ignoring side effet unused elements
                  if (name === 'code') {
                    return null;
                  }
                  return (
                    <li className={styles['tab-navs-item']} key={`tab-${id}`}>
                      {tab?.link ? (
                        <Link
                          ref={(element) => (tabRefs.current[index] = element)}
                          className={cn(styles[`tab-nav`], {
                            [styles[`tab-nav--active`]]: selected || currentPath,
                          })}
                          onMouseLeave={() => {
                            handleMouseLeave();
                          }}
                          onMouseOver={() => {
                            handleMouseOver(tab, index);
                          }}
                          id={`tab-${id}`}
                          editable={false}
                          field={formatInternalLinkToRelativeLink(tab?.link)}
                          tabIndex={!selected && selectedTab ? -1 : 0}
                        >
                          <Text className={styles[`nav-text`]} field={title} />
                        </Link>
                      ) : (
                        <TabButton
                          ref={(element) => (tabRefs.current[index] = element)}
                          tab={tab}
                          isSelected={selected}
                          variant={'navbar'}
                          tabIndex={!selected && selectedTab ? -1 : 0}
                          onClick={() => {
                            handleSelectTab(tab, index);
                            setFocusTrap((prev) => !prev);
                          }}
                          onMouseLeave={() => {
                            handleMouseLeave();
                          }}
                          onMouseOver={() => {
                            handleMouseOver(tab, index);
                          }}
                        />
                      )}
                    </li>
                  );
                })}
                <div
                  className={styles[`underline`]}
                  aria-hidden={'true'}
                  style={{
                    width: underlineRef?.offsetWidth,
                    left: underlineRef?.offsetLeft,
                    opacity: hoveredTab || (hoveredTab && lastHoveredTab) ? 1 : 0,
                  }}
                />
              </ul>
            </div>
            <div className={styles[`panels`]}>
              {tabs?.map((tab) => {
                const { content } = tab;
                return (
                  content && (
                    <TabPanel
                      key={`tab-panel-${tab.id}`}
                      tab={tab}
                      isActive={isSelected(tab.id)}
                      variant={'navbar'}
                    >
                      {content}
                    </TabPanel>
                  )
                );
              })}
            </div>
          </div>
        </FocusTrap>
      </div>
    </div>
  );
};

export default NavBar;
