import cn from 'classnames';
import { forwardRef } from 'react';

import BaseCta, { BaseCtaProps, useBaseCta } from '../BaseCta';

import styles from './BoxCta.module.scss';

export type BoxCtaProps = BaseCtaProps & {
  centered?: boolean;
};

const BoxCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, BoxCtaProps>(
  function BoxCtaComponent(props: BoxCtaProps, ref) {
    const { className, centered, ...otherProps } = props;
    const { shouldRenderCta } = useBaseCta();

    if (!shouldRenderCta(props)) {
      return <></>;
    }

    if (centered) {
      return (
        <div className={cn(styles['outer-wrapper'], className)}>
          <BaseCta className={cn(styles.main)} {...otherProps} ref={ref} />
        </div>
      );
    }

    return <BaseCta className={cn(styles.main, className)} {...otherProps} ref={ref} />;
  }
);

export default BoxCta;
