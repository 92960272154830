import cn from 'classnames';
import { useSelect } from 'downshift';
import { useEffect } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useCoveoBuilder } from 'src/hooks/useCoveoBuilder';
import { useDictionary } from 'src/hooks/useDictionary';

import { DownshiftClickEvent } from 'src/types/downshift';

import styles from './PeopleSearch.module.scss';

const alpha = 'abcdefghijklmnopqrstuvwxyz'.split('');
const ALPHA_FIELD = 'peoplelastnameletter';
const ALPHA_LABEL = 'First letter of last name';

export const PeopleSearchAlphaFilters = (): JSX.Element => {
  const { engine, controller } = useBaseSearch();
  const { selectSingleFacetValue } = controller;
  const { t } = useDictionary();
  const [alphaController, alphaState] = useCoveoBuilder('facet', engine, {
    numberOfValues: alpha.length,
    field: ALPHA_FIELD,
    sortCriteria: 'alphanumeric',
    facetId: 'people-peoplelastnameletter',
  });
  const {
    selectItem,
    selectedItem,
    setHighlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<string>({
    items: alpha,
    initialIsOpen: false,
    defaultIsOpen: true,
    onSelectedItemChange: (changes) => {
      const { selectedItem } = changes;
      if (!selectedItem) return;
      selectAlpha(selectedItem);
    },
    isItemDisabled(item) {
      return !isEnabled(item);
    },
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      if (
        state.isOpen &&
        (type === useSelect.stateChangeTypes.ToggleButtonBlur ||
          type === useSelect.stateChangeTypes.ItemClick)
      ) {
        return { ...changes, isOpen: true };
      }
      return changes;
    },
  });

  useEffect(() => {
    if (!alphaState?.hasActiveValues && selectedItem !== null) {
      selectItem(null);
    }
  }, [alphaState?.hasActiveValues, selectItem, selectedItem]);

  const selectAlpha = (value: string) => {
    const alphaValue = alphaState?.values.find(
      (alpha) => alpha.value.toLowerCase() === value.toLowerCase()
    );
    if (!alphaValue || !alphaController) return;
    selectSingleFacetValue(
      { controller: alphaController, state: alphaState, field: ALPHA_FIELD, label: ALPHA_LABEL },
      alphaValue
    );
  };

  const isEnabled = (value: string) => {
    if (!alphaState || !alphaState?.values.length) return false;
    return (
      alphaState?.values.find((alpha) => alpha.value.toLowerCase() === value.toLowerCase()) !==
      undefined
    );
  };

  const isSelected = (value: string) => {
    const alphaValue = alphaState?.values.find(
      (alpha) => alpha.value.toLowerCase() === value.toLowerCase()
    );
    if (!alphaValue) return false;
    return alphaValue.state === 'selected';
  };

  const handleKeydown = (e: React.KeyboardEvent) => {
    if (!['ArrowLeft', 'ArrowRight'].includes(e.key)) return;

    const nextIndex = e.key === 'ArrowRight' ? highlightedIndex + 1 : highlightedIndex - 1;
    const condition =
      e.key === 'ArrowRight'
        ? (index: number) => index <= alpha.length - 1
        : (index: number) => index >= 0;
    if (!condition(nextIndex)) return;

    if (isEnabled(alpha[nextIndex])) {
      setHighlightedIndex(nextIndex);
      return;
    }

    const operation =
      e.key === 'ArrowRight' ? (index: number) => index + 1 : (index: number) => index - 1;

    for (let i = nextIndex; condition(i); i = operation(i)) {
      if (isEnabled(alpha[i])) {
        setHighlightedIndex(i);
        break;
      }
    }
  };

  const label = t(ALPHA_LABEL);

  return (
    <div className={styles['alpha-filters']}>
      <label className={cn(styles['alpha-label'], 'visually-hidden')} {...getLabelProps()}>
        {label}
      </label>
      <div
        className={styles['alpha-toggle']}
        {...getToggleButtonProps({
          onKeyDown: handleKeydown,
        })}
      ></div>
      <ul className={styles['alpha-list']} {...getMenuProps()}>
        {alpha.map((value, index) => {
          const selected = isSelected(value);
          const disabled = !isEnabled(value);
          const { onMouseMove, ...restItemProps } = getItemProps({
            item: value,
            index,
            'aria-selected': selected,
            onClick: (e: DownshiftClickEvent<HTMLLIElement>) => {
              if (selected) {
                e.nativeEvent.preventDownshiftDefault = true;
                selectItem(null);
                selectAlpha(value);
              }
            },
          });
          return (
            <li
              key={value}
              className={cn(styles['alpha-list-item'], {
                [styles['disabled-alpha']]: disabled,
                [styles['focused-alpha']]: highlightedIndex === index,
                [styles['active-alpha']]: selected,
              })}
              {...restItemProps}
            >
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
