import { SortOrder, buildFieldSortCriterion } from '@coveo/headless';
import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useMemo } from 'react';

import { useCoveoEngine } from 'src/hooks/useCoveoEngine';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';
import { createLinkObject } from 'src/utils/sitecoreFieldHelpers';

import { COVEO_FIELDS } from 'src/types/coveo';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseSearch from 'components/BaseHelpers/BaseSearch/BaseSearch';

import { InsightsTopicsContentCards } from './InsightsTopicsContentCards';

export type InsightResult = {
  id?: string;
  url?: string;
  fields?: {
    carouselImage?: ImageField;
    title?: Field<string>;
    date?: Field<string>;
    startDateandTime?: Field<string>;
    endDateandTime?: Field<string>;
    displayDate?: Field<string>;
    abstract?: Field<string>;
  };
};

export type InsightsTopicsContentProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.TopicsContent & {
    fields?: {
      pinnedInsightsIds?: Array<string>;
      search?: { viewAllLink?: { text: string; url: string } };
    };
  };

const InsightsTopicsContent = (props: InsightsTopicsContentProps): JSX.Element => {
  const topicsEngine = useCoveoEngine('ResourceCenter');
  const { isEE, hasItemsOrIsEE } = useExpEditor();
  const {
    moduleHeader,
    customViewAllLink,
    customViewAllLabel,
    hideViewAllLink,
    search = {},
    pinnedInsights = [],
    pinnedInsightsIds,
    rows,
    aiTags,
    insightTypes,
  } = props.fields || {};

  const { viewAllLink } = search;
  const isUpcomingEvent = useMemo(() => {
    return insightTypes?.find((type) => type.fields?.name?.value === 'Upcoming Events');
  }, [insightTypes]);
  const initialQuery = useMemo(() => {
    if (!aiTags || !aiTags.length) return;
    return aiTags[0].fields?.name?.value;
  }, [aiTags]);
  const constantQuery = useMemo(() => {
    if (!insightTypes || !insightTypes.length) return;
    return `@z95xtemplatename==(${insightTypes
      .map((type) => `"${type.fields?.templateName?.value}"`)
      .join(',')})`;
  }, [insightTypes]);
  const advancedQuery = useMemo(() => {
    const ids =
      pinnedInsightsIds && pinnedInsightsIds.length
        ? `NOT @permanentid==(${pinnedInsightsIds.join(',')})`
        : '';
    const contentType = isUpcomingEvent ? `@${COVEO_FIELDS.dateRange}>now` : '';
    return `${ids} ${contentType}`.trim();
  }, [isUpcomingEvent, pinnedInsightsIds]);
  const sortOrder = useMemo(() => {
    return isUpcomingEvent ? SortOrder.Ascending : SortOrder.Descending;
  }, [isUpcomingEvent]);
  const sortCriterion = useMemo(
    () => buildFieldSortCriterion(COVEO_FIELDS.dateRange, sortOrder),
    [sortOrder]
  );

  if (!hasItemsOrIsEE(pinnedInsights) && !hasItemsOrIsEE(insightTypes) && !hasItemsOrIsEE(aiTags)) {
    return <></>;
  }

  const linkField = hasValue(customViewAllLink)
    ? customViewAllLink
    : createLinkObject(viewAllLink?.url);
  const linkText = hasValue(customViewAllLabel)
    ? customViewAllLabel
    : { value: viewAllLink?.text || '' };
  const showCta =
    isEE || (hideViewAllLink?.value !== true && hasValue(linkField) && hasValue(linkText));

  return (
    <BaseSearch
      engine={topicsEngine}
      resultConfig={{ sortCriterion, alwaysShow: true }}
      facetConfig={{ facetFields: [COVEO_FIELDS.topics] }}
      queryConfig={{ advancedQuery, constantQuery, initialQuery }}
      updateUrl={false}
    >
      <InsightsTopicsContentCards
        moduleHeader={moduleHeader}
        showCta={showCta}
        ctaLink={linkField}
        ctaText={linkText}
        pinnedInsights={pinnedInsights}
        rows={rows}
      />
    </BaseSearch>
  );
};

export default InsightsTopicsContent;
