import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram.svg';
import linkedin from '../../../assets/icons/linkedin.svg';
import mail from '../../../assets/icons/mail.svg';
import twitter from '../../../assets/icons/twitter-x.svg';
import wechat from '../../../assets/icons/we-chat.svg';
import youtube from '../../../assets/icons/youtube.svg';

import styles from './SocialIcons.module.scss';

export type SocialIconName =
  | 'Facebook'
  | 'Twitter'
  | 'LinkedIn'
  | 'WeChat'
  | 'Instagram'
  | 'YouTube'
  | 'Mail';

export type SocialIconsProps = {
  iconName: SocialIconName;
  theme: string;
};

export type SocialIconsComponentsObject = {
  [key in SocialIconName]: React.ElementType;
};

const socialIconComponents: SocialIconsComponentsObject = {
  Facebook: facebook,
  Twitter: twitter,
  LinkedIn: linkedin,
  WeChat: wechat,
  Instagram: instagram,
  YouTube: youtube,
  Mail: mail,
};

const SocialIcons = ({ iconName, theme }: SocialIconsProps): JSX.Element => {
  if (iconName && socialIconComponents[iconName]) {
    const TagName = socialIconComponents[iconName];
    const themeClassName = theme === 'light' ? styles[`main-light`] : styles[`main-dark`];
    return <TagName className={themeClassName} alt={iconName} />;
  }
  return <></>;
};

export default SocialIcons;
