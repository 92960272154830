import { ComponentRendering, Field, HtmlElementRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import { cloneElement } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

export const PlaceholderList = <T extends Record<string, Field<string>> | undefined>({
  components,
  data,
  shouldRender,
  classNames,
}: {
  components: Array<React.ReactNode>;
  data: (ComponentRendering | HtmlElementRendering)[];
  shouldRender: (fields: T | undefined) => boolean;
  classNames?: { list?: string; item?: string };
}) => {
  const { isEE, isHtmlRendering } = useExpEditor();
  return (
    <ul className={classNames?.list}>
      {components
        .filter((component) => {
          const typedComponent = component as { props: { fields: T } };
          return isEE || shouldRender(typedComponent?.props?.fields);
        })
        .map((component, index) => {
          const componentData = data[index];
          // in EE, this array contains components that help render the Sitecore UI elements
          // so we need to return them as-is
          if (isHtmlRendering(componentData)) return component;

          return (
            <li key={componentData.uid} className={classNames?.item}>
              {cloneElement(component as JSX.Element, { index })}
            </li>
          );
        })}
    </ul>
  );
};
