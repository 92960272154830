import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useRef, useState } from 'react';

import { LinkProps } from 'components/BaseHelpers/Link';

import { useSite } from './useSite';

export type Count = { viewing: number; total: number };
export type Params = Record<string, string>;

interface UseSearchArgs {
  path: string;
  pageSize?: number;
}
interface UseSearchReturn<T> {
  updateParams: (params: UpdateParams) => void;
  response: { data: Array<T>; count: Count; isLoading: boolean; isError: boolean };
  params: Params;
}

type UpdateParams = { key: string; value: string };

export interface TypeaheadItem {
  label?: string;
  name?: string;
  url: LinkProps;
  title?: string;
  type?: string;
}

export function useSearch<T>({ path, pageSize = 3 }: UseSearchArgs): UseSearchReturn<T> {
  const { sitecoreContext } = useSitecoreContext();
  /* data fetching state */
  // response data from the API -- uses generics for accurate typing outside of hook
  const [data, setData] = useState<Array<T>>([]);
  // loading state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // error state
  const [isError, setIsError] = useState<boolean>(false);
  /* query param state */
  // so we don't try to set state when the component unmounts
  const mounted = useRef<boolean>(true);

  // total results and how many we're currently viewing
  const [count, setCount] = useState<Count>({ viewing: 0, total: 0 });
  const [params, setParams] = useState<Params>({});
  const { isTCSite } = useSite();
  const site = isTCSite ? 'tc' : 'main';

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    newSearch();
  }, [params]);

  const updateParams = (updateParams: UpdateParams) => {
    if (updateParams.value) {
      const newParams = {
        ...params,
        [updateParams.key]: updateParams.value,
      };
      setParams(newParams);
    } else {
      const {
        [updateParams.key]: _undefinedVar, //remove key from object if value is undefined
        ...newParams
      } = params;
      setParams(newParams);
    }
  };

  const newSearch = () => {
    setIsLoading(true);
    fetchContent({ path, take: pageSize, query: params })
      .then((response) => {
        if (mounted.current) {
          setData(response?.results || []);
          setCount({ viewing: pageSize, total: response?.totalFound });
          return response;
        }
      })
      .catch((error: unknown) => {
        if (mounted.current) {
          setIsError(true);
          console.error('[Error fetching data]: ', error);
        }
      })
      .finally(() => {
        if (mounted.current) setIsLoading(false);
      });
  };
  const fetchContent = async ({ path, take, query }: FetchContent) => {
    if (Object.keys(query).length === 0 || query?.query?.length < 3) {
      return;
    }

    const baseUrl = `/sitecore/api/${path}`;

    const params = new URLSearchParams({
      ...(query || {}),
      take: take.toString(),
      sc_lang: sitecoreContext?.language || 'en',
      sc_site: process.env.NEXT_PUBLIC_SC_SITE_NAME || site,
    });

    const url = `${baseUrl}?${params.toString()}`;

    const response = await fetch(url);
    const data = await response.json();

    return data;
  };

  return {
    updateParams,
    response: { data, count, isLoading, isError },
    params,
  };
}

interface FetchContent {
  path: string;
  take: number;
  query: Params;
}
