import cn from 'classnames';
import { useMemo } from 'react';

import { useEngineContext } from 'src/hooks/useCoveoEngine';

import { executeManualTemplateSelect } from 'src/utils/coveo';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import { BaseLinkCard } from 'components/BaseHelpers/BaseLinkCard/BaseLinkCard';
import BoxCta from 'components/BaseHelpers/BoxCta';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import Text from 'components/BaseHelpers/Text';

import styles from './InsightsLatestInsights.module.scss';

export type InsightsLatestInsightsProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.LatestInsights & {
    fields: {
      search: {
        totalFound: number;
        results: Array<{
          title: string;
          bodyText: string;
          date: string;
          displayDate?: string;
          url: string;
          image: string;
        }>;
      };
    };
  };

const InsightsLatestInsights = (props: InsightsLatestInsightsProps): JSX.Element => {
  const { moduleHeader, search, displayOneRow, viewAllInsightsLabel } = props.fields || {};
  const { results } = search || {};
  const { insightsEngine } = useEngineContext();

  const insights = useMemo(() => {
    const endIndex = displayOneRow?.value ? 3 : 6;
    return (results || []).slice(0, endIndex);
  }, [displayOneRow, results]);

  if (!results || !insights.length) return <></>;

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer', 'container-10')}>
      <ModuleHeader header={moduleHeader} />
      <ul className={styles['insights-list']}>
        {insights.map((insight) => {
          return (
            <li key={insight.url} className={styles['insights-list-item']}>
              <BaseLinkCard
                className={styles['insights-card']}
                image={{ value: { src: insight.image, alt: insight.image ? insight.title : '' } }}
                url={insight.url}
              >
                <BaseLinkCard.Date field={insight.date} displayDate={insight.displayDate} />
                <BaseLinkCard.Title field={{ value: insight.title }} underline={true} />
              </BaseLinkCard>
            </li>
          );
        })}
      </ul>
      <BoxCta
        tag="button"
        centered={true}
        onClick={() => {
          executeManualTemplateSelect(insightsEngine, 'Publication');
        }}
      >
        <Text field={viewAllInsightsLabel} />
      </BoxCta>
    </AnimateInContainer>
  );
};

export default InsightsLatestInsights;
