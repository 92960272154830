import React, { createElement } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';

import Link, { LinkProps } from '../Link/Link';

type LinkOrComponentProps = {
  tag?: keyof HTMLElementTagNameMap;
} & LinkProps;

const LinkOrComponent = (props: LinkOrComponentProps): JSX.Element => {
  const { isEE } = useExpEditor();
  const hasLink = hasValue(props.field) && !isEE;
  return hasLink ? (
    <Link {...props} />
  ) : (
    createElement(props.tag || 'div', { className: props.className }, props.children)
  );
};

export default LinkOrComponent;
