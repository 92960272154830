import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useDictionary } from 'src/hooks/useDictionary';

import BoxCta from '../BoxCta';
import { BaseBreadcrumbs } from './BaseBreadcrumbs';
import { BaseResultStats } from './BaseResultStats';
import BaseResultsHeader from './BaseResultsHeader';

import styles from './BaseResults.module.scss';

interface BaseResultsProps {
  fallback?: JSX.Element;
  noResults?: JSX.Element;
  className?: string;
  statsLayout?: 'column' | 'row';
  statsTemplate: Field<string> | undefined;
  correctionTemplate: Field<string> | undefined;
  children: React.ReactNode;
  showHeader?: boolean;
}

export const BaseResults = ({
  fallback,
  noResults,
  statsLayout,
  statsTemplate,
  correctionTemplate,
  className,
  children,
  showHeader = true,
}: BaseResultsProps) => {
  const { state, controller, refs } = useBaseSearch();
  const { showResults, hasResults, moreResultsAvailable, isLoading, didYouMean } = state;
  const { loadMoreResults } = controller;
  const { resultsRef } = refs;
  const { t } = useDictionary();
  const headingRef = useRef<HTMLHeadingElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const indexToFocus = useRef<number | undefined>();

  useEffect(() => {
    if (isLoading) return;

    if (!showResults) {
      indexToFocus.current = undefined;
      return;
    }

    let elementToFocus: HTMLElement | null = headingRef.current;
    const index = indexToFocus.current;
    if (index) {
      const resultsContainer = resultsRef.current;
      const results = resultsContainer?.querySelectorAll('a');
      if (results && results[index]) elementToFocus = results[index];
    }
    timeoutRef.current = setTimeout(() => {
      elementToFocus?.focus({ preventScroll: true });
    }, 300);

    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, showResults]);

  const handleClick = () => {
    const resultsContainer = resultsRef.current;
    const results = resultsContainer?.querySelectorAll('a');
    if (results) indexToFocus.current = results.length;
    loadMoreResults();
  };

  const statsLayoutClass =
    statsLayout === 'column' ? styles['stats-container-column'] : styles['stats-container-row'];
  const queryCorrectionTemplate =
    correctionTemplate?.value || 'No results for {1}. Showing results for {0} instead.';
  const correctionText = didYouMean.hasQueryCorrection
    ? `${queryCorrectionTemplate.replace('{1}', `<em>${didYouMean.originalQuery}</em>`).replace('{0}', `<em>${didYouMean.correctedQuery}</em>`)}`
    : undefined;

  if (!showResults && fallback) return fallback;

  return (
    <div className={cn(styles['base-results'], className)} ref={resultsRef}>
      <div className={cn(styles['stats-container'], statsLayoutClass)}>
        <BaseBreadcrumbs />
        <BaseResultStats
          statsTemplate={statsTemplate}
          className={cn({ [styles['result-stats-row']]: statsLayout === 'row' })}
        />
      </div>
      {didYouMean.hasQueryCorrection && correctionText && (
        <p
          className={styles['query-correction']}
          dangerouslySetInnerHTML={{ __html: correctionText }}
        ></p>
      )}
      <h2 id="results-heading" className="visually-hidden" ref={headingRef} tabIndex={-1}>
        Search Results
      </h2>
      {showHeader && showResults && hasResults && <BaseResultsHeader />}
      {showResults && hasResults ? children : noResults}
      {moreResultsAvailable && (
        <BoxCta onClick={handleClick} tag="button" centered={true}>
          {t('Load More')}
        </BoxCta>
      )}
    </div>
  );
};
