import cn from 'classnames';
import { Children, ReactNode } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useTemplate } from 'src/hooks/useTemplate';

import { isInLastRow } from 'src/utils/isInLastRow';

import styles from './BaseHighlights.module.scss';

export type BaseHighlightsProps = {
  children?: ReactNode[];
};

const BaseHighlights = (props: BaseHighlightsProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const { children } = props;
  const arrayChildren = Children.toArray(children);
  const { isPeopleDetail } = useTemplate();

  let columns = arrayChildren.length >= 3 ? 3 : arrayChildren.length;

  if (isPeopleDetail) {
    columns = 2;
  }

  const columnsStyles = [`highlights--one`, `highlights--two`, `highlights--three`];
  if (!hasItemsOrIsEE(children)) {
    return <></>;
  }
  return (
    <ul className={cn(styles[`highlights`], styles[columnsStyles[columns - 1]])}>
      {arrayChildren?.map((child, index) => {
        return (
          <li
            key={`highlight-${index}`}
            className={cn(styles[`highlight-container`], {
              [styles['highlight-container--last-row']]: isInLastRow(
                index,
                arrayChildren.length,
                columns
              ),
            })}
          >
            <div className={styles[`highlight`]}>{child}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default BaseHighlights;
