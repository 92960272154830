import cn from 'classnames';
import React, { createElement, useCallback, useEffect, useRef, useState } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

import { useExpEditor } from 'src/hooks/useExpEditor';

import styles from './AnimateInContainer.module.scss';

export type AnimateInContainerProps = {
  tag?: keyof JSX.IntrinsicElements;
  options?: IntersectionOptions;
  className?: string;
  inViewClass?: string;
  notInViewClass?: string;
  startCondition?: boolean;
  children: React.ReactNode;
  [key: string]: unknown;
};

const DEFAULT_OPTIONS = {
  threshold: 0,
  rootMargin: '0% 0% 24px 0%',
  triggerOnce: true,
};

const AnimateInContainer = (props: AnimateInContainerProps): JSX.Element => {
  const {
    tag = 'div',
    className,
    inViewClass,
    notInViewClass,
    startCondition = true,
    children,
    options,
    ...restProps
  } = props;
  const { isEE } = useExpEditor();

  const [showAnimation, setShowAnimation] = useState<boolean>(
    !isEE && !process.env.STORYBOOK_TESTRUNNER
  );

  const { ref: inViewRef, inView } = useInView({
    ...(options || DEFAULT_OPTIONS),
    skip: !showAnimation,
  });
  const notInViewClassName = styles[notInViewClass || ''] || styles['main'];
  const inViewClassName = styles[inViewClass || ''] || styles['in-view'];
  const ref = useRef<HTMLElement | null>(null);

  const setRefs = useCallback(
    (node: HTMLElement) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  useEffect(() => {
    if (window !== undefined && ref?.current?.offsetTop) {
      if (window?.scrollY >= ref?.current?.offsetTop) {
        setShowAnimation(false);
      }
    }
  }, []);

  return createElement(
    tag,
    {
      ...restProps,
      ref: setRefs,
      className: cn(
        {
          [inViewClassName]: inView && showAnimation && startCondition,
          [notInViewClassName]: showAnimation,
        },
        className
      ),
    },
    children
  );
};

export default AnimateInContainer;
