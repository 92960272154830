import { Field, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useGlobalStore } from 'src/hooks/useGlobalStore';
import { useImagesLoaded } from 'src/hooks/useImagesLoaded';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useTemplate } from 'src/hooks/useTemplate';

import { hasValue } from 'src/utils/hasValue';

import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import BoxCta from '../BoxCta';
import { HomeLogo } from './HomeLogo';
import ScrollButton from './ScrollButton';

import styles from './HorizontalImageSplitHero.module.scss';

export type HorizontalImageSplitHeroProps =
  Website.Project.Main.ComponentTypes.BasicContent.HorizontalImageSplitHero & {
    fields?: {
      linkUrl?: Field<string>;
      linkText?: Field<string>;
      linkTarget?: Field<string>;
    };
  };

const HorizontalImageSplitHero = (props: HorizontalImageSplitHeroProps): JSX.Element => {
  const { fields = {} } = props;
  const { hasValueOrIsEE, isEE } = useExpEditor();
  const { isHomePage } = useTemplate();
  const { homeAnimationPlayed } = useGlobalStore();
  const { imagesContainerRef, isImagesLoaded } = useImagesLoaded();
  const {
    eyebrow,
    description,
    image1,
    image2,
    headline,
    mobileImage1,
    mobileImage2,
    linkUrl,
    linkText,
    linkTarget,
  } = fields;

  const isMobile = useMediaQuery('(max-width: 1024px)');

  let finalImage1: ImageField | undefined;
  let finalImage2: ImageField | undefined;
  // If isMobile and at least one mobile image is set, use mobile images
  if (isMobile && (hasValueOrIsEE(mobileImage1) || hasValueOrIsEE(mobileImage2))) {
    finalImage1 = mobileImage1;
    finalImage2 = mobileImage2;
  } else {
    finalImage1 = image1;
    finalImage2 = image2;
  }

  return (
    <div
      ref={imagesContainerRef}
      className={cn(
        styles.main,
        { 'container-full-width': !isHomePage },
        { 'container-full-width-1920': isHomePage },
        'spacer',
        { [styles[`homepage`]]: isHomePage },
        { [styles[`images-loaded`]]: isImagesLoaded },
        { [styles['no-images']]: !hasValueOrIsEE(finalImage1) && !hasValueOrIsEE(finalImage2) }
      )}
    >
      <div className={styles.images}>
        {hasValueOrIsEE(finalImage1) && (
          <div
            className={cn(
              styles[`image`],
              styles[`one`],
              styles[`overlay`],
              'exp-editor-img-container'
            )}
          >
            <Image field={finalImage1} alt="" fill={true} priority={true}></Image>
          </div>
        )}
        {hasValueOrIsEE(finalImage2) && (
          <div
            className={cn(
              styles[`image`],
              styles[`two`],
              styles[`overlay`],
              'exp-editor-img-container'
            )}
          >
            <Image field={finalImage2} alt="" fill={true} priority={true}></Image>
          </div>
        )}
        {isHomePage && !isEE && <HomeLogo isImagesLoaded={isImagesLoaded} />}
      </div>
      <div className={cn(styles[`content`], 'container-10')}>
        {(isEE || (homeAnimationPlayed && isImagesLoaded) || (!isHomePage && isImagesLoaded)) && (
          <AnimateInContainer className={styles[`header-text`]}>
            <Text field={eyebrow} className={styles[`eyebrow`]} />
            <Text field={headline} className={styles[`headline`]} tag={isHomePage ? 'h2' : 'h1'} />
            <RichText className={styles[`description`]} field={description} />
            {hasValue(linkText) && hasValue(linkUrl) && (
              <BoxCta
                className={styles.cta}
                tag="a"
                field={linkUrl?.value}
                target={linkTarget?.value}
              >
                <Text field={linkText} />
              </BoxCta>
            )}
          </AnimateInContainer>
        )}
        {isHomePage && homeAnimationPlayed && (
          <AnimateInContainer className={styles['scroll-container']}>
            <ScrollButton />
          </AnimateInContainer>
        )}
      </div>
    </div>
  );
};

export default HorizontalImageSplitHero;
