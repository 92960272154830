import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { ChartDataShape, ChartType } from 'src/componentsNonSSR/InteractiveChart/InteractiveChart';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import Text from 'components/BaseHelpers/Text';

import styles from './BasicContentInfographic.module.scss';

const InteractiveChart = dynamic(() => import('../../../componentsNonSSR/InteractiveChart'), {
  ssr: false,
});

export type BasicContentInfographicProps = {
  fields: {
    dataPoints?: ChartDataShape;
  };
} & ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Infographic;

const BasicContentInfographic = ({
  fields = {},
  rendering,
}: BasicContentInfographicProps): JSX.Element => {
  const { isEE, hasItemsOrIsEE } = useExpEditor();

  const size = fields?.fullWidth?.value ? 'full' : 'half';
  const isHalfSized = size === 'half';

  const chartType = fields?.graphType?.value as unknown as ChartType;
  const dataPoints = fields?.dataPoints && hasItems(fields.dataPoints) ? fields.dataPoints : [];
  const hasPlaceholderItems = hasItems(rendering?.placeholders?.['infographic-rich-text']);

  // Don't display component without any DataPoints Present or ChartType Selected
  // But In EE, even though the chart isn't rendered we have a special note included to help content editors
  if (!hasItemsOrIsEE(fields?.dataPoints)) {
    return <></>;
  }
  return (
    <div
      className={cn(
        styles.main,
        { [styles['main-flex']]: isHalfSized },
        { [styles['main-flex--reverse']]: isHalfSized && fields?.displayTextOnLeft?.value },
        'container-8',
        'spacer'
      )}
    >
      <div className={cn(styles.column, styles['chart-module'])}>
        <Text className={cn(styles.title)} field={fields.title} tag={'h2'} />
        <Text className={cn(styles.subtitle)} field={fields.subtitle} tag={'div'} />
        {!isEE ? (
          <div className={cn({ [styles.barchart]: chartType === 'Bar Graph' })}>
            <InteractiveChart
              id={`chart-${rendering?.uid}`}
              size={size}
              chartType={chartType}
              data={dataPoints}
            />
          </div>
        ) : (
          <div className={cn(styles.note)}>
            Interactive Chart is not Visible in Experience Editor Mode. Please View in Preview or
            Publish your changes. Ensure ChartType is selected and Datapoints are added to the
            component.
          </div>
        )}
      </div>
      {isHalfSized && hasPlaceholderItems ? (
        <div className={cn(styles.column, styles.supplementalText)}>
          <Placeholder name="infographic-rich-text" rendering={rendering} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BasicContentInfographic;
