import { useContext } from 'react';

import {
  BaseSearchContext,
  BaseSearchContextValue,
} from 'components/BaseHelpers/BaseSearch/BaseSearch';

export const useBaseSearch = (): BaseSearchContextValue => {
  const context = useContext<BaseSearchContextValue | undefined>(BaseSearchContext);
  if (context === undefined) {
    throw new Error('useBaseSearch must be used within a BaseHelpers/BaseSearch component');
  }
  return context;
};
