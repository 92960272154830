import cn from 'classnames';
import { useRef } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import PlayButton from 'components/BaseHelpers/PlayButton';
import Text from 'components/BaseHelpers/Text';
import VideoModal, { VideoModalRef } from 'components/BaseHelpers/VideoModal';

import styles from './BasicContentVideo.module.scss';

export type BasicContentVideoProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Video & { isMediaGrid?: boolean };

const BasicContentVideo = (props: BasicContentVideoProps): JSX.Element => {
  const { t } = useDictionary();
  const { hasValueOrIsEE } = useExpEditor();
  const modalRef = useRef<VideoModalRef>(null);
  const launchButtonRef = useRef<HTMLButtonElement>(null);
  const { ctaLink, headline, video, image } = props.fields || {};

  const handleClick = () => {
    modalRef.current?.openModal({ launchElement: launchButtonRef.current });
  };
  if (!hasValueOrIsEE(video) || !hasValueOrIsEE(image)) return <></>;

  return (
    <AnimateInContainer
      className={cn(styles.main, {
        [styles['media-grid-item']]: props.isMediaGrid,
        spacer: !props.isMediaGrid,
        'container-10': !props.isMediaGrid,
      })}
    >
      <div className={cn(styles['image-container'], 'exp-editor-img-container')}>
        <Image field={image} fill={true} alt="" />
        <div className={styles['play-button-container']}>
          <PlayButton
            className={styles['play-button']}
            label={headline}
            onClick={handleClick}
            ref={launchButtonRef}
            variant={'light'}
          />
        </div>
      </div>
      {!props.isMediaGrid && (
        <>
          <Text field={headline} className={styles['headline']} />
          <BoxCta tag={'a'} field={ctaLink} className={styles['cta']}>
            {t('Learn More')}
          </BoxCta>
        </>
      )}
      <VideoModal ref={modalRef} video={video} />
    </AnimateInContainer>
  );
};

export default BasicContentVideo;
