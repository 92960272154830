import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { getEmail as getEmailUtil } from 'src/utils/getEmail';
import { getName as getNameUtil } from 'src/utils/getName';
import { getPhone } from 'src/utils/getPhone';

import { Website } from 'lib/component-props/model';

import { useExpEditor } from './useExpEditor';
import { useSite } from './useSite';

export type PeopleInfoProps = Website.Project.Main.PageTypes.People.Professional['fields'];

export const usePeopleInfo = (fields: PeopleInfoProps = {}) => {
  const { sitecoreContext } = useSitecoreContext();
  const { isEE } = useExpEditor();
  const { isTCSite } = useSite();

  const getName = () => {
    return getNameUtil(fields, sitecoreContext.language, isEE);
  };

  const getPractices = () => {
    const { keyPractices, keyPracticesTC, services, servicesTC } = fields;

    const keyPracticesList = isTCSite ? keyPracticesTC : keyPractices;
    if (Array.isArray(keyPracticesList) && keyPracticesList.length) {
      return {
        value: keyPracticesList
          .map((item) => item.fields?.name?.value)
          .filter(Boolean)
          .join(', '),
      };
    }

    const servicesList = isTCSite ? servicesTC : services;
    const items = Array.isArray(servicesList) && servicesList.length ? servicesList : [];

    return {
      value: items
        .map((item) => item.fields?.name?.value)
        .filter(Boolean)
        .slice(0, 3)
        .join(', '),
    };
  };

  const getOfficesPhones = () => {
    const getOffices = () => {
      const offices = Array.isArray(fields.offices) ? fields.offices : [];
      return offices
        .map((office) => {
          if (office?.fields?.name?.value) {
            return {
              href: office.url || '',
              text: office.fields.name?.value,
            };
          }
          return;
        })
        .slice(0, 3);
    };

    const offices = getOffices();
    const phones = [fields.phone1, fields.phone2, fields.phone3].map(getPhone);

    type Link = { text: string; href: string };
    type OfficePhone = {
      office?: Link;
      phone?: Link;
    };
    const results: OfficePhone[] = [];

    for (let i = 0; i < Math.max(offices.length, phones.length); i++) {
      results.push({
        office: offices[i],
        phone: phones[i],
      });
    }
    return results.filter((item) => Boolean(item.office || item.phone));
  };

  const getEmail = () => {
    return getEmailUtil(fields.businessEmail?.value || '');
  };

  return {
    level: fields.level?.fields?.name,
    title: fields.title,
    department: fields.department,
    name: getName(),
    practices: getPractices(),
    officesPhones: getOfficesPhones(),
    email: getEmail(),
    image: fields?.image,
  };
};
