import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { Website } from 'lib/component-props/model';

export const usePeopleDetailFields = () => {
  const { sitecoreContext } = useSitecoreContext();
  return (sitecoreContext.route?.fields || {}) as NonNullable<
    Website.Project.Main.PageTypes.People.Professional['fields']
  >;
};
