import { RefObject, useEffect } from 'react';

import { useMediaQuery } from './useMediaQuery';

/**
 * @param {Array<RefObject<HTMLElement>>} refs - An array of refs containing the elements that you want to be clickable. For example -- in a modal, instead of passing the container, you can pass the content and the close button so empty space between elements will fire `callback` when clicked
 * @param {() => void} callback - a function that will fire when a click is registered outside of your passed ref elements or when the `Esc` key is pressed
 */
export const useClickOutside = ({
  refs,
  callback,
  resize = true,
}: {
  refs: Array<RefObject<HTMLElement>>;
  callback: () => void;
  resize?: boolean;
}) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    const clickOutside = (e: Event) => {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];
        if (!ref?.current || ref?.current.contains(e.target as Element)) {
          return;
        }
      }
      callback();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        e.preventDefault();
        callback();
      }
    };
    const handleResize = () => {
      if (isMobile) return;
      callback();
    };

    window.addEventListener('mouseup', clickOutside);
    document.addEventListener('keyup', handleKeyDown);
    if (resize) window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('mouseup', clickOutside);
      document.addEventListener('keyup', handleKeyDown);
      if (resize) window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
};
