import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useDictionary } from 'src/hooks/useDictionary';

import { getStringFieldValue } from 'src/utils/sitecoreFieldHelpers';

import { SearchBox } from './SearchBox';

interface BaseSearchBoxProps {
  placeholder?: Field<string> | string | undefined;
  topics?: Array<string>;
  showSuggestions?: boolean;
  className?: string;
}

export const BaseSearchBox = ({
  placeholder,
  topics,
  showSuggestions = true,
  className,
}: BaseSearchBoxProps): JSX.Element => {
  const { t } = useDictionary();
  const { state, controller } = useBaseSearch();
  const { query, suggestions } = state;
  const { updateQueryText, submitSearch, selectQuerySuggestion } = controller;

  const placeholderValue = getStringFieldValue(placeholder) || t('SEARCH');

  return (
    <SearchBox
      id="keyword-search-input"
      value={query}
      placeholder={placeholderValue}
      updateText={updateQueryText}
      submitSearch={submitSearch}
      className={className}
      selectSuggestion={selectQuerySuggestion}
      suggestions={showSuggestions ? suggestions : undefined}
      topics={topics}
    />
  );
};
