import { SearchEngine, UrlManager, buildUrlManager } from '@coveo/headless';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { useExpEditor } from './useExpEditor';

export const useUrlManager = (engine: SearchEngine, updateUrl: boolean | undefined) => {
  const { isEE } = useExpEditor();
  const router = useRouter();
  const urlManager = useRef<UrlManager | null>(null);

  useEffect(() => {
    if (isEE || !updateUrl) return;

    if (!urlManager.current) {
      const fragment = router.asPath.includes('?') ? router.asPath.split('?')[1] : '';
      urlManager.current = buildUrlManager(engine, {
        initialState: { fragment },
      });
    }
    const unsubUrlManager = urlManager.current.subscribe(() => {
      router.push(
        { pathname: getBasePath(router.asPath), query: urlManager.current?.state.fragment },
        undefined,
        {
          shallow: true,
          scroll: false,
        }
      );
    });

    return () => {
      unsubUrlManager();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEE || !updateUrl) return;

    if (getBasePath(router.asPath) === `/${router.locale}`) return;

    router.beforePopState((state) => {
      if (getBasePath(state.as) === getBasePath(`/${router.locale}${router.asPath}`)) {
        const query = state.as.split('?')[1] || '';
        urlManager.current?.synchronize(query);
      }

      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return urlManager.current;
};

const getBasePath = (asPath: string) => {
  const indexOfQueryStringStart = asPath.indexOf('?');
  return indexOfQueryStringStart > -1 ? asPath.slice(0, indexOfQueryStringStart) : asPath;
};
