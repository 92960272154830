import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useBaseSearch } from 'src/hooks/useBaseSearch';

import { BaseResults } from 'components/BaseHelpers/BaseSearch/BaseResults';
import RichText from 'components/BaseHelpers/RichText';

import { PeopleResult } from './PeopleResult';

import styles from './PeopleResults.module.scss';

interface PeopleResultsProps {
  fallback?: JSX.Element;
  statsTemplate: Field<string> | undefined;
  correctionTemplate: Field<string> | undefined;
  noResultsText: Field<string> | undefined;
}

export const PeopleResults = ({
  statsTemplate,
  noResultsText,
  correctionTemplate,
  fallback = <></>,
}: PeopleResultsProps) => {
  const { state } = useBaseSearch();
  const { results } = state;

  return (
    <BaseResults
      className={cn(styles['people-results'], 'spacer', 'container-12')}
      statsLayout={'column'}
      correctionTemplate={correctionTemplate}
      statsTemplate={statsTemplate}
      noResults={<RichText field={noResultsText} styled={true} />}
      fallback={fallback}
      showHeader={false}
    >
      <ul className={styles['results-list']}>
        {results.map((result) => {
          return (
            <li key={result.uniqueId} className={styles['results-list-item']}>
              <PeopleResult result={result} />
            </li>
          );
        })}
      </ul>
    </BaseResults>
  );
};
