import { Facet } from '@coveo/headless';
import { useEffect, useMemo } from 'react';

import { useBaseSearch } from 'src/hooks/useBaseSearch';
import { useCoveoBuilder } from 'src/hooks/useCoveoBuilder';
import { useDictionary } from 'src/hooks/useDictionary';

import { FacetDropdown } from './FacetDropdown';

interface SectionFacetProps {
  facetLabels: Array<string>;
  sectionLabel: string;
  onSelectSection: (field: string) => void;
}

const SECTION_FIELD = 'sectiontitle';

export const SectionFacet = ({ sectionLabel, facetLabels, onSelectSection }: SectionFacetProps) => {
  const { engine } = useBaseSearch();
  const { t } = useDictionary();
  const [facetController, facetState] = useCoveoBuilder('facet', engine, {
    field: SECTION_FIELD,
    sortCriteria: 'alphanumeric',
    numberOfValues: 10,
    facetId: `search-${SECTION_FIELD}`,
  });

  useEffect(() => {
    const currentSection =
      facetState?.values.find((value) => value.state === 'selected')?.value || 'All Results';
    onSelectSection(currentSection);
  }, [facetState?.values, onSelectSection]);

  const sections = useMemo(() => {
    return facetLabels.map((label) => ({
      raw: label,
      label: t(label),
    }));
  }, [facetLabels, t]);

  return (
    <FacetDropdown
      facet={{
        controller: facetController as Facet,
        state: facetState,
        label: sectionLabel,
        field: SECTION_FIELD,
      }}
      label={sectionLabel}
      sections={sections}
    />
  );
};
