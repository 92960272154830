import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { usePeopleDetailFields } from 'src/hooks/usePeopleDetailFields';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import EditFrame from 'components/BaseHelpers/EditFrame';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import { richTextStyles } from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import styles from './PeopleLanguages.module.scss';

export type PeopleLanguagesProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Languages;

const PeopleLanguages = ({ fields = {} }: PeopleLanguagesProps): JSX.Element => {
  const { moduleHeader } = fields;
  const { spokenLanguages } = usePeopleDetailFields();
  const { hasItemsOrIsEE, noItemsAndIsEE } = useExpEditor();
  const {
    sitecoreContext: { itemId },
  } = useSitecoreContext();

  if (!hasItemsOrIsEE(spokenLanguages)) {
    return <></>;
  }

  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader header={moduleHeader} />
      <EditFrame itemId={itemId} fields={['Spoken Languages']}>
        {noItemsAndIsEE(spokenLanguages) && (
          <span>Spoken Languages: [No data entered]. Click here to add.</span>
        )}
        <div className={richTextStyles.main}>
          <ul>
            {spokenLanguages?.map((item) => (
              <li key={item.id}>
                <Text field={item.fields?.name} editable={false} />
              </li>
            ))}
          </ul>
        </div>
      </EditFrame>
    </AnimateInContainer>
  );
};

export default PeopleLanguages;
