import { Text as JssText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

export type TextProps = ComponentProps<typeof JssText>;

const Text = (props: TextProps): JSX.Element => {
  const { hasValueOrIsEE, isEE } = useExpEditor();

  if (hasValueOrIsEE(props.field)) {
    return <JssText encode={isEE} tag="span" {...props} />;
  }

  return <></>;
};

export default Text;
