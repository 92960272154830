import { HighlightUtils, Result } from '@coveo/headless';
import { useMemo } from 'react';

import { getTextFromHtmlString } from 'src/utils/getTextFromHtmlString';

import { useBaseSearch } from './useBaseSearch';

type ResultWithAbstract = Result & { raw: { desc?: string } };

export function useCoveoExcerpt({
  result,
  hideCondtion,
}: {
  result: ResultWithAbstract;
  hideCondtion?: undefined;
}): string;
export function useCoveoExcerpt({
  result,
  hideCondtion,
}: {
  result: ResultWithAbstract;
  hideCondtion?: boolean;
}): string | undefined;
export function useCoveoExcerpt({
  result,
  hideCondtion,
}: {
  result: ResultWithAbstract;
  hideCondtion?: boolean | undefined;
}): string | undefined {
  const { state } = useBaseSearch();
  const { hasQuery } = state;
  const excerpt = useMemo(() => {
    if (hideCondtion) return undefined;

    const { desc } = result.raw || {};
    return hasQuery
      ? HighlightUtils.highlightString({
          content: result.excerpt,
          highlights: result.excerptHighlights,
          openingDelimiter: '<strong>',
          closingDelimiter: '</strong>',
        })
      : getTextFromHtmlString(desc);
  }, [hideCondtion, result, hasQuery]);

  return excerpt;
}
