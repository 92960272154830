import { useExpEditor } from 'src/hooks/useExpEditor';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseFlexibleFeatures from 'components/BaseHelpers/BaseFlexibleFeatures/BaseFlexibleFeatures';

export type InsightsFlexibleFeaturesProps = ComponentProps &
  Website.Project.Main.ComponentTypes.Insights.FlexibleFeatures;

const InsightsFlexibleFeatures = (props: InsightsFlexibleFeaturesProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();

  if (!hasItemsOrIsEE(props.rendering?.placeholders?.['flexible-features'])) {
    return <></>;
  }

  return <BaseFlexibleFeatures {...props} />;
};

export default InsightsFlexibleFeatures;
