import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useDictionary } from 'src/hooks/useDictionary';

import styles from './ScrollButton.module.scss';

const ScrollButton = (): JSX.Element => {
  const { t } = useDictionary();
  const [ranTransition, setRanTransition] = useState(false);

  useEffect(() => setRanTransition(true), []);

  return (
    <button
      aria-label={t('Scroll to next section')}
      className={styles.main}
      onClick={() => window.scrollTo({ top: window.innerHeight })}
    >
      <svg
        className={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
        width="383"
        height="255"
        viewBox="-100 -50 383 255"
        fill="none"
      >
        <path
          className={cn([styles.left, { [styles['transition-complete']]: ranTransition }])}
          d="M0.000112972 124.301L0.000114556 106.184L70.7305 176.668L70.7184 194.774L0.000112972 124.301Z"
          fill="white"
        />

        <path
          className={cn([styles.center, { [styles['transition-complete']]: ranTransition }])}
          d="M85.1429 205L98 205L98 1.52588e-05L85.1429 1.41348e-05L85.1429 205Z"
          fill="#B9A16C"
        />

        <path
          className={cn([styles.right, { [styles['transition-complete']]: ranTransition }])}
          d="M112 194.586L112.012 176.453L182.71 106L182.71 124.122L112 194.586Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default ScrollButton;
