import cn from 'classnames';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';

import Plus from '../../../assets/icons/plus.svg';

import styles from './BasicContentAccordionItem.module.scss';

export type BasicContentAccordionItemProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.AccordionItem;

const BasicContentAccordionItem = ({
  fields = {},
  rendering: { uid },
}: BasicContentAccordionItemProps): JSX.Element => {
  const { content, title } = fields;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isEE, hasValueOrIsEE } = useExpEditor();

  const isExpandedOrIsEE = isExpanded || isEE;

  const tl = useRef<GSAPTimeline>();
  const component = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded) {
      tl?.current?.play();
    } else {
      tl?.current?.reverse(0);
    }
  }, [isExpanded]);

  useIsomorphicLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({ paused: true });
      tl.current
        .set('.plus_svg__vertical', { transformOrigin: '50% 50%' })
        .to('.plus_svg__vertical', { rotation: 90 });
    }, component);

    return () => ctx.revert();
  }, []);

  if (!hasValueOrIsEE(content) || !hasValueOrIsEE(title)) {
    return <></>;
  }

  return (
    <div ref={component} className={cn(styles.main, 'container-8')}>
      <button
        aria-controls={`accordion-panel-${uid}`}
        className={styles[`toggle`]}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        disabled={isEE}
      >
        <ModuleHeader
          className={styles['module-header']}
          variant="single-line"
          header={title}
          aria-labelledby={`accordion-header-${uid}`}
        />
        <Plus className={styles.icon} />
      </button>

      <section
        id={`accordion-panel-${uid}`}
        aria-hidden={isExpandedOrIsEE ? 'false' : 'true'}
        className={cn({ [styles[`content--expanded`]]: isExpandedOrIsEE }, styles['content'])}
      >
        <div className={styles[`wrapper`]}>
          <div className={styles[`body`]}>
            <RichText field={content}></RichText>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BasicContentAccordionItem;
