import { Field, LinkField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { useEngineContext } from 'src/hooks/useCoveoEngine';
import { useExpEditor } from 'src/hooks/useExpEditor';

import { executeClearSearch, executeManualSearch, handleManualViewAll } from 'src/utils/coveo';
import { formatInternalLinkToRelativeLink } from 'src/utils/formatInternalLinkToRelativeLink';
import { hasItems } from 'src/utils/hasItems';

import { Website } from 'lib/component-props/model';

import BoxCta from 'components/BaseHelpers/BoxCta';
import LinkOrComponent from 'components/BaseHelpers/LinkOrComponent/LinkOrComponent';
import Text from 'components/BaseHelpers/Text';
import UnderlineCta from 'components/BaseHelpers/UnderlineCta';

import styles from './InsightsPanel.module.scss';

export type NavigationItem = Website.Feature.Insights._Insight &
  Website.Project.Main.PageTypes.Insights.ResourceCenter;

export type InsightsPanelProps = {
  viewAllLink?: LinkField;
  viewAllLinkText?: Field<string>;
  secondaryViewAllLink?: LinkField;
  secondaryViewAllLinkText?: Field<string>;
  trendingTopics?: Website.Feature.Insights.AITag[];
  trendingTopicsLabel?: Field<string>;
  navigationItems?: NavigationItem[];
  featuredInsightsLabel?: Field<string>;
};

const InsightsPanel = ({
  trendingTopics,
  trendingTopicsLabel,
  viewAllLink,
  viewAllLinkText,
  secondaryViewAllLink,
  secondaryViewAllLinkText,
  navigationItems,
  featuredInsightsLabel,
}: InsightsPanelProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { hasValueOrIsEE } = useExpEditor();
  const router = useRouter();
  const { insightsEngine } = useEngineContext();

  return (
    <div className={cn(styles[`main`], 'container-12')}>
      <div className={cn(styles[`links`], styles['trending-topics-links'])}>
        {hasItems(trendingTopics) && (
          <>
            <div className={styles[`label`]}>
              <Text field={trendingTopicsLabel} />
            </div>
            <ul
              className={cn(styles[`trending-topics`])}
              aria-label={trendingTopicsLabel?.value || ''}
            >
              {trendingTopics?.map(({ fields, id }) => {
                return (
                  <li className={styles[`trending-topic`]} key={`trending-topic-${id}`}>
                    <BoxCta
                      tag="a"
                      field={{
                        href: `/${sitecoreContext.language}/insights`,
                        querystring: `q=${fields?.name?.value?.replaceAll(' ', '%20')}`,
                      }}
                      onClick={(e) => {
                        // just changing the URL while on `/insights` doesn't update search state
                        // so, if we're on that page, we manually update search state with redux actions
                        if (!router.asPath.match(/^\/insights(?!\/)/)) return;
                        e.preventDefault();
                        executeManualSearch(insightsEngine, fields?.name?.value);
                      }}
                    >
                      <Text field={fields?.name} className={styles['nested-text']} />
                    </BoxCta>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>

      <div className={cn(styles[`links`], styles['featured-links'])}>
        {hasItems(navigationItems) && (
          <>
            <div className={styles[`label`]}>
              <Text field={featuredInsightsLabel} />
            </div>

            <ul
              className={cn(styles[`nav-panel-links`])}
              aria-label={featuredInsightsLabel?.value || ''}
            >
              {navigationItems
                ?.filter(
                  ({ fields, url }) =>
                    (hasValueOrIsEE(fields?.title) || hasValueOrIsEE(fields?.name)) &&
                    hasValueOrIsEE(url)
                )
                .map(({ url, fields, id }) => {
                  return (
                    <li className={styles[`nav-panel-link`]} key={`nav-panel-link-${id}`}>
                      <LinkOrComponent field={url} className={styles[`link`]}>
                        <Text field={fields?.title || fields?.name} editable={false} />
                      </LinkOrComponent>
                    </li>
                  );
                })}
            </ul>
          </>
        )}
      </div>
      <ul className={styles['view-all-links']}>
        <li>
          <UnderlineCta
            className={styles[`view-all-insights`]}
            tag="a"
            field={formatInternalLinkToRelativeLink(viewAllLink)}
            onClick={(e) => {
              if (!router.asPath.match(/^\/insights(?!\/)/)) return;
              e.preventDefault();
              executeClearSearch(insightsEngine);
            }}
          >
            <Text field={viewAllLinkText} className={styles['nested-text']} />
          </UnderlineCta>
        </li>
        <li>
          <UnderlineCta
            className={styles[`view-all-insights`]}
            tag="a"
            field={formatInternalLinkToRelativeLink(secondaryViewAllLink)}
            onClick={(e) => {
              if (!router.asPath.match(/^\/insights(?!\/)/)) return;
              e.preventDefault();
              handleManualViewAll(secondaryViewAllLink, insightsEngine);
            }}
          >
            <Text field={secondaryViewAllLinkText} className={styles['nested-text']} />
          </UnderlineCta>
        </li>
      </ul>
    </div>
  );
};

export default InsightsPanel;
