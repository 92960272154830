import {
  Field,
  ImageField,
  ImageFieldValue,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-nextjs';
import startCase from 'lodash/startCase';

import { LinkProps } from 'components/BaseHelpers/Link';

import { hasValue } from './hasValue';

// A Link's "field" type can be either a LinkField or LinkFieldValue.
// This function standardizes it to LinkFieldValue so it can be easier to use
export const getLinkFieldValue = (
  field: LinkProps['field'] | string | undefined
): LinkFieldValue => {
  if (field === undefined) {
    return {};
  }

  if (typeof field === 'string') {
    return {
      href: field,
    };
  }

  if ('href' in field) {
    return field;
  }

  if ('value' in field && typeof field.value === 'object') {
    if (field.value && 'src' in field.value) {
      return {
        ...field.value,
        href: field.value.src as string,
      };
    }
    return field.value as LinkFieldValue;
  }

  return {};
};

export const getStringFieldValue = (
  field: Field<string> | string | undefined
): string | undefined => {
  if (!field) return undefined;
  if (typeof field === 'string') return field;
  return field.value;
};

export const stringFieldToNumberValue = (field: Field<string> | Field<number> | undefined) => {
  if (!field) return;
  if (typeof field?.value === 'string') return parseInt(field.value, 10);
  return field.value;
};

export const createLinkObject = (url: string | undefined) => {
  if (!url) return;
  const [href, querystring] = url.split('?');
  return { href, querystring };
};

interface FieldsWithSource {
  bodyText?: Field<string> | undefined;
  headline?: Field<string> | undefined;
  date?: Field<string> | undefined;
  displayDate?: Field<string> | undefined;
}

interface ItemSource {
  headline?: string;
  bodyText?: string;
  date?: string;
  displayDate?: string;
}

type FieldKey = keyof FieldsWithSource & keyof ItemSource;

export const fieldOrSourceField = <T extends FieldsWithSource | undefined>(
  fields: T,
  source: ItemSource | undefined,
  field: FieldKey,
  isEE: boolean
): FieldsWithSource[keyof FieldsWithSource] | undefined => {
  return hasValue(fields?.[field])
    ? fields?.[field]
    : source?.[field]
      ? { value: source[field] as string }
      : getFallback(startCase(field), isEE);
};

export const getFallback = (fieldName: string, isEE: boolean): Field<string> | undefined =>
  isEE ? { value: `${fieldName}: [No data in field]` } : undefined;

export const imageOrSourceImage = (
  fields: { image?: ImageField },
  source: { image?: ImageFieldValue } | undefined,
  isEE: boolean
) => {
  const hasImageValue = isEE
    ? // In experience editor, image fields will have a `src` which is Sitecore's empty image icon.
      // Therefore it doesn't really have a valid image value. We can tell it's Sitecore's empty image
      // icon because it will also include the class of `scEmptyImage`
      hasValue(fields.image) && fields.image?.value?.class !== 'scEmptyImage'
    : hasValue(fields.image);
  if (hasImageValue) {
    return fields.image;
  }
  if (source?.image) {
    return { value: source.image };
  }
  return isEE ? fields.image : undefined;
};
