import {
  BreadcrumbManager,
  BreadcrumbManagerState,
  Context,
  ContextState,
  Facet,
  FacetProps,
  FacetState,
  QuerySummary,
  QuerySummaryState,
  ResultList,
  ResultListOptions,
  ResultListProps,
  ResultListState,
  ResultsPerPage,
  ResultsPerPageProps,
  ResultsPerPageState,
  SearchBox,
  SearchBoxOptions,
  SearchBoxProps,
  SearchBoxState,
  SearchEngine,
  Sort,
  SortProps,
  SortState,
  StandaloneSearchBox,
  StandaloneSearchBoxOptions,
  StandaloneSearchBoxProps,
  StandaloneSearchBoxState,
  buildBreadcrumbManager,
  buildContext,
  buildFacet,
  buildQuerySummary,
  buildResultList,
  buildResultsPerPage,
  buildSearchBox,
  buildStandaloneSearchBox,
  buildSort,
  DateFacetProps,
  DateFacet,
  DateFacetState,
  buildDateFacet,
  DidYouMean,
  DidYouMeanState,
  buildDidYouMean,
  DidYouMeanProps,
} from '@coveo/headless';
import { useEffect, useRef, useState } from 'react';

type CoveoBuilder =
  | 'facet'
  | 'datefacet'
  | 'breadcrumb'
  | 'searchbox'
  | 'standalonesearchbox'
  | 'resultlist'
  | 'resultsperpage'
  | 'querysummary'
  | 'context'
  | 'sort'
  | 'didyoumean';
type CoveoOptions =
  | FacetProps['options']
  | DateFacetProps['options']
  | SearchBoxOptions
  | StandaloneSearchBoxOptions
  | ResultListOptions
  | ResultsPerPageProps
  | SortProps
  | DidYouMeanProps['options']
  | undefined;
type CoveoController =
  | Facet
  | DateFacet
  | SearchBox
  | StandaloneSearchBox
  | BreadcrumbManager
  | ResultList
  | ResultsPerPage
  | QuerySummary
  | Context
  | Sort
  | DidYouMean;
type CoveoState =
  | FacetState
  | DateFacetState
  | SearchBoxState
  | StandaloneSearchBoxState
  | BreadcrumbManagerState
  | ResultListState
  | ResultsPerPageState
  | QuerySummaryState
  | ContextState
  | SortState
  | DidYouMeanState;

export function useCoveoBuilder(
  builder: 'facet',
  engine: SearchEngine,
  options: FacetProps['options']
): [Facet | null, FacetState | undefined];
export function useCoveoBuilder(
  builder: 'datefacet',
  engine: SearchEngine,
  options: DateFacetProps['options']
): [DateFacet | null, DateFacetState | undefined];
export function useCoveoBuilder(
  builder: 'resultlist',
  engine: SearchEngine,
  options: ResultListOptions
): [ResultList | null, ResultListState | undefined];
export function useCoveoBuilder(
  builder: 'resultsperpage',
  engine: SearchEngine,
  options: ResultsPerPageProps
): [ResultsPerPage | null, ResultsPerPageState | undefined];
export function useCoveoBuilder(
  builder: 'searchbox',
  engine: SearchEngine,
  options: SearchBoxOptions
): [SearchBox | null, SearchBoxState | undefined];
export function useCoveoBuilder(
  builder: 'standalonesearchbox',
  engine: SearchEngine,
  options: StandaloneSearchBoxOptions
): [StandaloneSearchBox | null, StandaloneSearchBoxState | undefined];
export function useCoveoBuilder(
  builder: 'breadcrumb',
  engine: SearchEngine
): [BreadcrumbManager | null, BreadcrumbManagerState | undefined];
export function useCoveoBuilder(
  builder: 'querysummary',
  engine: SearchEngine
): [QuerySummary | null, QuerySummaryState | undefined];
export function useCoveoBuilder(
  builder: 'context',
  engine: SearchEngine
): [Context | null, ContextState | undefined];
export function useCoveoBuilder(
  builder: 'sort',
  engine: SearchEngine,
  options: SortProps
): [Sort | null, SortState | undefined];
export function useCoveoBuilder(
  builder: 'didyoumean',
  engine: SearchEngine,
  options: DidYouMeanProps['options']
): [DidYouMean | null, DidYouMeanState | undefined];
export function useCoveoBuilder(
  builder: CoveoBuilder,
  engine: SearchEngine,
  options?: CoveoOptions
): [CoveoController | null, CoveoState | undefined] {
  const [controllerState, setControllerState] = useState<CoveoState | undefined>();

  const controller = useRef<CoveoController | null>(null);

  useEffect(() => {
    if (!controller.current) {
      controller.current = getController(engine, builder, options);
    }
    const unsubController = controller.current?.subscribe(() =>
      setControllerState(controller.current?.state)
    );

    return () => {
      if (unsubController) unsubController();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [controller.current, controllerState];
}

const getController = (engine: SearchEngine, builder: CoveoBuilder, options?: CoveoOptions) => {
  switch (builder) {
    case 'breadcrumb':
      return buildBreadcrumbManager(engine);
    case 'facet':
      return buildFacet(engine, { options } as FacetProps);
    case 'datefacet':
      return buildDateFacet(engine, { options } as DateFacetProps);
    case 'searchbox':
      return buildSearchBox(engine, { options } as SearchBoxProps);
    case 'standalonesearchbox':
      return buildStandaloneSearchBox(engine, { options } as StandaloneSearchBoxProps);
    case 'resultlist':
      return buildResultList(engine, { options } as ResultListProps);
    case 'resultsperpage':
      return buildResultsPerPage(engine, options as ResultsPerPageProps);
    case 'querysummary':
      return buildQuerySummary(engine);
    case 'context':
      return buildContext(engine);
    case 'sort':
      return buildSort(engine, options as SortProps);
    case 'didyoumean':
      return buildDidYouMean(engine, { options } as DidYouMeanProps);
  }
};
