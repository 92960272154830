import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import HorizontalImageSplitHero from 'components/BaseHelpers/HorizontalImageSplitHero';

export type BasicContentHorizontalImageSplitHeroProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.HorizontalImageSplitHero;

const BasicContentHorizontalImageSplitHero = (
  props: BasicContentHorizontalImageSplitHeroProps
): JSX.Element => {
  return <HorizontalImageSplitHero {...props} />;
};

export default BasicContentHorizontalImageSplitHero;
