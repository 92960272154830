import { Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

import { getRouteField } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import BaseContacts from 'components/BaseHelpers/BaseContacts';

export type PeopleSpeakersProps = ComponentProps &
  Website.Project.Main.ComponentTypes.People.Speakers;

const PeopleSpeakers = (props: PeopleSpeakersProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const speakers = getRouteField<Array<Item>>('speakers', sitecoreContext);

  return <BaseContacts {...props} contacts={speakers} />;
};

export default PeopleSpeakers;
