import { Link as NextLink, LinkProps as JssLinkProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { Link as ReactLink } from '@sitecore-jss/sitecore-jss-react';
import { ForwardedRef, forwardRef } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { getLinkFieldValue } from 'src/utils/sitecoreFieldHelpers';

export type LinkProps = Omit<Partial<JssLinkProps>, 'field'> & {
  ref?: ForwardedRef<HTMLAnchorElement>;
  field?: JssLinkProps['field'] | string;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function LinkComponent(
  { field, ...otherProps }: LinkProps,
  ref
): JSX.Element {
  const { isEE, hasValueOrIsEE } = useExpEditor();

  const linkFieldValue = getLinkFieldValue(field);

  if (linkFieldValue.target === '_blank') {
    linkFieldValue.rel = 'noreferrer';
  }

  if (!hasValueOrIsEE(linkFieldValue)) {
    return <></>;
  }

  if (isEE || process.env.NEXT_PUBLIC_DISABLE_NEXT_LINK_PREFETCH === 'true') {
    return <ReactLink field={linkFieldValue} {...otherProps} ref={ref} />;
  } else {
    return <NextLink field={linkFieldValue} {...otherProps} ref={ref} />;
  }
});

export default Link;
