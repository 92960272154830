import {
  ComponentRendering,
  PlaceholdersData,
  SitecoreContextValue,
} from '@sitecore-jss/sitecore-jss-nextjs';

export const getPlaceholder = <T>(
  key: string,
  rendering: ComponentRendering
): Array<T> | undefined => {
  return rendering?.placeholders?.[key] as Array<T> | undefined;
};

export const getComponent = <T>(
  componentName: string,
  placeholder: PlaceholdersData[string] | undefined
): T | undefined => {
  return placeholder?.find(
    (component) => 'componentName' in component && component.componentName === componentName
  ) as T | undefined;
};

export const getRouteField = <T>(
  key: string,
  sitecoreContext: SitecoreContextValue
): T | undefined => {
  return sitecoreContext.route?.fields?.[key] as T | undefined;
};

export const getRouteFields = <T>(sitecoreContext: SitecoreContextValue): T => {
  return (sitecoreContext.route?.fields || {}) as T;
};
