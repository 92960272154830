import {
  ImageField,
  ImageFieldValue,
  Item,
  LinkField,
  LinkFieldValue,
  TextField,
} from '@sitecore-jss/sitecore-jss-nextjs';

export type FieldOptions =
  | undefined
  | string
  | number
  | ImageField
  | ImageFieldValue
  | LinkField
  | LinkFieldValue
  | TextField
  | Item;

/**
 * returns `true` if the `field` or the `field`'s relevant property (if it is an object) is "truthy"
 *
 * example:
 *
 * `{ value: 'foo' }` would return `true`, but `{ value: '' }` would return `false` (even though the object exists)
 */
export const hasValue = <T extends FieldOptions>(field: T): field is NonNullable<T> => {
  // undefined or null
  if (field === undefined || field === null) {
    return false;
  }

  // string
  if (typeof field === 'string') {
    return Boolean(field);
  }

  // number
  if (typeof field === 'number') {
    return true;
  }

  // Item
  if ('name' in field) {
    return Boolean(field.name);
  }

  // LinkFieldValue
  if ('href' in field) {
    return Boolean(field.href);
  }

  // ImageFieldValue
  if ('src' in field) {
    return Boolean(field.src);
  }

  // value = undefined || null
  if (field.value === undefined || field.value === null) {
    return false;
  }

  // value = string
  if (typeof field.value === 'string') {
    return Boolean(field.value);
  }

  // value = number
  if (typeof field.value === 'number') {
    return true;
  }

  // ImageField || LinkField
  if (typeof field.value === 'object') {
    // value = LinkFieldValue
    if ('href' in field.value) {
      return Boolean(field.value.href);
    }

    // value = ImageFieldValue
    if ('src' in field.value) {
      return Boolean(field.value.src);
    }
  }
  return false;
};
