import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { getRouteFields } from 'src/utils/sitecoreHelpers';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import NoImageHero from 'components/BaseHelpers/NoImageHero';

import styles from './OfficeLandingHeader.module.scss';

export type OfficeLandingHeaderProps = {
  name?: Field<string>;
  description?: Field<string>;
};

const OfficeLandingHeader = (): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const fields = getRouteFields<OfficeLandingHeaderProps>(sitecoreContext);

  return (
    <AnimateInContainer className={cn(styles.main, 'spacer')}>
      <NoImageHero className={styles.hero} headline={fields.name} body={fields.description} />
    </AnimateInContainer>
  );
};

export default OfficeLandingHeader;
