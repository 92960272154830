import { Website } from 'lib/component-props/model';

import { getPhone } from './getPhone';

export type OfficeInfo = Website.Project.Main.PageTypes.Offices.Office['fields'];

export const getAddresses = (fields: OfficeInfo = {}) => {
  const {
    street,
    city,
    state,
    postalCode,
    country,
    googleMapsLink,
    phone1,
    phone2,
    secondaryStreet,
    secondaryCity,
    secondaryState,
    secondaryPostalCode,
    secondaryCountry,
    secondaryGoogleMapsLink,
    secondaryPhone1,
    secondaryPhone2,
  } = fields;

  const addresses = [];

  if (street?.value) {
    addresses.push({
      streetAddress: [
        street?.value,
        '<br>',
        [city?.value, state?.fields?.abbreviation?.value].filter(Boolean).join(', '),
        postalCode?.value,
        city?.value || state?.fields?.abbreviation?.value ? '<br>' : '',
        country?.fields?.name?.value,
      ]
        .filter(Boolean)
        .join(' '),
      phone1: getPhone(phone1),
      phone2: getPhone(phone2),
      googleMapsLink: { value: { ...(googleMapsLink || {}).value, target: '_blank' } },
    });
  }
  if (secondaryStreet?.value) {
    addresses.push({
      streetAddress: [
        secondaryStreet?.value,
        '<br>',
        [secondaryCity?.value, secondaryState?.fields?.abbreviation?.value]
          .filter(Boolean)
          .join(', '),
        secondaryPostalCode?.value,
        '<br>',
        secondaryCountry?.fields?.name?.value,
      ]
        .filter(Boolean)
        .join(' '),
      phone1: getPhone(secondaryPhone1),
      phone2: getPhone(secondaryPhone2),
      googleMapsLink: { value: { ...(secondaryGoogleMapsLink || {}).value, target: '_blank' } },
    });
  }
  return addresses;
};
