import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasValue } from 'src/utils/hasValue';
import { getPlaceholder } from 'src/utils/sitecoreHelpers';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import AnimateInContainer from 'components/BaseHelpers/AnimateInContainer';
import ModuleHeader from 'components/BaseHelpers/ModuleHeader';
import RichText from 'components/BaseHelpers/RichText';

import { BasicContentAccordionItemProps } from '../BasicContentAccordionItem';

import styles from './BasicContentAccordion.module.scss';

export type BasicContentAccordionProps = ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.Accordion;

const BasicContentAccordion = ({ fields, rendering }: BasicContentAccordionProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const placeholder = getPlaceholder<BasicContentAccordionItemProps>('accordion', rendering);
  const itemsToRender = placeholder?.filter((item) => shouldRender(item));

  if (!hasItemsOrIsEE(itemsToRender)) {
    return <></>;
  }
  return (
    <AnimateInContainer className={cn(styles.main, 'container-10', 'spacer')}>
      <ModuleHeader className={styles[`header`]} header={fields?.moduleHeader}></ModuleHeader>
      <RichText className={styles[`description`]} field={fields?.moduleDescription}></RichText>
      <Placeholder name="accordion" rendering={rendering} />
    </AnimateInContainer>
  );
};

const shouldRender = (item: BasicContentAccordionItemProps) => {
  return hasValue(item.fields?.content) && hasValue(item.fields?.title);
};

export default BasicContentAccordion;
