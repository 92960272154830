import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import cn from 'classnames';
import { RefObject, useState } from 'react';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { TabButton } from './TabButton/TabButton';
import TabPanel from './TabPanels';

import styles from './TabNavigation.module.scss';

export type Tab = {
  title?: Field<string>;
  id: string;
  htmlRef?: RefObject<HTMLLIElement>;
  content?: JSX.Element;
  name?: string;
  link?: LinkField;
  viewAllLink?: LinkField;
};

export type TabNavigationProps = {
  tabs: Tab[];
  onTabPanel?: (isPanelOpen: boolean) => void;
};

type CurrentTab = {
  index: number;
  tab: Tab;
};

const TabNavigation = ({ tabs }: TabNavigationProps): JSX.Element => {
  const { hasItemsOrIsEE } = useExpEditor();
  const [selectedTab, setSelectedTab] = useState<CurrentTab>({
    index: 0,
    tab: tabs[0],
  });

  const handleSelectTab = (tab: Tab, index: number) => {
    setSelectedTab({ index, tab });
  };

  const isSelected = (tabId: string) => {
    return tabId === selectedTab?.tab.id;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const index = selectedTab?.index || 0;
    if (
      !['ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(event.key) ||
      (event.key === 'ArrowLeft' && index === 0) ||
      (event.key === 'ArrowRight' && index === tabs.length - 1)
    ) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    let nextIndex: number | undefined = undefined;

    switch (event.key) {
      case 'ArrowLeft':
        nextIndex = index - 1;
        break;

      case 'ArrowRight':
        nextIndex = index + 1;
        break;

      case 'Home':
        nextIndex = 0;
        break;

      case 'End':
        nextIndex = tabs.length - 1;
        break;

      default:
        break;
    }
    if (nextIndex !== undefined) {
      handleSelectTab(tabs[nextIndex], nextIndex);
    }
  };

  if (!hasItemsOrIsEE(tabs)) {
    return <></>;
  }

  return (
    <div className={styles[`main`]}>
      <div className={styles[`tabs`]}>
        <div className={styles[`tab-navs-container`]}>
          <div
            className={cn(styles[`tab-navs`], 'container-12')}
            role="tablist"
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            {tabs?.map((tab, index) => {
              return (
                <TabButton
                  key={`tab-${tab.id}`}
                  tab={tab}
                  isSelected={isSelected(tab.id)}
                  variant={'content-tab'}
                  onClick={() => {
                    handleSelectTab(tab, index);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={styles[`panels`]}>
          {tabs?.map((tab) => {
            const { content } = tab;
            return (
              content && (
                <TabPanel
                  key={`tab-panel-${tab.id}`}
                  tab={tab}
                  isActive={isSelected(tab.id)}
                  variant={'content-tab'}
                >
                  {content}
                </TabPanel>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
