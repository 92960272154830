import cn from 'classnames';
import dynamic from 'next/dynamic';
import {
  CityPointerData,
  HeatMapData,
  MapDataShape,
  MapRegion,
  MapType,
} from 'src/componentsNonSSR/InteractiveMap';

import { useExpEditor } from 'src/hooks/useExpEditor';

import { hasItems } from 'src/utils/hasItems';

import { ComponentProps } from 'lib/component-props';
import { Website } from 'lib/component-props/model';

import ModuleHeader from 'components/BaseHelpers/ModuleHeader';

import styles from './BasicContentInteractiveMap.module.scss';

const InteractiveMap = dynamic(() => import('../../../componentsNonSSR/InteractiveMap'), {
  ssr: false,
});

interface DataPoint {
  title: string;
  bodyText: string;
  link?: string;
  linkLabel?: string;
  linktarget?: string;
  latitude?: number | string;
  longitude?: number | string;
  country?: string;
  value?: number | string;
}

export type BasicContentInteractiveMapProps = {
  fields: {
    dataPoints?: Array<DataPoint>;
    defaultLinkLabel?: string;
  };
} & ComponentProps &
  Website.Project.Main.ComponentTypes.BasicContent.InteractiveMap;

const BasicContentInteractiveMap = ({
  fields = {},
  rendering,
}: BasicContentInteractiveMapProps): JSX.Element => {
  const { isEE, hasItemsOrIsEE, hasValueOrIsEE } = useExpEditor();

  const mapType = fields?.mapType?.value as unknown as MapType;
  const mapRegion = fields?.mapRegion?.value as unknown as MapRegion;
  const defaultLabel = fields?.defaultLinkLabel || '';

  let dataPoints = [] as MapDataShape;

  // Server Data Validation
  if (mapType === 'City Pointer Map') {
    dataPoints =
      fields?.dataPoints && hasItems(fields.dataPoints)
        ? fields.dataPoints
            .map((point) => {
              const datapoint = point as CityPointerData;

              return {
                ...datapoint,
                latitude: Number(datapoint?.latitude),
                longitude: Number(datapoint?.longitude),
              };
            })
            .filter((datapoint) => datapoint?.latitude && datapoint?.longitude)
        : [];
  }

  if (mapType === 'Heat Map') {
    dataPoints =
      fields?.dataPoints && hasItems(fields.dataPoints)
        ? fields.dataPoints
            .map((point) => {
              const datapoint = point as HeatMapData;

              return {
                ...datapoint,
                id: datapoint?.country || '',
                value: Number(datapoint?.value) || 0,
              };
            })
            .filter((datapoint) => datapoint?.id && typeof datapoint?.value === 'number')
        : ([] as HeatMapData[]);
  }

  // Don't display component without any DataPoints Present or Region or MapType Selected
  if (!hasItemsOrIsEE(dataPoints) || !hasValueOrIsEE(mapType) || !hasValueOrIsEE(mapRegion)) {
    return <></>;
  }

  return (
    <div className={cn('container-8', 'spacer')}>
      {!isEE ? (
        <>
          <ModuleHeader className={cn(styles.header)} header={fields?.title} />
          <InteractiveMap
            id={`map-${rendering?.uid}`}
            region={mapRegion}
            type={mapType}
            data={dataPoints}
            defaultLinkLabel={defaultLabel}
          />
        </>
      ) : (
        <div className={cn(styles.note)}>
          Interactive Map is not Visible in Experience Editor Mode. Please View in Preview or
          Publish your changes. Ensure mapType and mapRegion is selected and Datapoints are added to
          the component.
        </div>
      )}
    </div>
  );
};

export default BasicContentInteractiveMap;
